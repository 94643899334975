import { CASES } from '../EnumTypes/ActionEnumTypes';
import { generatePath } from 'react-router';
import { addCaseRemainder as addCaseRemainderConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: CASES.ADD_CASE_REMAINDER_INIT,
});

const success = () => ({
  type: CASES.ADD_CASE_REMAINDER_SUCCESS,
});

const failure = () => ({
  type: CASES.ADD_CASE_REMAINDER_FAILURE,
});

const addCaseRemainder = (caseRemainderData, caseId) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    console.log(caseRemainderData);
    await addCaseRemainderConnector({ reminderCreationDto: { ...caseRemainderData, caseId } });
    dispatch(success());
    toast.success('Pomyślnie dodano przypomnienie do sprawy');
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(generatePath(ROUTES.CASE_DETAILS, { id: caseId }));
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się dodać przypomnienia do sprawy');
    dispatch(failure());
  }
};

export default addCaseRemainder;
