import { Client } from '../EnumTypes/ActionEnumTypes';
import { getClientNotes as getClientNotesConnector } from 'Connectors/ClientConnector';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: Client.GET_CLIENT_NOTES_INIT,
});

const success = (clientNotes) => ({
    type: Client.GET_CLIENT_NOTES_SUCCESS,
    payload: {
        clientNotes,
    },
});

const failure = () => ({
    type: Client.GET_CLIENT_NOTES_FAILURE,
});

const getClientNotes = (id) => async (dispatch) => {
    dispatch(init());
    try {
        // dispatch(ViewManagementModule.Actions.showLoader());
        const { data } = await getClientNotesConnector(id);
        dispatch(success(data));
        // dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        // dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getClientNotes;
