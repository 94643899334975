import { GROUP } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/GroupModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: GROUP.GET_CRM_SETTINGS_INIT,
});

const success = (crmSettings) => ({
  type: GROUP.GET_CRM_SETTINGS_SUCCESS,
  payload: {
    crmSettings,
  },
});

const failure = () => ({
  type: GROUP.GET_CRM_SETTINGS_FAILURE,
});

const getCrmSettings = () => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await Connector.getCrmSettings();
    dispatch(success(data.crmOptions));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getCrmSettings;
