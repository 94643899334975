import { GROUP } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/GroupModule';
import getCrmSettings from './getCrmSettings';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: GROUP.PATCH_CRM_SETTINGS_INIT,
});

const success = (crmSettings) => ({
  type: GROUP.PATCH_CRM_SETTINGS_SUCCESS,
});

const failure = () => ({
  type: GROUP.PATCH_CRM_SETTINGS_FAILURE,
});

const updateCrmSettings = (crmOptions) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await Connector.updateCrmSettings({ crmOptions });
    dispatch(success(data.groupDtos));
    dispatch(getCrmSettings());
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.success('Pomyślnie zaktualizowano ustawienia');
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default updateCrmSettings;
