export enum User {
  GET_INTERNAL_USERS = 'GET_INTERNAL_USERS',
  ADD_INTERNAL_USER = 'ADD_INTERNAL_USER',
  GET_SPECIFIC_INTERNAL_USER = 'GET_SPECIFIC_INTERNAL_USER',
  GET_SPECIFIC_EXTERNAL_USER = 'GET_SPECIFIC_EXTERNAL_USER',
  EDIT_SPECIFIC_INTERNAL_USER = 'EDIT_SPECIFIC_INTERNAL_USER',
  DELETE_SPECIFIC_INTERNAL_USER = 'DELETE_SPECIFIC_INTERNAL_USER',
  CHANGE_PASSWORD_INTERNAL_USER = 'CHANGE_PASSWORD_INTERNAL_USER',
  GET_EXTERNAL_USERS = 'GET_EXTERNAL_USERS',
  GET_INTERNAL_USERS_LOGS_INIT = 'GET_INTERNAL_USERS_LOGS_INIT',
  GET_INTERNAL_USERS_LOGS_SUCCESS = 'GET_INTERNAL_USERS_LOGS_SUCCESS',
  GET_INTERNAL_USERS_LOGS_FAILURE = 'GET_INTERNAL_USERS_LOGS_FAILURE',
  GET_EXTERNAL_USERS_LOGS_INIT = 'GET_EXTERNAL_USERS_LOGS_INIT',
  GET_EXTERNAL_USERS_LOGS_SUCCESS = 'GET_EXTERNAL_USERS_LOGS_SUCCESS',
  GET_EXTERNAL_USERS_LOGS_FAILURE = 'GET_EXTERNAL_USERS_LOGS_FAILURE',
  ADD_EXTERNAL_USER_INIT = 'ADD_EXTERNAL_USER_INIT',
  ADD_EXTERNAL_USER_SUCCESS = 'ADD_EXTERNAL_USER_SUCCESS',
  ADD_EXTERNAL_USER_FAILURE = 'ADD_EXTERNAL_USER_FAILURE',
  UPDATE_EXTERNAL_USER_CLIENTS_INIT = 'UPDATE_EXTERNAL_USER_CLIENTS_INIT',
  UPDATE_EXTERNAL_USER_CLIENTS_SUCCESS = 'UPDATE_EXTERNAL_USER_CLIENTS_SUCCESS',
  UPDATE_EXTERNAL_USER_CLIENTS_FAILURE = 'UPDATE_EXTERNAL_USER_CLIENTS_FAILURE',
  EDIT_EXTERNAL_USER_INIT = 'EDIT_EXTERNAL_USER_INIT',
  EDIT_EXTERNAL_USER_SUCCESS = 'EDIT_EXTERNAL_USER_SUCCESS',
  EDIT_EXTERNAL_USER_FAILURE = 'EDIT_EXTERNAL_USER_FAILURE',
  GET_INTERNAL_USER_OPTIONS_INIT = 'GET_INTERNAL_USER_OPTIONS_INIT',
  GET_INTERNAL_USER_OPTIONS_SUCCESS = 'GET_INTERNAL_USER_OPTIONS_SUCCESS',
  GET_INTERNAL_USER_OPTIONS_FAILURE = 'GET_INTERNAL_USER_OPTIONS_FAILURE',
  GET_ALL_USERS_STATS_INIT = 'GET_ALL_USERS_STATS_INIT',
  GET_ALL_USERS_STATS_SUCCESS = 'GET_ALL_USERS_STATS_SUCCESS',
  GET_ALL_USERS_STATS_FAILURE = 'GET_ALL_USERS_STATS_FAILURE',
  GET_SIDEBAR_COUNTS_INIT = 'GET_SIDEBAR_COUNTS_INIT',
  GET_SIDEBAR_COUNTS_SUCCESS = 'GET_SIDEBAR_COUNTS_SUCCESS',
  GET_SIDEBAR_COUNTS_FAILURE = 'GET_SIDEBAR_COUNTS_FAILURE',
  GET_EXTERNAL_USER_LOGS_XLSX_INIT = 'GET_EXTERNAL_USER_LOGS_XLSX_INIT',
  GET_EXTERNAL_USER_LOGS_XLSX_SUCCESS = 'GET_EXTERNAL_USER_LOGS_XLSX_SUCCESS',
  GET_EXTERNAL_USER_LOGS_XLSX_FAILURE = 'GET_EXTERNAL_USER_LOGS_XLSX_FAILURE',
  GET_INTERNAL_USER_LOGS_XLSX_INIT = 'GET_INTERNAL_USER_LOGS_XLSX_INIT',
  GET_INTERNAL_USER_LOGS_XLSX_SUCCESS = 'GET_INTERNAL_USER_LOGS_XLSX_SUCCESS',
  GET_INTERNAL_USER_LOGS_XLSX_FAILURE = 'GET_INTERNAL_USER_LOGS_XLSX_FAILURE',
}

export enum Client {
  GET_CLIENTS_CASE_XLSX_INIT = 'GET_CLIENTS_CASE_XLSX_INIT',
  GET_CLIENTS_CASE_XLSX_SUCCESS = 'GET_CLIENTS_CASE_XLSX_SUCCESS',
  GET_CLIENTS_CASE_XLSX_FAILURE = 'GET_CLIENTS_CASE_XLSX_FAILURE',
  GET_CLIENTS_SELECT_LIST = 'GET_CLIENTS_SELECT_LIST',
  GET_CLIENTS_LIST_INIT = 'GET_CLIENTS_LIST_INIT',
  GET_CLIENTS_LIST_SUCCESS = 'GET_CLIENTS_LIST_SUCCESS',
  GET_CLIENTS_LIST_FAILURE = 'GET_CLIENTS_LIST_FAILURE',
  ADD_CLIENT_INIT = 'ADD_CLIENT_INIT',
  ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS',
  ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE',
  EDIT_CLIENT_INIT = 'EDIT_CLIENT_INIT',
  EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS',
  EDIT_CLIENT_FAILURE = 'EDIT_CLIENT_FAILURE',
  EDIT_SINGLE_CLIENT_INIT = 'EDIT_SINGLE_CLIENT_INIT',
  EDIT_SINGLE_CLIENT_SUCCESS = 'EDIT_SINGLE_CLIENT_SUCCESS',
  EDIT_SINGLE_CLIENT_FAILURE = 'EDIT_SINGLE_CLIENT_FAILURE',
  DELETE_CLIENT_INIT = 'DELETE_CLIENT_INIT',
  DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_FAILURE = 'DELETE_CLIENT_FAILURE',
  DELETE_CLIENT_NOTE_INIT = 'DELETE_CLIENT_NOTE_INIT',
  DELETE_CLIENT_NOTE_SUCCESS = 'DELETE_CLIENT_NOTE_SUCCESS',
  DELETE_CLIENT_NOTE_FAILURE = 'DELETE_CLIENT_NOTE_FAILURE',
  DELETE_CLIENT_CONTACT_INIT = 'DELETE_CLIENT_CONTACT_INIT',
  DELETE_CLIENT_CONTACT_SUCCESS = 'DELETE_CLIENT_CONTACT_SUCCESS',
  DELETE_CLIENT_CONTACT_FAILURE = 'DELETE_CLIENT_CONTACT_FAILURE',
  GET_CLIENT_DETAILS_INIT = 'GET_CLIENT_DETAILS_INIT',
  GET_CLIENT_DETAILS_SUCCESS = 'GET_CLIENT_DETAILS_SUCCESS',
  GET_CLIENT_DETAILS_FAILURE = 'GET_CLIENT_DETAILS_FAILURE',
  GET_CLIENT_NOTES_INIT = 'GET_CLIENT_NOTES_INIT',
  GET_CLIENT_NOTES_SUCCESS = 'GET_CLIENT_NOTES_SUCCESS',
  GET_CLIENT_NOTES_FAILURE = 'GET_CLIENT_NOTES_FAILURE',
  GET_CLIENT_NOTE_BY_ID_INIT = 'GET_CLIENT_NOTE_BY_ID_INIT',
  GET_CLIENT_NOTE_BY_ID_FAILURE = 'GET_CLIENT_NOTE_BY_ID_FAILURE',
  GET_CLIENT_NOTE_BY_ID_SUCCESS = 'GET_CLIENT_NOTE_BY_ID_SUCCESS',
  GET_CLIENT_CONTACTS_INIT = 'GET_CLIENT_CONTACTS_INIT',
  GET_CLIENT_CONTACTS_SUCCESS = 'GET_CLIENT_CONTACTS_SUCCESS',
  GET_CLIENT_CONTACTS_FAILURE = 'GET_CLIENT_CONTACTS_FAILURE',
  GET_CLIENT_SINGLE_CONTACT_INIT = 'GET_CLIENT_SINGLE_CONTACT_INIT',
  GET_CLIENT_SINGLE_CONTACT_SUCCESS = 'GET_CLIENT_SINGLE_CONTACT_SUCCESS',
  GET_CLIENT_SINGLE_CONTACT_FAILURE = 'GET_CLIENT_SINGLE_CONTACT_FAILURE',
  ADD_CONTACT_TO_CLIENT_INIT = 'ADD_CONTACT_TO_CLIENT_INIT',
  ADD_CONTACT_TO_CLIENT_SUCCESS = 'ADD_CONTACT_TO_CLIENT_SUCCESS',
  ADD_CONTACT_TO_CLIENT_FAILURE = 'ADD_CONTACT_TO_CLIENT_FAILURE',
  ADD_NOTE_TO_CLIENT_INIT = 'ADD_NOTE_TO_CLIENT_INIT',
  ADD_NOTE_TO_CLIENT_SUCCESS = 'ADD_NOTE_TO_CLIENT_SUCCESS',
  ADD_NOTE_TO_CLIENT_FAILURE = 'ADD_NOTE_TO_CLIENT_FAILURE',
  UPDATE_CLIENT_NOTE_INIT = 'UPDATE_CLIENT_NOTE_INIT',
  UPDATE_CLIENT_NOTE_SUCCESS = 'UPDATE_CLIENT_NOTE_SUCCESS',
  UPDATE_CLIENT_NOTE_FAILURE = 'UPDATE_CLIENT_NOTE_FAILURE',
  MANAGE_FAVOURITE_INIT = 'MANAGE_FAVOURITE_INIT',
  MANAGE_FAVOURITE_SUCCESS = 'MANAGE_FAVOURITE_SUCCESS',
  MANAGE_FAVOURITE_FAILURE = 'MANAGE_FAVOURITE_FAILURE',
  GET_CLIENT_STATS_INIT = 'GET_CLIENT_STATS_INIT',
  GET_CLIENT_STATS_SUCCESS = 'GET_CLIENT_STATS_SUCCESS',
  GET_CLIENT_STATS_FAILURE = 'GET_CLIENT_STATS_FAILURE',
  GET_CLIENT_STATS_FOR_USER_INIT = 'GET_CLIENT_STATS_FOR_USER_INIT',
  GET_CLIENT_STATS_FOR_USER_SUCCESS = 'GET_CLIENT_STATS_FOR_USER_SUCCESS',
  GET_CLIENT_STATS_FOR_USER_FAILURE = 'GET_CLIENT_STATS_FOR_USER_FAILURE',
  GET_CLIENTS_XLSX_INIT = 'GET_CLIENTS_XLSX_INIT',
  GET_CLIENTS_XLSX_SUCCESS = 'GET_CLIENTS_XLSX_SUCCESS',
  GET_CLIENTS_XLSX_FAILURE = 'GET_CLIENTS_XLSX_FAILURE',
}

export enum Auth {
  LOG_IN = 'LOG_IN',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  LOG_OUT = 'LOG_OUT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',
  GET_AUTHORIZED_USER = 'GET_AUTHORIZED_USER',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
}

export enum ViewManagement {
  SHOW_FILE_LOADER = 'SHOW_FILE_LOADER',
  HIDE_FILE_LOADER = 'HIDE_FILE_LOADER',
  SHOW_LOADER = 'SHOW_LOADER',
  HIDE_LOADER = 'HIDE_LOADER',
  SET_PREV_PATH = 'SET_PREV_PATH',
  EXPAND_MENU = 'EXPAND_MENU',
  EXPAND_ADD_REMINDER_VIEW = 'EXPAND_ADD_REMINDER_VIEW',
  SHOW_CONFIRM_POPUP = 'SHOW_CONFRIM_POPUP',
  HIDE_CONFIRM_POPUP = 'HIDE_CONFIRM_POPUP',
}

export enum GROUP {
  GET_GROUP_INIT = 'GET_GROUP_INIT',
  GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS',
  GET_GROUP_FAILURE = 'GET_GROUP_FAILURE',
  ADD_GROUP_INIT = 'ADD_GROUP_INIT',
  ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS',
  ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE',
  EDIT_GROUP_INIT = 'EDIT_GROUP_INIT',
  EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS',
  EDIT_GROUP_FAILURE = 'EDIT_GROUP_FAILURE',
  DELETE_GROUP_INIT = 'DELETE_GROUP_INIT',
  DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS',
  DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE',
  GET_GROUP_ID_USERS_INIT = 'GET_GROUP_ID_USERS_INIT',
  GET_GROUP_ID_USERS_SUCCESS = 'GET_GROUP_ID_USERS_SUCCESS',
  GET_GROUP_ID_USERS_FAILURE = 'GET_GROUP_ID_USERS_FAILURE',
  UPDATE_GROUP_ID_USERS_INIT = 'UPDATE_GROUP_ID_USERS_INIT',
  UPDATE_GROUP_ID_USERS_SUCCESS = 'UPDATE_GROUP_ID_USERS_SUCCESS',
  UPDATE_GROUP_ID_USERS_FAILURE = 'UPDATE_GROUP_ID_USERS_FAILURE',
  GET_CRM_SETTINGS_INIT = 'GET_CRM_SETTINGS_INIT',
  GET_CRM_SETTINGS_SUCCESS = 'GET_CRM_SETTINGS_SUCCESS',
  GET_CRM_SETTINGS_FAILURE = 'GET_CRM_SETTINGS_FAILURE',
  PATCH_CRM_SETTINGS_INIT = 'PATCH_CRM_SETTINGS_INIT',
  PATCH_CRM_SETTINGS_SUCCESS = 'PATCH_CRM_SETTINGS_SUCCESS',
  PATCH_CRM_SETTINGS_FAILURE = 'PATCH_CRM_SETTINGS_FAILURE',
  GET_GROUP_BY_ID_INIT = 'GET_GROUP_BY_ID_INIT',
  GET_GROUP_BY_ID_SUCCESS = 'GET_GROUP_BY_ID_SUCCESS',
  GET_GROUP_BY_ID_FAILURE = 'GET_GROUP_BY_ID_FAILURE',
  GET_PRIVILEGES_INIT = 'GET_PRIVILEGES_INIT',
  GET_PRIVILEGES_SUCCESS = 'GET_PRIVILEGES_SUCCESS',
  GET_PRIVILEGES_FAILURE = 'GET_PRIVILEGES_FAILURE',
}

export enum CASES {
  GET_REPORT_CASE_SUCCESS = 'GET_REPORT_CASE_SUCCESS',
  GET_CASE_COUNTS_INIT = 'GET_CASE_COUNTS_INIT',
  GET_CASE_COUNTS_SUCCESS = 'GET_CASE_COUNTS_SUCCESS',
  GET_CASE_COUNTS_FAILURE = 'GET_CASE_COUNTS_FAILURE',
  GET_CASES_LIST_INIT = 'GET_CASES_LIST_INIT',
  GET_CASES_LIST_SUCCESS = 'GET_CASES_LIST_SUCCESS',
  GET_CASES_LIST_FAILURE = 'GET_CASES_LIST_FAILURE',
  GET_CLIENT_CASES_LIST_INIT = 'GET_CLIENT_CASES_LIST_INIT',
  GET_CLIENT_CASES_LIST_SUCCESS = 'GET_CLIENT_CASES_LIST_SUCCESS',
  GET_CLIENT_CASES_LIST_FAILURE = 'GET_CLIENT_CASES_LIST_FAILURE',
  GET_CASE_DETAILS_INIT = 'GET_CASE_DETAILS_INIT',
  GET_CASE_DETAILS_SUCCESS = 'GET_CASE_DETAILS_SUCCESS',
  GET_CASE_DETAILS_FAILURE = 'GET_CASE_DETAILS_FAILURE',
  GET_EXTERNAL_CASE_DETAILS_INIT = 'GET_EXTERNAL_CASE_DETAILS_INIT',
  GET_EXTERNAL_CASE_DETAILS_SUCCESS = 'GET_EXTERNAL_CASE_DETAILS_SUCCESS',
  GET_EXTERNAL_CASE_DETAILS_FAILURE = 'GET_EXTERNAL_CASE_DETAILS_FAILURE',
  ADD_CASE_STATUS_DESCRIPTION_INIT = 'ADD_CASE_STATUS_DESCRIPTION_INIT',
  ADD_CASE_STATUS_DESCRIPTION_SUCCESS = 'ADD_CASE_STATUS_DESCRIPTION_SUCCESS',
  ADD_CASE_STATUS_DESCRIPTION_FAILURE = 'ADD_CASE_STATUS_DESCRIPTION_FAILURE',
  ADD_CASE_REMAINDER_INIT = 'ADD_CASE_REMAINDER_INIT',
  ADD_CASE_REMAINDER_SUCCESS = 'ADD_CASE_REMAINDER_SUCCESS',
  ADD_CASE_REMAINDER_FAILURE = 'ADD_CASE_REMAINDER_FAILURE',
  REPLY_TO_REMAINDER_INIT = 'REPLY_TO_REMAINDER_INIT',
  REPLY_TO_REMAINDER_SUCCESS = 'REPLY_TO_REMAINDER_SUCCESS',
  REPLY_TO_REMAINDER_FAILURE = 'REPLY_TO_REMAINDER_FAILURE',
  EDIT_CASE_STATUS_DESCRIPTION_INIT = 'EDIT_CASE_STATUS_DESCRIPTION_INIT',
  EDIT_CASE_STATUS_DESCRIPTION_SUCCESS = 'EDIT_CASE_STATUS_DESCRIPTION_SUCCESS',
  EDIT_CASE_STATUS_DESCRIPTION_FAILURE = 'EDIT_CASE_STATUS_DESCRIPTION_FAILURE',
  DELETE_CASE_STATUS_DESCRIPTION_INIT = 'DELETE_CASE_STATUS_DESCRIPTION_INIT',
  DELETE_CASE_STATUS_DESCRIPTION_SUCCESS = 'DELETE_CASE_STATUS_DESCRIPTION_SUCCESS',
  DELETE_CASE_STATUS_DESCRIPTION_FAILURE = 'DELETE_CASE_STATUS_DESCRIPTION_FAILURE',
  RESUME_CASE_INIT = 'RESUME_CASE_INIT',
  RESUME_CASE_SUCCESS = 'RESUME_CASE_SUCCESS',
  RESUME_CASE_FAILURE = 'RESUME_CASE_FAILURE',
  FINISH_CASE_INIT = 'FINISH_CASE_INIT',
  FINISH_CASE_SUCCESS = 'FINISH_CASE_SUCCESS',
  FINISH_CASE_FAILURE = 'FINISH_CASE_FAILURE',
  ADD_CASE_INIT = 'ADD_CASE_INIT',
  ADD_CASE_SUCCESS = 'ADD_CASE_SUCCESS',
  ADD_CASE_FAILURE = 'ADD_CASE_FAILURE',
  GET_CLIENT_CASE_COUNTS_INIT = 'GET_CLIENT_CASE_COUNTS_INIT',
  GET_CLIENT_CASE_COUNTS_SUCCESS = 'GET_CLIENT_CASE_COUNTS_SUCCESS',
  GET_CLIENT_CASE_COUNTS_FAILURE = 'GET_CLIENT_CASE_COUNTS_FAILURE',
  DELETE_CASE_INIT = 'DELETE_CASE_INIT',
  DELETE_CASE_SUCCESS = 'DELETE_CASE_SUCCESS',
  DELETE_CASE_FAILURE = 'DELETE_CASE_FAILURE',
  EDIT_CASE_INIT = 'EDIT_CASE_INIT',
  EDIT_CASE_SUCCESS = 'EDIT_CASE_SUCCESS',
  EDIT_CASE_FAILURE = 'EDIT_CASE_FAILURE',
  SEARCH_CASE_INIT = 'SEARCH_CASE_INIT',
  SEARCH_CASE_SUCCESS = 'SEARCH_CASE_SUCCESS',
  SEARCH_CASE_FAILURE = 'SEARCH_CASE_FAILURE',
  GET_FAVOURITE_CASES_INIT = 'GET_FAVOURITE_CASES_INIT',
  GET_FAVOURITE_CASES_SUCCESS = 'GET_FAVOURITE_CASES_SUCCESS',
  GET_FAVOURITE_CASES_FAILURE = 'GET_FAVOURITE_CASES_FAILURE',
  MANAGE_FAVOURITE_INIT = 'MANAGE_FAVOURITE_INIT',
  MANAGE_FAVOURITE_SUCCESS = 'MANAGE_FAVOURITE_SUCCESS',
  MANAGE_FAVOURITE_FAILURE = 'MANAGE_FAVOURITE_FAILURE',
  GET_EXTERNAL_USER_CASES_COUNTS_INIT = 'GET_EXTERNAL_USER_CASES_COUNTS_INIT',
  GET_EXTERNAL_USER_CASES_COUNTS_SUCCESS = 'GET_EXTERNAL_USER_CASES_COUNTS_SUCCESS',
  GET_EXTERNAL_USER_CASES_COUNTS_FAILURE = 'GET_EXTERNAL_USER_CASES_COUNTS_FAILURE',
  GET_EXTERNAL_USER_CASES_LIST_INIT = 'GET_EXTERNAL_USER_CASES_LIST_INIT',
  GET_EXTERNAL_USER_CASES_LIST_SUCCESS = 'GET_EXTERNAL_USER_CASES_LIST_SUCCESS',
  GET_EXTERNAL_USER_CASES_LIST_FAILURE = 'GET_EXTERNAL_USER_CASES_LIST_FAILURE',
  GET_CASE_FILES_INIT = 'GET_CASE_FILES_INIT',
  GET_CASE_FILES_SUCCESS = 'GET_CASE_FILES_SUCCESS',
  GET_CASE_FILES_FAILURE = 'GET_CASE_FILES_FAILURE',
  SET_ELECTRONIC_ONLY_INIT = 'SET_ELECTRONIC_ONLY_INIT',
  SET_ELECTRONIC_ONLY_SUCCESS = 'SET_ELECTRONIC_ONLY_SUCCESS',
  SET_ELECTRONIC_ONLY_FAILURE = 'SET_ELECTRONIC_ONLY_FAILURE',
  GET_CASE_STATUSES_INIT = 'GET_CASE_STATUSES_INIT',
  GET_CASE_STATUSES_SUCCESS = 'GET_CASE_STATUSES_SUCCESS',
  GET_CASE_STATUSES_FAILURE = 'GET_CASE_STATUSES_FAILURE',
}

export enum TimeTrial {
  GET_TIME_TRIAL_LIST_INIT = 'GET_TIME_TRIAL_LIST_INIT',
  GET_TIME_TRIAL_LIST_SUCCESS = 'GET_TIME_TRIAL_LIST_SUCCESS',
  GET_TIME_TRIAL_LIST_FAILURE = 'GET_TIME_TRIAL_LIST_FAILURE',
  ADD_TIME_TRIAL_INIT = 'ADD_TIME_TRIAL_INIT',
  ADD_TIME_TRIAL_SUCCESS = 'ADD_TIME_TRIAL_SUCCESS',
  ADD_TIME_TRIAL_FAILURE = 'ADD_TIME_TRIAL_FAILURE',
  EDIT_TIME_TRIAL_INIT = 'EDIT_TIME_TRIAL_INIT',
  EDIT_TIME_TRIAL_SUCCESS = 'EDIT_TIME_TRIAL_SUCCESS',
  EDIT_TIME_TRIAL_FAILURE = 'EDIT_TIME_TRIAL_FAILURE',
  DELETE_TIME_TRIAL_INIT = 'DELETE_TIME_TRIAL_INIT',
  DELETE_TIME_TRIAL_SUCCESS = 'DELETE_TIME_TRIAL_SUCCESS',
  DELETE_TIME_TRIAL_FAILURE = 'DELETE_TIME_TRIAL_FAILURE',
  GET_TIME_TRIAL_XLSX_INIT = 'GET_TIME_TRIAL_XLSX_INIT',
  GET_TIME_TRIAL_XLSX_SUCCESS = 'GET_TIME_TRIAL_XLSX_SUCCESS',
  GET_TIME_TRIAL_XLSX_FAILURE = 'GET_TIME_TRIAL_XLSX_FAILURE',
}

export enum Reminder {
  GET_REMINDER_LIST_INIT = 'GET_REMINDER_LIST_INIT',
  GET_REMINDER_LIST_SUCCESS = 'GET_REMINDER_LIST_SUCCESS',
  GET_REMINDER_LIST_FAILURE = 'GET_REMINDER_LIST_FAILURE',
  GET_ACTIVE_REMINDER_LIST_INIT = 'GET_ACTIVE_REMINDER_LIST_INIT',
  GET_ACTIVE_REMINDER_LIST_SUCCESS = 'GET_ACTIVE_REMINDER_LIST_SUCCESS',
  GET_ACTIVE_REMINDER_LIST_FAILURE = 'GET_ACTIVE_REMINDER_LIST_FAILURE',
  GET_ALL_REMINDER_LIST_INIT = 'GET_ALL_REMINDER_LIST_INIT',
  GET_ALL_REMINDER_LIST_SUCCESS = 'GET_ALL_REMINDER_LIST_SUCCESS',
  GET_ALL_REMINDER_LIST_FAILURE = 'GET_ALL_REMINDER_LIST_FAILURE',
  ADD_REMINDER_INIT = 'ADD_REMINDER_INIT',
  ADD_REMINDER_SUCCESS = 'ADD_REMINDER_SUCCESS',
  ADD_REMINDER_FAILURE = 'ADD_REMINDER_FAILURE',
  POSTPONE_REMINDER_INIT = 'POSTPONE_REMINDER_INIT',
  POSTPONE_REMINDER_SUCCESS = 'POSTPONE_REMINDER_SUCCESS',
  POSTPONE_REMINDER_FAILURE = 'POSTPONE_REMINDER_FAILURE',
  REJECT_REMINDER_INIT = 'REJECT_REMINDER_INIT',
  REJECT_REMINDER_SUCCESS = 'REJECT_REMINDER_SUCCESS',
  REJECT_REMINDER_FAILURE = 'REJECT_REMINDER_FAILURE',
  RESPOND_REMINDER_INIT = 'RESPOND_REMINDER_INIT',
  RESPOND_REMINDER_SUCCESS = 'RESPOND_REMINDER_SUCCESS',
  RESPOND_REMINDER_FAILURE = 'RESPOND_REMINDER_FAILURE',
}

export enum Limits {
  GET_CLIENTS_REACHING_LIMITS_INIT = 'GET_CLIENTS_REACHING_LIMITS_INIT',
  GET_CLIENTS_REACHING_LIMITS_SUCCESS = 'GET_CLIENTS_REACHING_LIMITS_SUCCESS',
  GET_CLIENTS_REACHING_LIMITS_FAILURE = 'GET_CLIENTS_REACHING_LIMITS_FAILURE',
  GET_CLIENTS_REACHING_LIMITS_COUNT_INIT = 'GET_CLIENTS_REACHING_LIMITS_COUNT_INIT',
  GET_CLIENTS_REACHING_LIMITS_COUNT_SUCCESS = 'GET_CLIENTS_REACHING_LIMITS_COUNT_SUCCESS',
  GET_CLIENTS_REACHING_LIMITS_COUNT_FAILURE = 'GET_CLIENTS_REACHING_LIMITS_COUNT_FAILURE',
}
