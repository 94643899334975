/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { ROUTES, PRIVILEGES } from 'shared/consts';
import { checkPrivilegeAccess } from 'shared/utils';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { CASE_OPTIONS, CASE_TYPES } from 'Components/Pages/AddCase/consts';
import { MainType } from './Container';
import { ExternalPagination } from 'shared/components';
import CasesListTableHeader from './components/CasesListTableHeader';
import ReactTooltip from 'react-tooltip';

type S = { filters: any; searchParams; isCasesListRolledUp: boolean };
type P = {
  location: any;
  history: any;
};

class AddTimeTrial extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        pageSize: JSON.parse(sessionStorage.getItem('pageSize'))?.value ?? 10,
        pageNo: 0,
      },
      searchParams: {},
      isCasesListRolledUp: false,
    };
  }

  // async componentDidMount() {
  //   const {
  //     actions: { searchCase },
  //     location: { state },
  //   } = this.props;
  //   if (state?.isSidebarSearch) {
  //     await searchCase({ ...this.state.filters, caseNumberOrSignature: state.caseNumber });
  //   }
  // }

  triggerExpandCasesList = () => {
    this.setState({ isCasesListRolledUp: !this.state.isCasesListRolledUp });
  };

  goToCaseDetails = (id, external) => (external ? generatePath(ROUTES.EXTERNAL_CASE_DETAILS, { id }) : generatePath(ROUTES.CASE_DETAILS, { id }));

  onSizeChange = (size) => {
    this.setState({ filters: { ...this.state.filters, pageSize: size } }, () => this.props.actions.searchCase({ ...this.state.searchParams, ...this.state.filters, pageSize: size }));
  };
  onPageChange = (page) => this.setState({ filters: { ...this.state.filters, pageNo: page } }, () => this.props.actions.searchCase({ ...this.state.searchParams, ...this.state.filters, pageNo: page }));

  public render() {
    const {
      actions: { searchCase },
      searchedCasesList,
      location: { state },
      authorizedUser: { external },
    } = this.props;
    const { pageNo, pageSize } = this.state.filters;

    // console.log(searchedCasesList, 'SearchedList');
    const isCardCaseAccess = checkPrivilegeAccess(PRIVILEGES.CASE_CARD);

    return (
      <div className="search-engine">
        {!state?.isSidebarSearch && (
          <div className={`add-time-trial ${this.state.isCasesListRolledUp ? 'add-time-trial--rolled-up' : ''}`}>
            <div className="add-time-trial__title">
              <Link to={ROUTES.DASHBOARD}>
                <img src={GrayArrow} alt="" />
              </Link>
              <h2>Szukaj sprawy</h2>
            </div>
            <div className="add-time-trial__form-container">
              <Formik
                initialValues={{}}
                onSubmit={async (values: any) => {
                  this.setState({ searchParams: { ...values, caseStatusType: values.caseStatusType === 'ALL' ? '' : values.caseStatusType, clientName: values.clientName?.name } });
                  await searchCase({ ...values, caseStatusType: values.caseStatusType === 'ALL' ? '' : values.caseStatusType, clientName: values.clientName?.name, pageNo, pageSize });
                }}>
                {({ handleSubmit, errors, setFieldValue, values }: any) => (
                  <form onSubmit={handleSubmit} className="add-time-trial__form">
                    <Field name="caseType" type="select" wrapperClassName="default" placeholder="Wybierz typ" label="Typ sprawy" options={CASE_OPTIONS} component={RegularField} errors={errors} />
                    {values.caseType === CASE_TYPES.COURT && (
                      <>
                        <Field name="plaintiff" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Powód/Wnioskodawca/Wierzyciel" component={RegularField} errors={errors} />
                        <Field name="defendant" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Pozwany/Uczestnik/Dłużnik" component={RegularField} errors={errors} />
                        {/* <Field name="defendant" type="asyncSelect" setValueWithClientName placeholder="Wpisz pozwanego" label="Pozwany" component={RegularField} setFieldValue={setFieldValue} errors={errors} external={external} /> */}
                        {/* <Field name="plaintiff" type="asyncSelect" setValueWithClientName initValue={this.props.location.state?.company} placeholder="Wpisz powoda" label="Powód" component={RegularField} setFieldValue={setFieldValue} errors={errors} external={external} /> */}
                      </>
                    )}
                    <Field name="clientName" type="asyncSelect" setValueWithClientName initValue={this.props.location.state?.company} placeholder="Wpisz klienta" label="Klient" component={RegularField} setFieldValue={setFieldValue} errors={errors} external={external} />
                    <Field name="name" type="text" wrapperClassName="default" placeholder="Wpisz nazwę" label="Nazwa sprawy" component={RegularField} errors={errors} />
                    <Field name="tags" type="text" wrapperClassName="default" placeholder="Wpisz tagi" label="Tagi" component={RegularField} errors={errors} />
                    <Field
                      name="caseStatusType"
                      type="select"
                      wrapperClassName="default"
                      placeholder="Wybierz typ"
                      label="Status sprawy"
                      options={[
                        {
                          label: 'Wszystkie',
                          value: 'ALL',
                        },
                        {
                          label: 'W toku',
                          value: 'IN_PROGRESS',
                        },
                        {
                          label: 'Zakończona',
                          value: 'CLOSED',
                        },
                      ]}
                      component={RegularField}
                      errors={errors}
                    />
                    <Field name="caseNumberOrSignature" type="text" wrapperClassName="default" placeholder="Wpisz sygnaturę lub nr sprawy" label="Sygnatura lub nr sprawy" component={RegularField} errors={errors} />
                    <button className="btn btn--default btn--login btn--center" type="submit">
                      Szukaj
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        )}
        {!state?.isSidebarSearch ? (
          <div className={`cases-list__expander cases-list__expander--search-engine ${this.state.isCasesListRolledUp ? 'cases-list__expander--rolled-up ' : ''}`} onClick={() => this.triggerExpandCasesList()}>
            <img className={`cases-list__expander--icon ${this.state.isCasesListRolledUp ? 'cases-list__expander--icon-right' : ''}`} src={GrayArrow} alt="" />
          </div>
        ) : null}
        <div className={`cases-list__container ${state?.isSidebarSearch ? 'cases-list__container--full-width' : ''} ${this.state.isCasesListRolledUp ? 'cases-list__container--rolled-up ' : ''}`}>
          {searchedCasesList.content && (
            <>
              <div className="cases-list__header">
                <div className="cases-list__title">
                  <h2> Wyniki wyszukiwania</h2>
                </div>
              </div>
              <div className="search-engine__pagination">
                <ExternalPagination size={searchedCasesList.size} pages={searchedCasesList.totalPages} page={pageNo} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
              </div>
              <div className="cases-list__table cases-list__table--search-engine">
                <table className="table">
                  <CasesListTableHeader onFilterChange={() => console.log('filtruje')} filterValues={{}} isCardCaseAccess={isCardCaseAccess || external} />
                  <tbody>
                    {searchedCasesList.content.map(({ id, company, name, executive1FullName, executive2FullName, caseNumber, status, createdAt, plaintiff, defendant, signature, wps, kind, favourite, electronicOnly }) => (
                      <tr className="hoverable" key={caseNumber}>
                        {(isCardCaseAccess || external) && (
                          <td className={`actions ${electronicOnly ? 'actions--electronicOnly' : ''}`}>
                            <a href={this.goToCaseDetails(id, external)} className="btn-action btn-action--open" data-tip="Karta sprawy" />
                            {/* <button className="btn-action btn-action--open" data-tip="Karta sprawy" onClick={() => this.goToCaseDetails(id)}></button> */}
                          </td>
                        )}
                        <td>{caseNumber}</td>
                        <td>{company}</td>
                        <td>{name}</td>
                        <td>{executive1FullName}</td>
                        <td>{executive2FullName}</td>
                        <td>{createdAt}</td>
                        <td>{status}</td>
                        <td>{plaintiff || '--'}</td>
                        <td>{defendant || '--'}</td>
                        {/*<td>{signature || '--'}</td>
                        <td>{wps || '--'}</td>
                        <td>{kind || '--'}</td>
                        <td>{'--'}</td> */}
                        <td>{'--'}</td>
                        {/* <td>{favourite ? 'Tak' : 'Nie'}</td> */}
                        <ReactTooltip place="top" effect="solid" className="tooltip" />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default AddTimeTrial;
