import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

type PrivateRouteProps = {
  component: any;
  path: string;
};

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps =>
        localStorage.getItem('token') ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
