import * as React from 'react';

const filterKeys = ['id', 'company', 'type', 'nip', 'address', 'postalCode', 'city', 'phone', 'email', 'letter'];

const keyTranslations = {
  id: 'Nr Klienta',
  company: 'Nazwisko/Nazwa firmy',
  type: 'Typ',
  nip: 'NIP',
  address: 'Adres',
  postalCode: 'Kod pocztowy',
  city: 'Miasto',
  phone: 'Telefon',
  email: 'Email',
  letter: 'Litera porządkowa',
};

const CustomFilters = ({ filters = {}, onFilterChange }: any) => (
  <>
    {filterKeys.map((key) => (
      <th key={key}>
        {keyTranslations[key]}
        <br />
        <input type="text" name={key} id={key} value={filters[key]} onChange={({ target: { value } }) => onFilterChange(key, value)} />
      </th>
    ))}
  </>
);
export default CustomFilters;
