import { CASES } from '../EnumTypes/ActionEnumTypes';
import { getStatusesForCase as getStatusesForCaseConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: CASES.GET_CASE_STATUSES_INIT,
});

const success = (data) => ({
  type: CASES.GET_CASE_STATUSES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CASES.GET_CASE_STATUSES_FAILURE,
});

const getStatusesForCase = (caseId) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showFileLoader());
    const { data } = await getStatusesForCaseConnector(caseId);
    dispatch(success(data.statusDescriptions));
    dispatch(ViewManagementModule.Actions.hideFileLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideFileLoader());
    dispatch(failure());
  }
};

export default getStatusesForCase;
