import * as React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as UserModule from 'Modules/UserModule';
import * as ClientModule from 'Modules/ClientModule';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Selectors as ClientSelectors } from 'Modules/ClientModule';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { ExternalUserDetails, AddClients, AssignedClients } from './components';
import { STEP_TITLE, STEP_BUTTON_LABEL, VALIDATION_SCHEMA } from './consts';
import { mapFormValuesToRequestData, initializeClients, mapClientsToRequest } from './utils';

type S = {
  numberOfClients: number;
  clients: Array<any>;
  step: number;
  formType: 'ADD' | 'EDIT';
};
type P = {
  match: any;
};
type ConnectedP = {
  prevPath: string;
  clientsSelectList: any;
  externalUser: any;
};
type DispatchedP = {
  addInternalUser: (data: any) => void;
  getClientsSelectList: () => void;
  addExternalUser: (userData: any) => void;
  editExternalUser: (userData) => void;
  getSpecificInternalUser: (id: number) => void;
  updateClientsForExternalUser: (clients, userId) => void;
};

class AddExternalUser extends React.Component<DispatchedP & ConnectedP & P, S> {
  constructor(props: DispatchedP & ConnectedP & P) {
    super(props);
    this.state = {
      numberOfClients: 0,
      clients: [],
      step: this.isEditMode() ? 1 : 0,
      formType: this.isEditMode() ? 'EDIT' : 'ADD',
    };
  }
  async componentDidMount() {
    if (this.state.formType === 'EDIT') {
      await this.props.getSpecificInternalUser(this.props.match.params.id);
      this.setState({
        clients: initializeClients(this.props.externalUser?.clients),
      });
    }
  }
  isEditMode = () => this.props.match.url.includes('edit') === true;

  isClientSelected = (value) => {
    return this.props.clientsSelectList.some((client) => client.value === value);
  };

  addClientToUser = async (values, { setFieldValue, setFieldTouched }) => {
    const newClientsArr = [...this.state.clients];
    console.log(values, 'wartosci z forma');
    if (newClientsArr.some(({ id }) => id === values.id.id)) {
      const currentClientIdx = newClientsArr.findIndex(({ id }) => id === values.id.id);
      newClientsArr[currentClientIdx].details = values.id;
      newClientsArr[currentClientIdx].req = values.clients[0];
      newClientsArr[currentClientIdx].id = values.id.id;
    } else {
      newClientsArr.push({ id: values.id.id, details: values.id, req: values.clients[0] });
    }

    console.log(newClientsArr, 'wartosci z forma 2');
    await this.setState(
      () => ({
        clients: newClientsArr,
      }),
      () => {
        setFieldValue('id', '');
        setFieldTouched('id', false, false);
        setFieldValue('clients', []);
        setFieldTouched('clients', false, false);
      },
    );
  };

  addClientsStep = () => {
    this.setState({
      step: 1,
    });
  };

  submitExternalUser = async (values) => {
    if (this.isEditMode()) {
      const { userName } = this.props.externalUser?.userExternalDto || {};
      const { firstName, lastName, email, phone } = values || {};
      console.log(this.state.clients, 'wrzucam clients do submita');
      await this.props.updateClientsForExternalUser(mapClientsToRequest(this.state.clients), this.props.externalUser?.userExternalDto.id);
      await this.props.editExternalUser({ user: { firstName, lastName, email, id: this.props.match.params.id, phone, userName } });
    } else {
      await this.props.addExternalUser(mapFormValuesToRequestData(values, this.state.clients));
    }
  };

  handleSubmit = (values, actions) => {
    if (this.state.step === 0) {
      this.addClientsStep();
    } else if (this.state.step === 1 && values.clients.length > 0) {
      this.addClientToUser(values, actions);
    }
  };

  removeClient = (id) => {
    const currentClients = [...this.state.clients];
    currentClients.splice(id, 1);
    this.setState({
      clients: currentClients,
    });
  };

  public render() {
    const { prevPath, externalUser = {} } = this.props;
    const { firstName, lastName, email, id, phone } = externalUser?.userExternalDto || {};
    const { step } = this.state;
    const initialValues = this.isEditMode()
      ? {
          firstName,
          lastName,
          phone,
          email,
          id,
        }
      : { firstName: '', lastName: '', email: '', id: '', phone: '', clients: [] };
    return (
      <Formik initialValues={initialValues} validationSchema={VALIDATION_SCHEMA[step]} enableReinitialize={true} onSubmit={this.handleSubmit}>
        {({ handleSubmit, errors, setFieldValue, values, validateOnBlur }) => (
          <div className="add-customer--details__wrapper">
            <div className="add-customer add-customer--details">
              <div className="add-customer--details__inner">
                <div className="add-customer__title">
                  <Link to={prevPath ? prevPath : '/dashboard'}>
                    <img src={GrayArrow} alt="" />
                  </Link>
                  <h2>{STEP_TITLE[step]}</h2>
                </div>
                <div className="add-customer__form-container">
                  <form onSubmit={handleSubmit} className="add-customer__form">
                    {step === 0 && <ExternalUserDetails errors={errors} />}
                    {step === 1 && <AddClients values={values} validateOnBlur={validateOnBlur} errors={errors} setFieldValue={setFieldValue} isClientSelected={this.isClientSelected} numberOfClients={this.state.numberOfClients} />}
                    <button className="btn btn--default btn--login btn--center" type="submit">
                      {STEP_BUTTON_LABEL[step]}
                    </button>
                  </form>
                </div>
              </div>
            </div>
            {step === 1 && (
              <div className="add-customer--details__summary">
                <div className="add-customer__title">
                  <h2>Podsumowanie</h2>
                </div>
                <div className="add-customer__subtitle add-customer__subtitle--margintop">
                  <h3>Użytkownik zęwnętrzny</h3>
                </div>
                <div className="add-customer--details__userInfo">
                  <div className="add-customer--details__userInfo--name">
                    {values.firstName} {values.lastName}
                  </div>
                  <div className="add-customer--details__userInfo--phone">{values.phone}</div>
                  <div className="add-customer--details__userInfo--email">{values.email}</div>
                  <div className="add-customer--details__userInfo--actions" onClick={() => this.setState({ step: 0 })}>
                    <button className="btn-action btn-action--edit" data-tip="Edytuj"></button>
                  </div>
                </div>
                <div className="add-customer__subtitle">
                  <h3>Przypisani klienci i ich typy spraw</h3>
                </div>
                {this.state.clients.length > 0 && <AssignedClients clients={this.state.clients} onRemove={this.removeClient} />}
                <div className="add-customer--details__actions">
                  <div className="add-customer--details__addCustomerButton">
                    <button className="btn btn--default btn--login btn--center btn--addNewCustomer" type="button" disabled={this.state.clients?.length === 0} onClick={() => this.submitExternalUser(values)}>
                      Zatwierdzam dane użytkownika
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Formik>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  addInternalUser: (data: any) => UserModule.Actions.addInternalUser(data),
  getClientsSelectList: () => ClientModule.Actions.getCLientsSelectList(),
  addExternalUser: (userData) => UserModule.Actions.addExternalUser(userData),
  editExternalUser: (userData) => UserModule.Actions.editExternalUser(userData),
  updateClientsForExternalUser: (clients, userId) => UserModule.Actions.updateClientsForExternalUser(clients, userId),
  getSpecificInternalUser: (id: number) => UserModule.Actions.getSpecificInternalUser(id, 'EXTERNAL'),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    prevPath: ViewManagementSelectors.prevPath(state),
    clientsSelectList: ClientSelectors.clientsSelectList(state),
    externalUser: UserModule.Selectors.getSpecificExternalUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddExternalUser);
