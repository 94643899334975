/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import ReactTooltip from 'react-tooltip';
import { MainType } from './Container';
import { ROUTES } from 'shared/consts';
import CasesListTableHeader from './components/CasesListTableHeader';
import Pagination from '../../Pagination';

type S = {
  params: any;
  isCasesListRolledUp: boolean;
  filters: any;
};

const CASES_TYPE_LABELS = {
  various: 'różne',
  court: 'sądowe',
  vindication: 'windykacja',
  contract: 'umowy',
  other: 'inne',
};

type P = {
  location: any;
  history: any;
  match: any;
};
const getURLParameters = (url: string): Record<string, string> => {
  const params: Record<string, string> = {};
  const urlParts = url.split('?');
  if (urlParts.length > 1) {
    const queryString = urlParts[1];
    const paramPairs = queryString.split('&');
    paramPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      params[key] = value;
    });
  }
  console.log(params);

  sessionStorage.setItem('pageSize', JSON.stringify({ label: params.pageSize || 10, value: params.pageSize || 10 }));
  return params;
};
export default class CasesList extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      params: this.getParamsOnInit(),
      isCasesListRolledUp: false,
      filters: {
        pageSize: getURLParameters(window.location.search).pageSize || 10,
        pageNo: parseFloat(getURLParameters(window.location.search).pageNo) || 0,
      },
    };
  }

  triggerExpandCasesList = () => {
    this.setState({ isCasesListRolledUp: !this.state.isCasesListRolledUp });
  };

  getParamsOnInit = () => {
    if (this.props.location.search) {
      const urlSearchParams = new URLSearchParams(this.props.location.search);
      const params = {};
      urlSearchParams.forEach((value, key) => (params[key] = value));
      return {
        ...params,
        caseStatusType: undefined,
        caseType: undefined,
      };
    } else {
      return {
        caseStatusType: undefined,
        caseType: undefined,
        pageNo: parseFloat(getURLParameters(window.location.search).pageNo) || 0,
        pageSize: parseFloat(getURLParameters(window.location.search).pageSize) || 10,
      };
    }
  };

  async componentDidMount() {
    // await this.getCounts();
    await this.props.getExternalUserCasesCounts();
    await this.getCases();
    // await this.props.getExternalUserCasesList({ clientId: 1, pageNo: 0, pageSize: 25, caseType: 'VINDICATION' });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState(
        {
          params: this.getParamsOnInit(),
        },
        async () => {
          await this.props.getExternalUserCasesCounts();
          await this.getCases();
        },
      );
    }
  }

  getCases = async () => {
    this.updateUrlWithFilters();
    // if (this.isCustomer()) {
    //   await this.props.getClientCasesList(this.props.match.params.id, this.state.params);
    // } else {
    await this.props.getExternalUserCasesList(this.state.params);
    // }
  };

  // getCounts = async () => {
  //   if (this.isCustomer()) {
  //     await this.props.getClientCaseCounts(this.props.match.params.id);
  //   } else {
  //     await this.props.getCaseCounts(this.isContractor() ? 'executive1' : 'executive2');
  //   }
  // };

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  changeCaseStatus = (caseType, caseStatusType, clientId) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          caseStatusType,
          clientId,
          caseType: caseType?.toUpperCase(),
        },
      },
      () => this.getCases(),
    );
  };

  changeCaseType = (caseType, clientId) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          pageNo: 0,
          caseStatusType: undefined,
          clientId,
          caseType: caseType?.toUpperCase(),
        },
        filters: {
          pageNo: 0,
        },
      },
      () => this.getCases(),
    );
  };

  onFilterChange = (id, value) => {
    this.setState(
      {
        params: {
          ...this.state.params,
          [id]: value,
        },
      },
      () => this.getCases(),
    );
  };

  handlePageOrSizeChange = (...args) => {
    const [page, size] = args;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          pageNo: page,
          pageSize: size,
        },
        params: {
          ...this.state.params,
          pageNo: page,
          pageSize: size,
        },
      },
      async () => await this.getCases(),
    );
  };

  updateUrlWithFilters = () => {
    const urlSearchParams = new URLSearchParams();

    Object.keys(this.state.params).map((key) => {
      if (this.state.params[key] !== undefined) {
        urlSearchParams.set(key, this.state.params[key]);
      }
      return true;
    });

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: urlSearchParams.toString(),
    });
  };

  goToCaseDetails = (id) => generatePath(ROUTES.EXTERNAL_CASE_DETAILS, { id });

  handleManageFavourite = async (id, favourite) => {
    const { manageFavourite } = this.props;

    await manageFavourite(id, !favourite);
    await this.getCases();
  };

  public render() {
    const { prevPath, externalUserCasesCounts, externalUserCasesList } = this.props;
    const { content = [], totalPages } = this.props.externalUserCasesList ?? {};

    // console.log(externalUserCasesList, 'externalUserCasesList');
    // console.log(externalUserCasesCounts, 'externalUserCasesCounts');

    return (
      <div className="cases-list">
        <div className={`cases-list__summary ${this.state.isCasesListRolledUp ? 'cases-list__summary--rolled-up' : ''}`}>
          <div className="cases-list__summary--content">
            <div className="cases-list__summary__title">
              <Link to={prevPath ? prevPath : '/dashboard'}>
                <img src={GrayArrow} alt="" />
              </Link>
              <h2>Moje sprawy</h2>
            </div>

            {/* /// */}
            {externalUserCasesCounts.length > 0 &&
              externalUserCasesCounts.map((item) => (
                <>
                  <div className={'cases-list__summary__company'}>{item.company}</div>
                  <div className="cases-list__short-summary">
                    <p>
                      Sprawy <br /> w toku <span className="cases-list__short-summary--in-progress">{item['total']?.inProgress}</span>
                    </p>
                    <p>
                      Zakończone <span className="cases-list__short-summary--done">{item['total']?.closed}</span>
                    </p>
                  </div>
                  <div className="cases-list__cases-status">
                    <p className="cases-list__cases-status--in-progress">W toku</p>
                    <p className="cases-list__cases-status--all">Wszystkie</p>
                    <p className="cases-list__cases-status--done">Zakończone</p>
                  </div>
                  <div className="cases-list__cases-types">
                    <ul>
                      <li className={`${this.state.params.caseType === undefined && this.state.params.clientId === item.clientId ? 'cases-list__cases-types--selected' : ''}`}>
                        <div className="caseTypeTitle" onClick={() => this.changeCaseType(undefined, item.clientId)}>
                          Wszystkie
                        </div>
                        <span className="container">
                          <span className="cases-list__cases-types--in-progress" onClick={() => this.changeCaseStatus(undefined, 'IN_PROGRESS', item.clientId)}>
                            {item['total']?.inProgress}
                          </span>
                          <span className="cases-list__cases-types--all" onClick={() => this.changeCaseStatus(undefined, undefined, item.clientId)}>
                            {item['total']?.total}
                          </span>
                          <span className="cases-list__cases-types--done" onClick={() => this.changeCaseStatus(undefined, 'CLOSED', item.clientId)}>
                            {item['total']?.closed}
                          </span>
                        </span>
                      </li>
                      {Object.keys(CASES_TYPE_LABELS).map((caseType) => (
                        <li key={caseType} className={`${this.state.params.caseType?.toLowerCase() === caseType && this.state.params.clientId === item.clientId ? 'cases-list__cases-types--selected' : ''}`}>
                          <div className="caseTypeTitle" onClick={() => this.changeCaseType(caseType, item.clientId)}>
                            {CASES_TYPE_LABELS[caseType]}
                          </div>
                          <span className="container">
                            <span className="cases-list__cases-types--in-progress" onClick={() => this.changeCaseStatus(caseType, 'IN_PROGRESS', item.clientId)}>
                              {item[caseType]?.inProgress}
                            </span>
                            <span className="cases-list__cases-types--all" onClick={() => this.changeCaseStatus(caseType, undefined, item.clientId)}>
                              {item[caseType]?.total}
                            </span>
                            <span className="cases-list__cases-types--done" onClick={() => this.changeCaseStatus(caseType, 'CLOSED', item.clientId)}>
                              {item[caseType]?.closed}
                            </span>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ))}
            {/* ///// */}
          </div>
          <div className={`cases-list__expander ${this.state.isCasesListRolledUp ? 'cases-list__expander--rolled-up' : ''}`} onClick={() => this.triggerExpandCasesList()}>
            <img className={`cases-list__expander--icon ${this.state.isCasesListRolledUp ? 'cases-list__expander--icon-right' : ''}`} src={GrayArrow} alt="" />
          </div>
        </div>
        <div className={`cases-list__container cases-list__container--without-scroll ${this.state.isCasesListRolledUp ? 'cases-list__container--full-width' : ''}`}>
          {externalUserCasesList && (
            <>
              <div className="cases-list__header">
                <div className="cases-list__title">
                  <h2> {this.state.params.caseType ? CASES_TYPE_LABELS[this.state.params.caseType.toLowerCase()] : 'Wszystkie'}</h2>
                </div>
              </div>
              {content && <Pagination pages={totalPages} getFunction={this.handlePageOrSizeChange} pageNo={this.state.filters.pageNo} />}
              <div className="cases-list__table">
                <table className="table">
                  <CasesListTableHeader onFilterChange={this.onFilterChange} filterValues={this.state.params} />
                  <tbody>
                    {externalUserCasesList.content.map(({ id, executive1FullName, executive2FullName, caseNumber, status, createdAt, plaintiff, defendant, signature, wps, kind, name, favourite, company }) => (
                      <tr className="hoverable" key={caseNumber}>
                        <td className="actions">
                          <a href={this.goToCaseDetails(id)} className="btn-action btn-action--open" data-tip="Karta sprawy" />
                          {/* <button className="btn-action btn-action--open" data-tip="Karta sprawy" onClick={() => this.goToCaseDetails(id)}></button> */}
                          <button className={`btn-action btn-action--${favourite ? 'favourite-selected' : 'favourite'}`} data-tip={favourite ? 'Usuń z ulubionych' : `Dodaj do ulubionych`} onClick={() => this.handleManageFavourite(id, favourite)}></button>
                        </td>
                        <td>{caseNumber}</td>
                        <td>{company}</td>
                        <td>{name}</td>
                        <td>{createdAt}</td>
                        <td>{status}</td>
                        {/* <td>{plaintiff || '--'}</td>
                        <td>{defendant || '--'}</td>
                        <td>{signature || '--'}</td>
                        <td>{wps || '--'}</td>
                        <td>{kind || '--'}</td> */}
                        <td>{'--'}</td>
                        <td>{'--'}</td>
                        <ReactTooltip place="top" effect="solid" className="tooltip" />
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
