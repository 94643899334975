import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';

export type State = any;

export const initialState: State = {
  timeTrialList: null,
};

export function timeTrialReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ActionTypes.TimeTrial.GET_TIME_TRIAL_LIST_SUCCESS: {
      return {
        ...state,
        timeTrialList: action.payload.timeTrialList,
      };
    }
    default: {
      return state;
    }
  }
}
