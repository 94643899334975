import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addCaseRemainder } from 'Store/Actions/cases';
import * as UserModule from 'Modules/UserModule';

type ConnectedP = { internalUserOptions: any };
type DispatchedP = {
  actions: {
    addCaseRemainder: (caseRemainderData, caseId) => void;
    getInternalUserOptions: () => void;
  };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      addCaseRemainder: (caseRemainderData, caseId) => addCaseRemainder(caseRemainderData, caseId),
      getInternalUserOptions: () => UserModule.Actions.getInternalUserOptions(),
    },
    dispatch,
  ),
});

function mapStateToProps(state): ConnectedP {
  return {
    internalUserOptions: UserModule.Selectors.getInternalUserOptions(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
