import { CASES } from '../EnumTypes/ActionEnumTypes';
import { resumeCase as resumeCaseConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { getCaseDetails } from './';

const init = () => ({
  type: CASES.RESUME_CASE_INIT,
});

const success = () => ({
  type: CASES.RESUME_CASE_SUCCESS,
});

const failure = () => ({
  type: CASES.RESUME_CASE_FAILURE,
});

const resumeCase = (caseId) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await resumeCaseConnector(caseId);
    dispatch(success());
    toast.success('Pomyślnie wznowiono sprawę');
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(getCaseDetails(caseId));
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się wznowić sprawy');
    dispatch(failure());
  }
};

export default resumeCase;
