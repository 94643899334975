import { TimeTrial } from '../EnumTypes/ActionEnumTypes';
import { getTimeTrialXlsx as getTimeTrialXlsxConnector } from 'Connectors/TimeTrialConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: TimeTrial.GET_TIME_TRIAL_XLSX_INIT,
});

const success = () => ({
  type: TimeTrial.GET_TIME_TRIAL_XLSX_SUCCESS,
});

const failure = () => ({
  type: TimeTrial.GET_TIME_TRIAL_XLSX_FAILURE,
});

const getTimeTrialXlsx = (params) => async (dispatch) => {

  dispatch(init());

  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await getTimeTrialXlsxConnector(params);
    console.log(data);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Czasówka.xlsx');
    document.body.appendChild(link);
    link.click();
    dispatch(success());
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    console.log(err)
    dispatch(failure());
  }
};

export default getTimeTrialXlsx;
