import * as React from 'react';
import { MainType } from './Container';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import EditContactForm from './components/editContactForm';
import { history } from 'Store/Reducers/RootReducer';

type P = {
  location: any;
  match: any;
};

class Main extends React.Component<MainType & P, any> {
  constructor(props: MainType & P) {
    super(props);
    this.state = {
      data: null,
    };
  }
  async componentDidMount() {
    await this.props.actions.getClientSingleContact(this.props.match.params.id);
    this.setState({ data: this.props.clientSingleContact.contact });
  }
  handleSubmit = async (values) => {
    await this.props.actions.editClientSingleContact(values);
    history.goBack();
  };

  render() {
    return (
      <div className="addCaseRemainder narrow-section">
        <div className="narrow-section__title">
          <div onClick={() => history.goBack()}>
            <img src={GrayArrow} alt="" />
          </div>
          <h2>Edytuj osobę kontakową</h2>
        </div>
        <div className="add-customer__form-container">
          <EditContactForm data={this.state.data} onSubmit={this.handleSubmit} />
        </div>
      </div>
    );
  }
}

export default Main;
