import * as Yup from 'yup';

const STEP_TITLE = {
  0: 'Dodaj użytkownika zewnętrznego',
  1: 'Przypisz klientów',
};
const STEP_BUTTON_LABEL = {
  0: 'Przejdź dalej',
  1: 'Dodaj sprawę',
};

const VALIDATION_SCHEMA = {
  0: Yup.object().shape({
    firstName: Yup.string().required('Pole wymagane'),
    lastName: Yup.string().required('Pole wymagane'),
    email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
    phone: Yup.string().required('Pole wymagane'),
  }),
  1: Yup.object().shape({ id: Yup.string().required('Pole wymagane') }),
};

const CASE_TYPES = {
  VARIOUS: 'VARIOUS',
  COURT: 'COURT',
  VINDICATION: 'VINDICATION',
  CONTRACT: 'CONTRACT',
  OTHER: 'OTHER',
};

const CASES_TYPE_LABELS = {
  various: 'różne',
  court: 'sądowe',
  vindication: 'windykacja',
  contract: 'umowy',
  other: 'inne',
};

const CASE_OPTIONS = [
  { label: 'Różne', value: 'VARIOUS' },
  { label: 'Sądowe i windykacyjne', value: 'COURT' },
  // { label: 'Windykacja', value: 'VINDICATION' },
  { label: 'Umowy', value: 'CONTRACT' },
  { label: 'Inne', value: 'OTHER' },
];
const ADD_CASE_OPTIONS = [
  { label: 'Różne', value: 'VARIOUS' },
  { label: 'Sądowe', value: 'COURT' },
  { label: 'Windykacja', value: 'VINDICATION' },
  { label: 'Umowy', value: 'CONTRACT' },
  { label: 'Inne', value: 'OTHER' },
];

export { STEP_TITLE, STEP_BUTTON_LABEL, VALIDATION_SCHEMA, CASES_TYPE_LABELS, CASE_OPTIONS, CASE_TYPES, ADD_CASE_OPTIONS };
