import { TimeTrial } from '../EnumTypes/ActionEnumTypes';
import { deleteTimeTrial as deleteTimeTrialConnector } from 'Connectors/TimeTrialConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';
import { store } from 'Store/Store';
import getTimeTrialList from './getTimeTrialList';

const init = () => ({
  type: TimeTrial.DELETE_TIME_TRIAL_INIT,
});

const success = () => ({
  type: TimeTrial.DELETE_TIME_TRIAL_SUCCESS,
});

const failure = () => ({
  type: TimeTrial.DELETE_TIME_TRIAL_FAILURE,
});

const deleteTimeTrial = (id) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await deleteTimeTrialConnector(id);
    dispatch(success());
    await dispatch(ViewManagementModule.Actions.hideConfirmPopup());
    const { timeTrialList } = store.getState().timeTrialStore;
    await dispatch(getTimeTrialList(timeTrialList ? { pageSize: timeTrialList.timeEntries.pageable.pageSize, pageNo: timeTrialList.timeEntries.pageable.pageNumber } : { pageSize: 10, pageNo: 1 }));
    toast.success('Pomyślnie usunięto czasówkę');
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(ROUTES.TIME_TRIAL_LIST);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się usunąć czasówki');
    dispatch(failure());
  }
};

export default deleteTimeTrial;
