import { Connector } from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const checkCanActivate = (token) => async (dispatch) => {
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await Connector.checkCanActivate(token);
        dispatch(ViewManagementModule.Actions.hideLoader());
        return true;
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        return false
    }
};

export default checkCanActivate;
