import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addCaseRemainder } from 'Store/Actions/cases';
import * as ClientModule from 'Modules/ClientModule';

type ConnectedP = { clientSingleContact: any };
type DispatchedP = {
  actions: {
    getClientSingleContact: (id) => void;
    editClientSingleContact: (data) => void;
  };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      editClientSingleContact: (id) => ClientModule.Actions.editClientSingleContact(id),
      getClientSingleContact: (id) => ClientModule.Actions.getClientSingleContact(id),
      addCaseRemainder: (caseRemainderData, caseId) => addCaseRemainder(caseRemainderData, caseId),
    },
    dispatch,
  ),
});

function mapStateToProps(state): ConnectedP {
  return {
    clientSingleContact: ClientModule.Selectors.getClientSingleContact(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
