import { Client } from '../EnumTypes/ActionEnumTypes';
import { deleteClient as deleteClientConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';
import { store } from 'Store/Store';
import getClients from './getClients';
import  getSidebarCounts  from 'Store/Actions/user/getSidebarCounts';

const init = () => ({
  type: Client.DELETE_CLIENT_INIT,
});

const success = () => ({
  type: Client.DELETE_CLIENT_SUCCESS,
});

const failure = () => ({
  type: Client.DELETE_CLIENT_FAILURE,
});

const deleteClient = (id) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await deleteClientConnector(id);
    dispatch(success());
    await dispatch(ViewManagementModule.Actions.hideConfirmPopup());
    const { clientsList }: any = store.getState().clientStore;
    await dispatch(getClients(clientsList ? { size: clientsList.pageable.pageSize, page: clientsList.pageable.pageNumber } : { size: 10, page: 1 }));
    toast.success('Pomyślnie usunięto klienta');
    dispatch(ViewManagementModule.Actions.hideLoader());
    await dispatch(getSidebarCounts());
    history.push(ROUTES.CUSTOMER_LIST);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się usunąć klienta');
    dispatch(failure());
  }
};

export default deleteClient;
