import API from './Config';
import { AxiosPromise } from 'axios';

export function getTimeTrialList(params): AxiosPromise<any> {
  return API.get(`time-entry`, params ? { params: { size: 20, ...params } } : null);
}

export function addTimeTrial(data): AxiosPromise<any> {
  return API.post(`time-entry`, data);
}

export function editTimeTrial(data): AxiosPromise<any> {
  return API.put(`time-entry`, data);
}

export function deleteTimeTrial(id): AxiosPromise<any> {
  return API.delete(`time-entry/${id}`);
}

export function getTimeTrialXlsx(params): AxiosPromise<any> {
  return API.get(`time-entry/xlsx`, { responseType: 'blob', params: { ...params } });
}
