import * as React from 'react';
import { connect } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';
import * as AuthModule from '../../../../Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as ReminderModule from 'Modules/ReminderModule';
import * as UserModule from 'Modules/UserModule';
import * as Yup from 'yup';
import { RootState } from '../../../../Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import { Link } from 'react-router-dom';
// import ReactTransitionGroup from 'react-addons-transition-group';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
// import ReactHtmlParser from 'react-html-parser';

type S = {};
type P = {
  match: any;
};
type ConnectedP = {
  isMenuExpanded: boolean;
  isAddReminderView: boolean;
  authorizedUser: AuthModule.Types.AuthorizedUserData;
};
type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
  postponeReminder: (reminderGroup) => void;
  getInternalUserOptions: () => void;
};
class AddReminder extends React.Component<DispatchedP & ConnectedP & P, S> {
  componentDidMount() {
    this.props.getInternalUserOptions();
  }

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };
  public render() {
    const AddReminderSchema = Yup.object().shape({
      remindAt: Yup.string().required('Pole wymagane'),
    });
    const reminderStringFromStorage = sessionStorage.getItem('reminder');
    const initialReminder = JSON.parse(reminderStringFromStorage);

    const { id } = this.props.match.params;

    return (
      <div className="add-reminder">
        <div className="add-reminder__title">
          <Link to="/dashboard">
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Edytuj przypomnienie</h2>
        </div>
        <div className="add-reminder__form-container">
          <Formik
            initialValues={{ remindAt: initialReminder.remindAt || '', about: initialReminder.about }}
            validationSchema={AddReminderSchema}
            onSubmit={async (values) => {
              await this.props.postponeReminder({ reminderUpdateDto: { ...values, id } });
            }}>
            {({ handleSubmit, errors, setFieldValue }) => (
              <>
                <form onSubmit={handleSubmit} className="add-reminder__form">
                  <Field name="remindAt" type="date" wrapperClassName="default" placeholder="Podaj datę" label="Data" component={RegularField} errors={errors} />
                  {/* <Field name="about" type="textarea" wrapperClassName="default" label="Treść przypomnienia" component={RegularField} errors={errors} /> */}
                  <Field name="about" type="draft" wrapperClassName="default" label="Treść przypomnienia" component={RegularField} errors={errors} />
                  <button className="btn btn--default btn--login btn--center" type="submit">
                    Edytuj przypomnienie
                  </button>
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  postponeReminder: (reminderData) => ReminderModule.Actions.postponeReminder(reminderData),
  getInternalUserOptions: () => UserModule.Actions.getInternalUserOptions(),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    isAddReminderView: ViewManagementSelectors.isAddReminderView(state),
    authorizedUser: AuthSelectors.authorizedUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddReminder);
