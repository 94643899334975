import { Reminder } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/ReminderModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
// import { ROUTES } from 'shared/consts';
// import { history } from 'Store/Reducers/RootReducer';
import getActiveRemidersList from './getActiveRemidersList';
import getAllRemindersList from './getAllRemindersList';
import { store } from 'Store/Store';

const init = () => ({
    type: Reminder.REJECT_REMINDER_INIT,
});

const success = () => ({
    type: Reminder.REJECT_REMINDER_SUCCESS,
});

const failure = () => ({
    type: Reminder.REJECT_REMINDER_FAILURE,
});

const rejectReminder = (id) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await Connector.rejectReminder(id);
        dispatch(success());
        await dispatch(ViewManagementModule.Actions.hideConfirmPopup());
        await store.getState().reminderStore.remindersList?.reminders?.length ? dispatch(getActiveRemidersList()) : dispatch(getAllRemindersList({ page: 0, size: 10 }));
        toast.success('Pomyślnie odrzucono przypomnienie');
        // history.push(ROUTES.REMAINDER_LIST);
    } catch (err) {
        console.log(err, 'error')
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.error('Nie udało się odrzucić przypomnienia');
        dispatch(failure());
    }
};

export default rejectReminder;
