enum PRIVILEGES {
    CLIENT_ADD = 'CLIENT_ADD',
    CLIENT_EDIT = 'CLIENT_EDIT',
    TIMER_ALL = 'TIMER_ALL',
    EXPORT = 'EXPORT',
    CLIENT_DEL = 'CLIENT_DEL',
    CASE_CARD = 'CASE_CARD',
    STATS_VIEW = 'STATS_VIEW',
    CASE_ADD = 'CASE_ADD'
}

export default PRIVILEGES;
