import * as React from 'react';
import { Link } from 'react-router-dom';
import { MainType } from './Container';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { ROUTES } from 'shared/consts';
import * as Yup from 'yup';
import EditRemainderForm from './components/editReminderForm';

type P = {
  location: any;
  match: any;
};

class Main extends React.Component<MainType & P, any> {
  constructor(props: MainType & P) {
    super(props);
    this.state = {

    };
  }

  async componentDidMount() {
    // await this.props.actions.getCaseDetails(this.props.match.params.id);
    // this.props.actions.getInternalUserOptions();
    // this.setState({ caseType: this.props.caseData?.caseDetails?.caseType });
  }

  handleSubmit = async (values) => {

    // this.props.actions.editCase(values);
    console.log(values);
  };

  handleGoBack = () => this.setState({ step: 0 });

  addCaseSchema = () => {
    return Yup.object().shape({

      executive1Id: Yup.string().required('Pole wymagane'),
      executive2Id: Yup.string().required('Pole wymagane'),
      caseType: Yup.string().required('Pole wymagane'),
      name: Yup.string().required('Pole wymagane'),

    });
  };

  render() {

    const { caseData } = this.props;
    const initialValues = caseData?.caseDetails || {};

    return (
          <div className="add-customer--details__wrapper">
            <div className="add-customer add-customer--details">
              <div className="add-customer--details__inner">
                <div className="add-customer__title">
                  <Link to={ROUTES.CASES_LIST_CONTRACTOR}>
                    <img src={GrayArrow} alt="" />
                  </Link>
                  <h2>Edytuj Przypomnienie</h2>
                </div>
                <div className="add-customer__form-container">
                    <EditRemainderForm onSubmit={this.handleSubmit} initialValues={initialValues} />
                </div>
              </div>
            </div>
          </div>

    );
  }
}

export default Main;
