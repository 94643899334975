import * as React from 'react';
import { FieldProps } from 'formik';

type P = {
  label?: string;
  errors: any;
  id?: any;
  disabled?: boolean;
};

class Users extends React.PureComponent<P & FieldProps, any> {
  handleChange = (id) => {
    const {
      form: { setFieldValue },
      field: { name, value },
    } = this.props;
    value[id].active = !value[id].active;
    setFieldValue(name, value);
  };

  render() {
    const {
      label,
      field: { value = [], name },
      form: { touched },
      errors = {},
      disabled,
    } = this.props;

    let formClasses: string = 'form__field form__field--checkbox';
    formClasses += disabled ? ' form__field--disabled' : '';
    formClasses += touched[name] && errors[name] ? ' form__field--error' : '';

    return (
      <div className={`users ${formClasses}`}>
        <label className="field-label">{label}</label>
        {value.map((user, idx) => (
          <div key={user.firstName} className="checkbox__details">
            <input id={user.id} name={user.id} disabled={disabled || false} onChange={() => this.handleChange(idx)} checked={user.active} value={user.active} type={'checkbox'} />
            <label className="custom-box" htmlFor={user.id} />
            <label htmlFor={user.id}>
              {user.firstName} {user.lastName}
            </label>
          </div>
        ))}
      </div>
    );
  }
}

export default Users;
