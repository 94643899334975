import { Client } from '../EnumTypes/ActionEnumTypes';
import { getClientsList as getClientsListConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Client.GET_CLIENTS_LIST_INIT,
});

const success = (clientsList) => ({
  type: Client.GET_CLIENTS_LIST_SUCCESS,
  payload: {
    clientsList,
  },
});

const failure = () => ({
  type: Client.GET_CLIENTS_LIST_FAILURE,
});

const getClientsList = (params) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await getClientsListConnector(params);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getClientsList;
