import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addCase, editCase, getCaseDetails } from 'Store/Actions/cases';
import * as UserModule from 'Modules/UserModule';
import * as ClientModule from 'Modules/ClientModule';
import * as CaseSelector from 'Store/Selectors/CaseSelector';

type ConnectedP = {
    internalUserOptions: any;
    caseData: any;
    usersStats: any;
    clientStats: any;
};
type DispatchedP = {
    actions: {
        addCase: (caseData) => void;
        editCase: (caseData) => void;
        getInternalUserOptions: () => void;
        getCaseDetails: (id: string) => void;
        getAllUsersStats: () => void;
        getClientStats: (params: any) => void;
        getClientStatsForUser: (params: any) => void;
    };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
    actions: bindActionCreators(
        {
            addCase: (caseData) => addCase(caseData),
            getInternalUserOptions: () => UserModule.Actions.getInternalUserOptions(),
            getCaseDetails: (id) => getCaseDetails(id),
            editCase: (caseData) => editCase(caseData),
            getAllUsersStats: () => UserModule.Actions.getAllUsersStats(),
            getClientStats: (params: any) => ClientModule.Actions.getClientStats(params),
            getClientStatsForUser: (params: any) => ClientModule.Actions.getClientStatsForUser(params),
        },
        dispatch,
    ),
});

function mapStateToProps(state): ConnectedP {
    return {
        internalUserOptions: UserModule.Selectors.getInternalUserOptions(state),
        caseData: CaseSelector.getCaseDetails(state),
        usersStats: UserModule.Selectors.getAllUsersStats(state),
        clientStats: ClientModule.Selectors.getClientStats(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
