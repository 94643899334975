/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ArrowUp from 'Assets/Img/Icons/arrow-up.svg';
import ArrowDown from 'Assets/Img/Icons/arrow-down.svg';

const Header = ({files, setFiles}) => {
  const [dataArrow, setDataArrow] = useState(false);
  const [nameArrow, setNameArrow] = useState(false);
  const handleDataSort = () => {
    const sortedData = [...files].sort((a, b) => {
      const dateA = new Date(a.modifiedAt).getTime();
      const dateB = new Date(b.modifiedAt).getTime();
      setDataArrow(!dataArrow)
      setNameArrow(false)
      return dataArrow ? dateB - dateA : dateA - dateB;
    });
    setFiles(sortedData);
  }
  const handleNameSort = () => {
    const sortedData = [...files].sort((a, b) => nameArrow ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name));
    setNameArrow(!nameArrow)
    setDataArrow(false)
    setFiles(sortedData);
  }

  return (
    <div className="files__table--header">
      <div>Rodzaj</div>
      <div className="head">Nazwa <img onClick={handleNameSort} src={nameArrow ? ArrowUp : ArrowDown} alt=''/></div>
      <div className="data">Data modyfikacji <img onClick={handleDataSort} src={dataArrow ? ArrowUp : ArrowDown} alt=''/></div>
    </div>
  );
};

export default Header;
