import * as React from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';
import * as AuthModule from 'Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as ClientModule from 'Modules/ClientModule';
import * as Yup from 'yup';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';

type S = {};

type P = {
  location: any;
  history: any;
  company: any;
};

type ConnectedP = {
  prevPath: string;
};
type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
  addClient: (data) => any;
  editClient: (data) => void;
};
class AddCustomer extends React.Component<DispatchedP & ConnectedP & P, S> {
  isEdit: boolean;
  constructor(props) {
    super(props);
    this.isEdit = this.isEditMode();
  }

  isEditMode = () => this.props.location.pathname.includes('edit');

  public render() {
    const AddCustomerSchema = Yup.object().shape(
      {
        company: Yup.string().required('Pole wymagane'),
        clientType: Yup.string().required('Pole wymagane'),
        // nip: Yup.number().required('Pole wymagane'),
        // address: Yup.string().required('Pole wymagane'),
        // postalCode: Yup.string().required('Pole wymagane'),
        // city: Yup.string().required('Pole wymagane'),
        // phone: Yup.number().required('Pole wymagane'),
        // email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
        letter: Yup.string().required('Pole wymagane'),
        warningLimit: Yup.string().when(['maximumLimit'], {
          is: (maximumLimit) =>  !maximumLimit,
          then: Yup.string(),
          otherwise: Yup.string().required('Pole wymagane')
        }).nullable(true),
        maximumLimit: Yup.string().when(['warningLimit'], {
          is: (warningLimit) =>  !warningLimit,
          then: Yup.string(),
          otherwise: Yup.string().required('Pole wymagane')
        }).test('match', 
        'limit końcowy > limit ostrzegawczy', 
         function(limitMax) { 
           if(this.parent.warningLimit !== undefined && this.parent.warningLimit !== null) {
             if(Number(limitMax) > Number(this.parent.warningLimit)) {
               return true
              } else {
               return false
             }
           } return true
         }).nullable(true),
      }, [['warningLimit', 'maximumLimit']]
    );

    const CLIENT_TYPE_OPTIONS = [
      {
        label: 'Firma',
        value: 'COMPANY',
      },
      {
        label: 'Indywidualny',
        value: 'INDIVIDUAL',
      },
      {
        label: 'Zlecenie stałe',
        value: 'ASSIGNMENT',
      },
      {
        label: 'Z urzędu',
        value: 'LEGAL_AID',
      },
    ];

    const { prevPath, addClient, editClient } = this.props;
    const title = this.isEdit ? 'Edytuj klienta' : 'Dodaj klienta';

    return (
      <div className="add-customer">
        <div className="add-customer__title">
          <Link to={prevPath ? prevPath : '/dashboard'}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>{title}</h2>
        </div>
        <div className="add-customer__form-container">
          <Formik
            initialValues={this.props.location.state || { company: '', clientType: '', nip: '', address: '', postalCode: '', city: '', phone: '', email: '', letter: '', warningLimit: '', maximumLimit: ''}}
            validationSchema={AddCustomerSchema}
            onSubmit={async (values) => {
              // await addClient(values);
              if (this.isEdit) {
                await editClient({ ...values });
              } else {
               const response =  await addClient(values);
               console.log(response);
              }
            }}>
            {({ handleSubmit, errors, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="add-customer__form">
                <Field name="company" type="text" wrapperClassName="default" placeholder="Wpisz nazwę firmy lub nazwisko" label="Nazwa firmy / Nazwisko i Imię" component={RegularField} errors={errors} />
                <Field name="clientType" type="select" placeholder="Wybierz typ" label="Typ" options={CLIENT_TYPE_OPTIONS} component={RegularField} setFieldValue={setFieldValue} errors={errors} />
                <Field name="nip" type="number" wrapperClassName="default" placeholder="Wpisz NIP" label="NIP" component={RegularField} errors={errors} />
                <Field name="address" type="text" wrapperClassName="default" placeholder="Wpisz adres" label="Adres" component={RegularField} errors={errors} />
                <Field name="postalCode" type="text" wrapperClassName="default" placeholder="Wpisz kod pocztowy" label="Kod pocztowy" component={RegularField} errors={errors} />
                <Field name="city" type="text" wrapperClassName="default" placeholder="Wpisz miejscowość" label="Miejscowość" component={RegularField} errors={errors} />
                <Field name="phone" type="number" wrapperClassName="default" placeholder="Wpisz numer tel" label="Telefon" component={RegularField} errors={errors} />
                <Field name="email" type="email" wrapperClassName="default" placeholder="Wpisz adres email" label="Email" component={RegularField} errors={errors} />
                <Field name="letter" type="select" placeholder="Wybierz literę" label="Litera porządkowa" options={LETTER_OPTIONS} component={RegularField} setFieldValue={setFieldValue} errors={errors} />
                <Field name="warningLimit" type="number" wrapperClassName="default"  label="Limit ostrzegawczy" component={RegularField} errors={errors} />
                <Field name="maximumLimit" type="number" wrapperClassName="default"  label="Limit końcowy" component={RegularField} errors={errors} />
                <button className="btn btn--default btn--login btn--center" type="submit">
                  {title}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  addClient: (data) => ClientModule.Actions.addClient(data),
  editClient: (data) => ClientModule.Actions.editClient(data),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    prevPath: ViewManagementSelectors.prevPath(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);

const LETTER_OPTIONS = [
  {
    label: 'A',
    value: 'A',
  },
  {
    label: 'B',
    value: 'B',
  },
  {
    label: 'C',
    value: 'C',
  },
  {
    label: 'D',
    value: 'D',
  },
  {
    label: 'E',
    value: 'E',
  },
  {
    label: 'F',
    value: 'F',
  },
  {
    label: 'G',
    value: 'G',
  },
  {
    label: 'H',
    value: 'H',
  },
  {
    label: 'I',
    value: 'I',
  },
  {
    label: 'J',
    value: 'J',
  },
  {
    label: 'K',
    value: 'K',
  },
  {
    label: 'L',
    value: 'L',
  },
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'N',
    value: 'N',
  },
  {
    label: 'O',
    value: 'O',
  },
  {
    label: 'P',
    value: 'P',
  },
  {
    label: 'Q',
    value: 'Q',
  },
  {
    label: 'R',
    value: 'R',
  },
  {
    label: 'S',
    value: 'S',
  },
  {
    label: 'T',
    value: 'T',
  },
  {
    label: 'U',
    value: 'U',
  },
  {
    label: 'V',
    value: 'V',
  },
  {
    label: 'W',
    value: 'W',
  },
  {
    label: 'X',
    value: 'X',
  },
  {
    label: 'Y',
    value: 'Y',
  },  
  {
    label: 'Z',
    value: 'Z',
  },
];
