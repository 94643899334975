import * as React from 'react';

const CasesListTableHeader = ({ onFilterChange, filterValues }: any) => (
  <thead>
    <tr>
      <th className="actions">Akcje</th>
      <FilterCell id={'caseNumber'} title={'Nr sprawy'} onChange={onFilterChange} filterValue={filterValues?.caseNumber} />
      <FilterCell id={'name'} title={'Nazwa sprawy'} onChange={onFilterChange} filterValue={filterValues?.name} />
      <FilterCell id={'executive1FullName'} title={'Wykonawca 1'} onChange={onFilterChange} filterValue={filterValues?.executive1FullName} />
      <FilterCell id={'executive2FullName'} title={'Wykonawca 2'} onChange={onFilterChange} filterValue={filterValues?.executive2FullName} />
      <FilterCell id={'createdAt'} title={'Data założenia'} onChange={onFilterChange} filterValue={filterValues?.createdAt} />
      <FilterCell id={'status'} title={'Status realizacji'} onChange={onFilterChange} filterValue={filterValues?.status} />
      <FilterCell id={'plaintiff'} title={'Powód/Wnioskodawca/Wierzyciel'} onChange={onFilterChange} filterValue={filterValues?.plaintiff} />
      <FilterCell id={'defendant'} title={'Pozwany/Uczestnik/Dłużnik'} onChange={onFilterChange} filterValue={filterValues?.defendant} />
      <FilterCell id={'signature'} title={'Sygnatury'} onChange={onFilterChange} filterValue={filterValues?.signature} />
      <FilterCell id={'wps'} title={'WPS'} onChange={onFilterChange} filterValue={filterValues?.wps} />
      <FilterCell id={'kind'} title={'Rodzaj'} onChange={onFilterChange} filterValue={filterValues?.kind} />
      {/* <FilterCell id={'caseFile'} title={'Karta sprawy'} onChange={onFilterChange} disabled filterValue={filterValues?.caseFile} /> */}
      <FilterCell id={'caseFolder'} title={'Folder sprawy'} onChange={onFilterChange} disabled filterValue={filterValues?.caseFolder} />
      <FilterCell id={'caseNumber'} title={'Ulubione'} onChange={onFilterChange} disabled filterValue={filterValues?.caseNumber} />
    </tr>
  </thead>
);

const FilterCell = ({ id, title, filterValue = '', onChange, disabled = false }: any) => (
  <th key={id}>
    {title}
    <br />
    <input disabled={disabled} value={filterValue} type="text" name={id} id={id} onChange={({ target: { value } }) => onChange(id, value)} />
  </th>
);

export default CasesListTableHeader;
