import { GROUP } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/GroupModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: GROUP.GET_GROUP_BY_ID_INIT,
});

const success = (group) => ({
  type: GROUP.GET_GROUP_BY_ID_SUCCESS,
  payload: {
    group,
  },
});

const failure = () => ({
  type: GROUP.GET_GROUP_BY_ID_FAILURE,
});

const getGroupById = (id) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await Connector.getGroupById(id);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getGroupById;
