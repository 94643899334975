import * as React from 'react';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { MainType } from './Container';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { ROUTES } from 'shared/consts';
import ReplyToRemainderForm from './components/replyToRemainderForm';

type P = {
  match: any;
};

class Main extends React.Component<MainType & P, any> {
  handleSubmit = async (values) => {
    await this.props.actions.replyToRemainder(values, this.props.match.params.id);
  };
  render() {
    const { params } = this.props.match;
    return (
      <div className="replyToRemainder narrow-section">
        <div className="narrow-section__title">
          <Link to={generatePath(ROUTES.CASE_DETAILS, { id: params.id })}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Odpowiedz na przypomnienie</h2>
        </div>
        <div className="add-customer__form-container">
          <ReplyToRemainderForm onSubmit={this.handleSubmit} />
        </div>
      </div>
    );
  }
}

export default Main;
