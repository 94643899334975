import * as React from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';
import * as AuthModule from '../../../../Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as Yup from 'yup';
import { RootState } from '../../../../Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Actions as TimeTrialActions } from 'Modules/TimeTrialModule';
import { Link } from 'react-router-dom';
import { ROUTES } from 'shared/consts';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';

type S = {};
type P = {
  location: any;
  history: any;
};
type ConnectedP = {
  prevPath: string;
};
type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
  addTimeTrial: (data) => void;
  editTimeTrial: (data) => void;
};
class AddTimeTrial extends React.Component<DispatchedP & ConnectedP & P, S> {
  isEdit: boolean;
  constructor(props) {
    super(props);
    this.isEdit = this.isEditMode();
  }

  isEditMode = () => this.props.location.pathname.includes('edit');

  public render() {
    const AddTimeTrialSchema = Yup.object().shape({
      clientId: Yup.string().required('Pole wymagane'),
      ordering: Yup.string().required('Pole wymagane'),
      description: Yup.string().required('Pole wymagane'),
      time: Yup.number().typeError('Wpisz liczbę').required('Pole wymagane'),
    });

    const options = [
      {
        label: 'Klient 1',
        value: 'test',
      },
      {
        label: 'Klient 1',
        value: 'test',
      },
      {
        label: 'Klient 1',
        value: 'test',
      },
    ];
    const { addTimeTrial, editTimeTrial } = this.props;
    const title = this.isEdit ? 'Edytuj czasówkę' : 'Dodaj czasówkę';

    return (
      <div className="add-time-trial">
        <div className="add-time-trial__title">
          <Link to={ROUTES.TIME_TRIAL_LIST}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>{title}</h2>
        </div>
        <div className="add-time-trial__form-container">
          <Formik
            initialValues={this.props.location.state ? { ...this.props.location.state, clientId: { id: this.props.location.state.clientId, name: this.props.location.state.company } } : { clientId: '', ordering: '', description: '', time: '' }}
            validationSchema={AddTimeTrialSchema}
            onSubmit={async (values: any) => {
              if (this.isEdit) {
                await editTimeTrial({ ...values, clientId: values.clientId.id });
              } else {
                await addTimeTrial({ ...values, clientId: values.clientId.id });
              }
            }}>
            {({ handleSubmit, errors, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="add-time-trial__form">
                {/* <Field name="customer" type="select" placeholder="Wybierz osobę" label="Klient" options={options} component={RegularField} setFieldValue={setFieldValue} errors={errors} /> */}
                <Field name="clientId" type="asyncSelect" setValueWithClientName initValue={this.props.location.state?.company} placeholder="Wpisz klienta" label="Klient" component={RegularField} setFieldValue={setFieldValue} errors={errors} />
                <Field name="ordering" type="text" wrapperClassName="default" placeholder="Wpisz osobę" label="Zlecający" options={options} component={RegularField} errors={errors} />
                <Field name="description" type="textarea" wrapperClassName="default" placeholder="Wpisz opis" label="Opis czynności" component={RegularField} errors={errors} />
                <Field name="time" type="number" wrapperClassName="default" placeholder="Wpisz czas" label="Czas w h" component={RegularField} errors={errors} />
                <button className="btn btn--default btn--login btn--center" type="submit">
                  {title}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  addTimeTrial: (timeTrialData) => TimeTrialActions.addTimeTrial(timeTrialData),
  editTimeTrial: (timeTrialData) => TimeTrialActions.editTimeTrial(timeTrialData),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    prevPath: ViewManagementSelectors.prevPath(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTimeTrial);
