import * as React from 'react';
import { Link, generatePath } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { history } from 'Store/Reducers/RootReducer';
import ReactTooltip from 'react-tooltip';
import { MainType } from './ContainerCustomersList';
import CustomFilters from './components/customFilters';
import Pagination from '../../Pagination';
import { ROUTES, PRIVILEGES } from 'shared/consts';
import { checkPrivilegeAccess } from 'shared/utils';

let timer;

type S = {
  filters: any;
};

type P = {
  location: any;
};

class CustomersList extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        size: JSON.parse(sessionStorage.getItem('pageSize'))?.value ?? 10,
        page: 0,
      },
    };
  }

  async componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const queryParams: any = new URLSearchParams(search);
    await this.props.actions.getClientsList({ ...this.state.filters, companyNameOrId: queryParams.get('companyNameOrId') });
    this.setState({ filters: { ...this.state.filters } });
  }

  async componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    const queryParams: any = new URLSearchParams(search);
    if (prevProps.location.search !== search) {
      await this.props.actions.getClientsList({ ...this.state.filters, companyNameOrId: queryParams.get('companyNameOrId') });
      this.setState({ filters: { ...this.state.filters } });
    }
  }

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  onFilterChange = (key, value) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [key]: value,
        },
      },
      () => {
        this.getClients();
      },
    );
  };

  getClients = () => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await this.props.actions.getClientsList(this.state.filters);
      const queryParams = new URLSearchParams(this.state.filters);
      // const queryParams = new URLSearchParams({ ...values, page: curentParams.get('page') });
      history.replace({
        search: queryParams.toString(),
      });
    }, 600);
  };

  handlePageOrSizeChange = (...args) => {
    const [page, size] = args;
    console.log(page, size);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          page,
          size,
        },
      },
      async () => await this.props.actions.getClientsList(this.state.filters),
    );
  };

  redirectToEditCustomer = (data) => history.push(ROUTES.EDIT_CUSTOMER, data);

  handleManageFavourite = async (client) => {
    const { manageFavourite, getClientsList } = this.props.actions;

    await manageFavourite(client.id, !client.favourite);
    await getClientsList(this.state.filters);
  };

  public render() {
    const { content = [], totalPages } = this.props.clientsListData ?? {};

    const isAddClientAccess = checkPrivilegeAccess(PRIVILEGES.CLIENT_ADD);
    const isEditClientAccess = checkPrivilegeAccess(PRIVILEGES.CLIENT_EDIT);
    const isDeleteClientAccess = checkPrivilegeAccess(PRIVILEGES.CLIENT_DEL);
    const isExportAccess = checkPrivilegeAccess(PRIVILEGES.EXPORT);

    return (
      <div className="customers-list">
        <div className="customers-list__header">
          <div className="customers-list__title">
            <Link to="/dashboard">
              <img src={GrayArrow} alt="" />
            </Link>
            <h2>Lista klientów</h2>
          </div>
          {content && <Pagination pages={totalPages} getFunction={this.handlePageOrSizeChange} pageNo={this.state.filters.page} />}
          {isAddClientAccess && (
            <div className="customers-list__add-button">
              <button className="btn btn--default" onClick={() => history.push('/dashboard/add-customer')}>
                <span>&#43;</span> Dodaj klienta
              </button>
            </div>
          )}
        </div>
        {isExportAccess && (
          <div className="time-trial-list__export">
            <div onClick={() => this.props.actions.getClientsXlsx(window.location.search)}>Eksportuj do pliku</div>
          </div>
        )}
        <div className="customers-list__table">
          <table className="table">
            <thead>
              <tr>
                <th className="actions">Akcje</th>
                <CustomFilters onFilterChange={this.onFilterChange} filters={this.state.filters} />
              </tr>
            </thead>
            <tbody>
              {content.map((client, idx: number) => {
                const { id, type, nip, address, postalCode, city, phone, email, letter, company, favourite } = client;
                return (
                  <>
                    <ReactTooltip place="top" effect="solid" className="tooltip" />
                    <tr className="hoverable" key={idx}>
                      <td className="actions">
                        <button className="btn-action btn-action--search" data-tip="Karta klienta" onClick={() => history.push(generatePath(ROUTES.CUSTOMER_CARD, { id }))}></button>
                        <button className="btn-action btn-action--open" data-tip="Lista spraw klienta" onClick={() => history.push(generatePath(ROUTES.CASES_LIST_CUSTOMER, { id }))}></button>
                        <button className={`btn-action btn-action--${favourite ? 'favourite-selected' : 'favourite'}`} data-tip={favourite ? 'Usuń z ulubionych' : `Dodaj do ulubionych`} onClick={() => this.handleManageFavourite(client)}></button>
                        {isEditClientAccess && <button className="btn-action btn-action--edit" data-tip="Edytuj" onClick={() => this.redirectToEditCustomer(client)}></button>}
                        {isDeleteClientAccess && <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => this.props.actions.showConfirmPopup('DeleteClient', client)}></button>}
                      </td>
                      <td> {id}</td>
                      <td className="case">{company}</td>
                      <td>{type}</td>
                      <td>{nip}</td>
                      <td>{address}</td>
                      <td>{postalCode}</td>
                      <td>{city}</td>
                      <td>{phone}</td>
                      <td>
                        <a href={`mailto:${email}`}>{email}</a>
                      </td>
                      <td>{letter}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default CustomersList;
