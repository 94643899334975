import { CASES } from '../EnumTypes/ActionEnumTypes';
import { deleteCaseStatus } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { getCaseDetails } from './';

const init = () => ({
  type: CASES.DELETE_CASE_STATUS_DESCRIPTION_INIT,
});

const success = () => ({
  type: CASES.DELETE_CASE_STATUS_DESCRIPTION_SUCCESS,
});

const failure = () => ({
  type: CASES.DELETE_CASE_STATUS_DESCRIPTION_FAILURE,
});

const deleteCaseStatusDescription = (statusId, caseId) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await deleteCaseStatus(statusId);
    dispatch(success());
    toast.success('Pomyślnie usunięto wpis do sprawy');
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(getCaseDetails(caseId));
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się usunąć wpisu do sprawy');
    dispatch(failure());
  }
};

export default deleteCaseStatusDescription;
