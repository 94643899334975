import { CASES } from '../EnumTypes/ActionEnumTypes';
// import { generatePath } from 'react-router';
// import { addCaseRemainder as addCaseRemainderConnector } from 'Connectors/CaseConnector';
import { Connector } from 'Modules/ReminderModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
// import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: CASES.REPLY_TO_REMAINDER_INIT,
});

const success = () => ({
  type: CASES.REPLY_TO_REMAINDER_SUCCESS,
});

const failure = () => ({
  type: CASES.REPLY_TO_REMAINDER_FAILURE,
});

const replyToRemainder = (replyToRemainderData, id) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    // await addCaseRemainderConnector(caseRemainderData);
    await Connector.respondReminder({ reminderResponseDto: { ...replyToRemainderData, id } });
    dispatch(success());
    toast.success('Pomyślnie dodano odpowiedź');
    dispatch(ViewManagementModule.Actions.hideLoader());
    // history.push(generatePath(ROUTES.CASE_DETAILS, { id: caseId }));
    history.goBack();
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się dodać odpowiedzi');
    dispatch(failure());
  }
};

export default replyToRemainder;
