import { CASES } from '../EnumTypes/ActionEnumTypes';
import { generatePath } from 'react-router';
import { editCase as editCaseConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
    type: CASES.EDIT_CASE_INIT,
});

const success = () => ({
    type: CASES.EDIT_CASE_SUCCESS,
});

const failure = () => ({
    type: CASES.EDIT_CASE_FAILURE,
});

const editCase = (caseData) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await editCaseConnector({ caseUpdateDto: caseData });
        dispatch(success());
        toast.success('Pomyślnie edytowano sprawę');
        dispatch(ViewManagementModule.Actions.hideLoader());
        history.push(generatePath(ROUTES.CASE_DETAILS, { id: caseData.id }));
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.error('Nie udało się edytować sprawy');
        dispatch(failure());
    }
};

export default editCase;
