import * as React from 'react';

interface SwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  className?: string;
}

const Switch = ({ checked, onChange, className = '' }: SwitchProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <label className={`switch ${className}`}>
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <span className="slider round"></span>
    </label>
  );
};

export default Switch;
