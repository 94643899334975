import * as React from 'react';
import RegularField from 'Components/Forms/Fields/RegularField';
import { Field } from 'formik';
import { CASE_TYPES } from '../consts';

type P = {
  errors: any;
  setFieldValue: any;
  caseType: any;
};

const DetailedData = ({ errors, setFieldValue, caseType }: P) => {
  switch (caseType) {
    case CASE_TYPES.VARIOUS:
      return (
        <>
          <Field name="name" type="text" wrapperClassName="default" placeholder="Wpisz nazwę" label="Nazwa sprawy" component={RegularField} errors={errors} />
          <Field name="details" type="textarea" wrapperClassName="default" placeholder="Wpisz opis" label="Szczegółowy opis sprawy" component={RegularField} errors={errors} />
          <Field name="tags" type="text" wrapperClassName="default" placeholder="Wpisz tag" label="Tagi (rozdzielone przecinkiem)" component={RegularField} errors={errors} />
        </>
      );
    case CASE_TYPES.COURT:
      return (
        <>
          <Field name="name" type="text" wrapperClassName="default" placeholder="Wpisz nazwę" label="Nazwa sprawy" component={RegularField} errors={errors} />
          <Field name="plaintiff" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Powód/Wnioskodawca/Wierzyciel" component={RegularField} errors={errors} />
          <Field name="defendant" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Pozwany/Uczestnik/Dłużnik" component={RegularField} errors={errors} />
          <Field name="wps" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="WPS" component={RegularField} errors={errors} />
          <Field name="signature" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Sygnatura" component={RegularField} errors={errors} />
          <Field name="kind" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Rodzaj" component={RegularField} errors={errors} />
          <Field name="tags" type="text" wrapperClassName="default" placeholder="Wpisz tag" label="Tagi (rozdzielone przecinkiem)" component={RegularField} errors={errors} />
        </>
      );
    case CASE_TYPES.VINDICATION:
      return (
        <>
          <Field name="name" type="text" wrapperClassName="default" placeholder="Wpisz nazwę" label="Nazwa sprawy" component={RegularField} errors={errors} />
          <Field name="plaintiff" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Powód/Wnioskodawca/Wierzyciel" component={RegularField} errors={errors} />
          <Field name="defendant" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Pozwany/Uczestnik/Dłużnik" component={RegularField} errors={errors} />
          <Field name="wps" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="WPS" component={RegularField} errors={errors} />
          <Field name="signature" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Sygnatura" component={RegularField} errors={errors} />
          <Field name="kind" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Rodzaj" component={RegularField} errors={errors} />
          <Field name="tags" type="text" wrapperClassName="default" placeholder="Wpisz tag" label="Tagi (rozdzielone przecinkiem)" component={RegularField} errors={errors} />
        </>
      );
    case CASE_TYPES.CONTRACT:
      return (
        <>
          <Field name="name" type="text" wrapperClassName="default" placeholder="Wpisz nazwę" label="Nazwa sprawy" component={RegularField} errors={errors} />
          <Field name="contractor" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Kontrahent" component={RegularField} errors={errors} />
          {/* <Field name="about" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Opis sprawy" component={RegularField} errors={errors} /> */}
          <Field name="details" type="textarea" wrapperClassName="default" placeholder="Wpisz opis" label="Szczegółowy opis sprawy" component={RegularField} errors={errors} />
          <Field name="kind" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Rodzaj" component={RegularField} errors={errors} />
          <Field name="tags" type="text" wrapperClassName="default" placeholder="Wpisz tag" label="Tagi (rozdzielone przecinkiem)" component={RegularField} errors={errors} />
        </>
      );
    case CASE_TYPES.OTHER:
      return (
        <>
          <Field name="name" type="text" wrapperClassName="default" placeholder="Wpisz nazwę" label="Nazwa sprawy" component={RegularField} errors={errors} />
          <Field name="kind" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Rodzaj" component={RegularField} errors={errors} />
          <Field name="details" type="textarea" wrapperClassName="default" placeholder="Wpisz opis" label="Szczegółowy opis sprawy" component={RegularField} errors={errors} />
          <Field name="sign1" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Oznaczenie sprawy 1" component={RegularField} errors={errors} />
          <Field name="sign2" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Oznaczenie sprawy 2" component={RegularField} errors={errors} />
          <Field name="tags" type="text" wrapperClassName="default" placeholder="Wpisz tag" label="Tagi (rozdzielone przecinkiem)" component={RegularField} errors={errors} />
        </>
      );
    default:
      return null;
  }
  // return (
  //   <>
  //     <Field name="lastName" type="text" wrapperClassName="default" placeholder="Wpisz nazwę" label="Nazwa sprawy" component={RegularField} errors={errors} />
  //     <Field name="ordering" type="textarea" wrapperClassName="default" placeholder="Wpisz opis" label="Szczegółowy opis sprawy" component={RegularField} errors={errors} />
  //     <Field name="ordering1" type="text" wrapperClassName="default" placeholder="Wpisz tag" label="Tagi (rozdzielone przecinkiem)" component={RegularField} errors={errors} />
  //   </>
  // );
};

export default DetailedData;
