import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions as GroupActions, Selectors as GroupSelectors } from 'Modules/GroupModule';
import Main from './Main';

type ConnectedP = {
  groups: Array<any>;
};

type DispatchedP = {
  actions: {
    getGroup: () => void;
    deleteGroup: (id) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getGroup: () => GroupActions.getGroup(),
      deleteGroup: (id) => GroupActions.deleteGroup(id),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  groups: GroupSelectors.getGroupData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
