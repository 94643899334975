import * as React from 'react';

const sizes = ['10', '20', '30'];

const ExternalPagination = ({ pages, page = 0, onPageChange, onSizeChange, size = sizes[0] }: any) => {
  const [currentSize, setSize] = React.useState(size);
  const [currentPage, setPage] = React.useState(page);
  const [isDropdownOpen, setDropdownBool] = React.useState(false);
  const arr = Array(pages).fill(true, 0, pages);
  return (
    <div className="externalPagination">
      <div className="externalPagination__inner">
        <span className="externalPagination__title">Na stronie</span>
        <span className="externalPagination__sizeSelect">
          <div className="externalPagination__sizeSelect--value" onClick={() => setDropdownBool(!isDropdownOpen)}>
            {currentSize}
          </div>
          {isDropdownOpen && (
            <div className="externalPagination__sizeSelect--options">
              {sizes.map((size) => (
                <div
                  key={size}
                  className="externalPagination__sizeSelect--option"
                  onClick={() => {
                    setDropdownBool(false);
                    setSize(size);
                    setPage(0);
                    onSizeChange(size);
                  }}>
                  {size}
                </div>
              ))}
            </div>
          )}
        </span>
        <span className="externalPagination__pages">
          {arr.map((_, idx) => (
            <span
              className={`externalPagination__page ${idx === currentPage ? 'externalPagination__page--active' : ''}`}
              key={idx + 1}
              onClick={() => {
                setPage(idx);
                onPageChange(idx);
              }}>
              {idx + 1}
            </span>
          ))}
        </span>
      </div>
    </div>
  );
};

export default ExternalPagination;
