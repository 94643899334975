import { Connector } from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { history } from 'Store/Reducers/RootReducer';

const activateAccount = (data) => async (dispatch) => {
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await Connector.activateAccount(data);
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.success('Pomyślnie aktywowano konto');
        history.push('/login')
        return true;
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.error('Wystąpił błąd podczas aktywacji konta');
        return false
    }
};

export default activateAccount;
