import EditInternalUser from './EditInternalUser';
import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import * as UserModule from 'Modules/UserModule';
import { Actions as GroupActions, Selectors as GroupSelectors } from 'Modules/GroupModule';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';

type ConnectedP = {
  prevPath: string;
  match?: any;
  specificInternalUser: UserModule.Types.InternalUserDto;
  group: Array<any>;
};
type DispatchedP = {
  addInternalUser: (data: any) => void;
  getSpecificInternalUser: (id: number) => void;
  editSpecificInternalUser: (data: any) => void;
  getGroup: () => void;
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps: DispatchedP = {
  addInternalUser: (data: any) => UserModule.Actions.addInternalUser(data),
  getSpecificInternalUser: (id: number) => UserModule.Actions.getSpecificInternalUser(id, 'INTERNAL'),
  editSpecificInternalUser: (data: any) => UserModule.Actions.editSpecificInternalUser(data),
  getGroup: () => GroupActions.getGroup(),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    prevPath: ViewManagementSelectors.prevPath(state),
    specificInternalUser: UserModule.Selectors.specificInternalUser(state),
    group: GroupSelectors.getGroupData(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInternalUser);
