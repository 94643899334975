import * as React from 'react';
import { CASE_STATUS, ROUTES } from 'shared/consts';
import { generatePath } from 'react-router';
import { history } from 'Store/Reducers/RootReducer';

const CASES_TYPE_LABELS = {
  other: 'inne',
  contract: 'umowy',
  vindication: 'windykacja',
  court: 'sądowe',
  various: 'różne',
};

const Summary = ({ caseDetails: { companyId, caseNumber, company, signature, status, caseStatus, ordering, caseType = '', createdAt, executive1FullName, executive2FullName, name, details, tags, finishedAt, plaintiff, defendant, wps, kind, contractor, sign1, sign2 }, changeCaseStatus, external }: any) => (
  <div className="summary">
    {/* plaintiff: Yup.string().required('Pole wymagane'), defendant: Yup.string().required('Pole wymagane'), wps: Yup.string().required('Pole wymagane'), signature: Yup.string().required('Pole wymagane'), kind: Yup.string().required('Pole wymagane'), */}
    <SummaryBlock clientId={companyId} title={'Nr sprawy'} value={caseNumber} />
    <SummaryBlock clientId={companyId} title={'Klient'} value={company} />
    <SummaryBlock clientId={companyId} title={'Zlecający'} value={ordering} />
    <SummaryBlock clientId={companyId} title={'Typ sprawy'} value={CASES_TYPE_LABELS[caseType.toLowerCase()]} />
    <SummaryBlock clientId={companyId} title={'Data przyjęcia'} value={createdAt} />
    {!external && (
      <>
        <SummaryBlock clientId={companyId} title={'Wykonawca'} value={executive1FullName} />
        <SummaryBlock clientId={companyId} title={'Podwykonawca'} value={executive2FullName} />
      </>
    )}
    <SummaryBlock clientId={companyId} title={'Nazwa sprawy'} value={name} />
    {details && <SummaryBlock clientId={companyId} title={'Szczegółowy opis sprawy'} value={details} />}
    {tags && <SummaryBlock clientId={companyId} title={'Tagi'} value={tags} />}
    {plaintiff && <SummaryBlock clientId={companyId} title={'Powód/Wnioskodawca/Wierzyciel'} value={plaintiff} />}
    {defendant && <SummaryBlock clientId={companyId} title={'Pozwany/Uczestnik/Dłużnik'} value={defendant} />}
    {wps && <SummaryBlock clientId={companyId} title={'WPS'} value={wps} />}
    {kind && <SummaryBlock clientId={companyId} title={'Rodzaj'} value={kind} />}
    {contractor && <SummaryBlock clientId={companyId} title={'Kontrahent'} value={contractor} />}
    {sign1 && <SummaryBlock clientId={companyId} title={'Oznaczenie sprawy 1'} value={sign1} />}
    {sign2 && <SummaryBlock clientId={companyId} title={'Oznaczenie sprawy 2'} value={sign2} />}
    {signature && <SummaryBlock clientId={companyId} title={'Sygnatura'} value={signature} />}
    <SummaryBlock
      clientId={companyId} title={'Status sprawy'}
      value={
        <span className="case-status">
          {status}
          {!external && (
            <span className="closeCase" onClick={changeCaseStatus}>
              {caseStatus === CASE_STATUS.IN_PROGRESS ? 'Zakończ' : 'Wznów'}
            </span>
          )}
        </span>
      }
    />
    <SummaryBlock clientId={companyId} title={'Data zakończenia'} value={finishedAt} />
  </div>
);

const SummaryBlock = ({ title, value, clientId }: SummaryBlockP) => (
  <div className={`summaryBlock ${title === 'Klient' ? 'summaryBlock--pointer summaryBlock--hyperlink' : ''}`} onClick={()=> {title === 'Klient' && history.push(generatePath(ROUTES.CUSTOMER_CARD, {id: clientId} ))}}>
    <div className="summaryBlock__title">
      <p>{title}</p>
    </div>
    <div className="summaryBlock__value">{value}</div>
  </div>
);

type SummaryBlockP = { title: string; value: any; clientId: any | JSX.Element };

export default Summary;
