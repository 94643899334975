import { CASES } from '../EnumTypes/ActionEnumTypes';
import { generatePath } from 'react-router';
import { updateCaseStatus } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: CASES.EDIT_CASE_STATUS_DESCRIPTION_INIT,
});

const success = () => ({
  type: CASES.EDIT_CASE_STATUS_DESCRIPTION_SUCCESS,
});

const failure = () => ({
  type: CASES.EDIT_CASE_STATUS_DESCRIPTION_FAILURE,
});

const editCaseStatusDescription =
  ({ body, shared, id }, caseId) =>
  async (dispatch) => {
    dispatch(init());
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      await updateCaseStatus({ body, shared, id });
      dispatch(success());
      toast.success('Pomyślnie edytowano wpis do sprawy');
      dispatch(ViewManagementModule.Actions.hideLoader());
      history.push(generatePath(ROUTES.CASE_DETAILS, { id: caseId }));
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.error('Nie udało się edytować wpisu do sprawy');
      dispatch(failure());
    }
  };

export default editCaseStatusDescription;
