import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getUserStore = (state: RootState) => state.userStore;
const internalUsersList = createSelector(getUserStore, (state) => state.internalUsersList);
const externalUsersList = createSelector(getUserStore, (state) => state.externalUsersList);
const specificInternalUser = createSelector(getUserStore, (state) => state.specificInternalUser);
const getSpecificExternalUser = createSelector(getUserStore, (state) => state.specificExternalUser);

const getInternalUsersLogs = createSelector(getUserStore, ({ internalUsersLogs: { data = {} } }) => data);
const getExternalUsersLogs = createSelector(getUserStore, ({ externalUsersLogs: { data = {} } }) => data);
const getInternalUserOptions = createSelector(getUserStore, ({ internalUserOptions: { data = {} } }) => data);
const getAllUsersStats = createSelector(getUserStore, ({ usersStats: { data = [] } }) => data);
const getSidebarCounts = createSelector(getUserStore, ({ sidebarCounts }) => sidebarCounts);

export { internalUsersList, specificInternalUser, externalUsersList, getInternalUsersLogs, getExternalUsersLogs, getSpecificExternalUser, getInternalUserOptions, getAllUsersStats, getSidebarCounts };
