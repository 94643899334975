import { Limits } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = any;

export const initialState: State = {
  clientsReachingLimits: undefined,
  clientsReachingLimitsCount: undefined,
};

export function limitsReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case Limits.GET_CLIENTS_REACHING_LIMITS_INIT: {
      return {
        ...state,
        clientsReachingLimits: undefined,
      };
    }
    case Limits.GET_CLIENTS_REACHING_LIMITS_SUCCESS: {
      return {
        ...state,
        clientsReachingLimits: action.payload.clientsReachingLimits,
      };
    }
    case Limits.GET_CLIENTS_REACHING_LIMITS_COUNT_INIT: {
      return {
        ...state,
        clientsReachingLimitsCount: undefined,
      };
    }
    case Limits.GET_CLIENTS_REACHING_LIMITS_COUNT_SUCCESS: {
      return {
        ...state,
        clientsReachingLimitsCount: action.payload.clientsReachingLimitsCount,
      };
    }
    default: {
      return state;
    }
  }
}
