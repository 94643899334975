import * as React from 'react';

const Button = ({ onClick, className = '', label }: P) => (
  <button className={`btn btn--main btn--default ${className}`} onClick={onClick}>
    {label}
  </button>
);

type P = {
  onClick?: () => void;
  className?: string;
  label: string;
};

export default Button;
