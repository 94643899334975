import { Client } from '../EnumTypes/ActionEnumTypes';
import { addClient as addClientConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';
import { generatePath } from 'react-router';
import  getSidebarCounts  from 'Store/Actions/user/getSidebarCounts';

const init = () => ({
  type: Client.ADD_CLIENT_INIT,
});

const success = () => ({
  type: Client.ADD_CLIENT_SUCCESS,
});

const failure = () => ({
  type: Client.ADD_CLIENT_FAILURE,
});

const addClient = (clientData) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const response = await addClientConnector(clientData);
    dispatch(success());
    toast.success('Pomyślnie dodano klienta');
    await dispatch(getSidebarCounts());
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(generatePath(ROUTES.CUSTOMER_CARD, { id: response.headers.location }));
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default addClient;
