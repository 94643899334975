import { Auth } from './EnumTypes/ActionEnumTypes';
import * as AuthActionModel from './Models/AuthActionModel';
import * as AuthModule from '../../Modules/AuthModule';
import { history } from '../Reducers/RootReducer';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
// import { store } from '../Store';
// import API from './../../Connectors/config';

function loginSuccess(authData: AuthModule.Types.LogInResponse): AuthActionModel.LoginSuccess {
  return {
    type: Auth.LOG_IN_SUCCESS,
    payload: {
      authData,
    },
  };
}
function loginFailure(): AuthActionModel.LoginFailure {
  return {
    type: Auth.LOG_IN_FAILURE,
  };
}

function getAuthorizedUserSuccess(authorizedUserData: AuthModule.Types.AuthorizedUserData): AuthActionModel.GetAuthorizedUser {
  return {
    type: Auth.GET_AUTHORIZED_USER,
    payload: {
      authorizedUserData,
    },
  };
}

function getAuthorizedUser() {
  return async (dispatch) => {
    try {
      const response = await AuthModule.Connector.getAuthorizedUser();
      dispatch(getAuthorizedUserSuccess(response.data));
    } catch (err) {
      console.log(err);
    }
  };
}

function login(email: string, password: string) {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      const response = await AuthModule.Connector.login(email, password);
      dispatch(loginSuccess(response.data));
      history.push('/dashboard');
      dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      dispatch(loginFailure());
      toast.error('Niepoprawne dane logowania');
    }
  };
}

function logoutSuccess(): AuthActionModel.LogoutSuccess {
  return {
    type: Auth.LOG_OUT_SUCCESS,
  };
}
function logoutFailure(): AuthActionModel.LogoutFailure {
  return {
    type: Auth.LOG_OUT_FAILURE,
  };
}

function logout() {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      await AuthModule.Connector.logout();
      dispatch(logoutSuccess());
      dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
      dispatch(logoutFailure());
      dispatch(ViewManagementModule.Actions.hideLoader());
    }
  };
}

// function setRefreshToken() {
//   return async (dispatch) => {
//     try {
//       // dispatch(ViewManagementModule.Actions.showLoader());
//       const response = await AuthModule.Connector.setRefreshToken();
//       dispatch(setRefreshTokenSuccess(response.data));
//       // dispatch(ViewManagementModule.Actions.hideLoader());
//     } catch (err) {
//       dispatch(logoutSuccess());
//     }
//   };
// }

function setRefreshToken(authData: AuthModule.Types.LogInResponse): AuthActionModel.SetRefreshToken {
  return {
    type: Auth.SET_REFRESH_TOKEN,
    payload: {
      authData,
    },
  };
}
export { login, logout, setRefreshToken, getAuthorizedUser, loginSuccess, logoutSuccess };
