import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';

type P = {
  onSubmit: (values: any) => void;
  isEditMode: boolean;
  initialValues?: any;
};
const AddCaseStatusForm = ({ onSubmit, isEditMode, initialValues = { body: '', shared: false } }: P) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        body: Yup.string().required('Pole wymagane'),
      })}
      onSubmit={onSubmit}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="add-customer__form">
          <Field name="body" type="textarea" wrapperClassName="default" label="Wpis" component={RegularField} errors={errors} />
          <Field name="shared" type="checkbox" wrapperClassName="default" label="Udostępnij klientowi" component={RegularField} errors={errors} />
          <button className="btn btn--default btn--login btn--center" type="submit">
            {isEditMode ? 'Edytuj' : 'Dodaj'} wpis
          </button>
        </form>
      )}
    </Formik>
  );
};

export default AddCaseStatusForm;
