import * as React from 'react';
import { MainType } from './Container';
import LimitsListTableHeader from './components/LimitsListTableHeader';
import { history } from 'Store/Reducers/RootReducer';
import { generatePath } from 'react-router-dom';
import { ROUTES } from 'shared/consts'


type S = {
  params: any;
  isCasesListRolledUp: boolean;
  filters: any;
};

type P = {
  location: any;
  history: any;
  match: any;
  authorizedUser: any;
};
export default class LimitsList extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      params: this.getParamsOnInit(),
      isCasesListRolledUp: false,
      filters: {
        size: JSON.parse(sessionStorage.getItem('pageSize'))?.value ?? 10,
        page: 0,
      },
    };
  }

  triggerExpandCasesList = () => {
    this.setState({ isCasesListRolledUp: !this.state.isCasesListRolledUp });
  };

  getParamsOnInit = () => {
    if (this.props.location.search) {
      const urlSearchParams = new URLSearchParams(this.props.location.search);
      const params = {};
      urlSearchParams.forEach((value, key) => (params[key] = value));
      return {
        ...params,
        caseStatusType: undefined,
        caseType: undefined,
      };
    } else {
      return {
        caseStatusType: undefined,
        caseType: undefined,
        pageNo: 0,
        pageSize: 25,
      };
    }
  };

  async componentDidMount() {
    await this.props.getClientsReachingLimits();
  }

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  
  public render() {
    const {clientsReachingLimits} = this.props;
    console.log(clientsReachingLimits);
    
    return (
      <div className="cases-list">
        <div className='cases-list__container cases-list__container--limits'>
        <div className="cases-list__header">
          <div className="cases-list__title">
            <h2>Limity</h2>
          </div>
        </div>
          <div className="cases-list__table">
            <table className="table table--limits">
              <LimitsListTableHeader/>
              <tbody>
                {clientsReachingLimits &&
                  clientsReachingLimits.map(({company, currentMonth, id, limitReached, maximumLimit, previusMonth, warningLimit}) => (
                    <tr className={`limits limits--${limitReached}`} key={company}>
                      <td>{id}</td>
                      <td className='limits--pointer' onClick={() => history.push(generatePath(ROUTES.CUSTOMER_CARD, { id }))}>{company}</td>
                      <td>{previusMonth}</td>
                      <td>{currentMonth}</td>
                      <td>{warningLimit}</td>
                      <td>{maximumLimit}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
