import { CASES } from '../EnumTypes/ActionEnumTypes';
import { generatePath } from 'react-router';
import { addCase as addCaseConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';
import  getSidebarCounts  from 'Store/Actions/user/getSidebarCounts';

const init = () => ({
  type: CASES.ADD_CASE_INIT,
});

const success = () => ({
  type: CASES.ADD_CASE_SUCCESS,
});

const failure = () => ({
  type: CASES.ADD_CASE_FAILURE,
});

const addCase = (caseData) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    console.log(caseData);
    const response = await addCaseConnector({ caseCreationDto: { ...caseData, clientId: caseData.clientId.id } });
    dispatch(success());
    toast.success('Pomyślnie dodano sprawę');
    await dispatch(getSidebarCounts());
    history.push(generatePath(ROUTES.CASE_DETAILS, { id: response.headers.location }))
    dispatch(ViewManagementModule.Actions.hideLoader());
    // history.push(generatePath(ROUTES.CASE_DETAILS, { id: caseId }));
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się dodać sprawy');
    dispatch(failure());
  }
};

export default addCase;
