import * as React from 'react';
import { FieldProps } from 'formik';

type P = {
  label?: string;
  errors: any;
  id?: any;
  disabled?: boolean;
  privileges: any;
};

class Privileges extends React.PureComponent<P & FieldProps, any> {
  handleChange = (id) => {
    const {
      form: { setFieldValue },
      field: { name, value },
    } = this.props;

    setFieldValue(name, { ...value, [id]: { ...value[id], active: !value[id].active } });
  };

  render() {
    const {
      label,
      field: { value, name },
      form: { touched },
      errors = {},
      disabled,
    } = this.props;

    let formClasses: string = 'form__field form__field--checkbox';
    formClasses += disabled ? ' form__field--disabled' : '';
    formClasses += touched[name] && errors[name] ? ' form__field--error' : '';

    const keysMap = Object.keys(value);
    return (
      <div className={`privileges ${formClasses}`}>
        <label className="field-label">{label}</label>
        {keysMap.map((privilege, idx) => (
          <div key={privilege} className="checkbox__details">
            <input id={privilege} name={privilege} disabled={disabled || false} onChange={() => this.handleChange(idx)} checked={value[idx].active} value={value[idx].active} type={'checkbox'} />
            <label className="custom-box" htmlFor={privilege} />
            <label htmlFor={privilege}>{value[privilege].description}</label>
          </div>
        ))}
      </div>
    );
  }
}

export default Privileges;
