import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: User.GET_INTERNAL_USER_OPTIONS_INIT,
});

const success = (data) => ({
    type: User.GET_INTERNAL_USER_OPTIONS_SUCCESS,
    payload: {
        data,
    },
});

const failure = () => ({
    type: User.GET_INTERNAL_USER_OPTIONS_FAILURE,
});

const getInternalUserOptions = () => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        const { data } = await Connector.getInternalUserOptions();
        dispatch(success(data));
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getInternalUserOptions;
