import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';

type P = {
  onSubmit: (values: any) => void;
  // caseNo: string;
  // internalUserOptions: any;
};

const AddCaseRemainderForm = ({ onSubmit }: P) => {
  return (
    <Formik
      initialValues={{ surname: '', name: '', email: '', phone: '', status: '' }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Pole wymagane'),
        // email: Yup.string().required('Pole wymagane'),
        surname: Yup.string().required('Pole wymagane'),
        // phone: Yup.string().required('Pole wymagane'),
        // status: Yup.string().required('Pole wymagane'),
      })}
      onSubmit={onSubmit}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="add-customer__form">
          <Field name="surname" type="text" wrapperClassName="default" placeholder="Wpisz nazwisko" label="Nazwisko" component={RegularField} errors={errors} />
          <Field name="name" type="text" wrapperClassName="default" label="Imię" placeholder="Wpisz imię" component={RegularField} errors={errors} />
          <Field name="email" type="text" wrapperClassName="default" label="Email" placeholder="Wpisz adres email" component={RegularField} errors={errors} />
          <Field name="phone" type="text" wrapperClassName="default" label="Telefon" placeholder="Wpisz numer telefonu" component={RegularField} errors={errors} />
          <Field name="status" type="text" wrapperClassName="default" label="Status" placeholder="Wpisz status" component={RegularField} errors={errors} />
          <button className="btn btn--default btn--login btn--center" type="submit">
            Dodaj
          </button>
        </form>
      )}
    </Formik>
  );
};

export default AddCaseRemainderForm;
