import { Reminder } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/ReminderModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: Reminder.ADD_REMINDER_INIT,
});

const success = () => ({
  type: Reminder.ADD_REMINDER_SUCCESS,
});

const failure = () => ({
  type: Reminder.ADD_REMINDER_FAILURE,
});

const addReminder = (reminderData) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await Connector.addReminder(reminderData);
    dispatch(success());
    toast.success('Pomyślnie dodano przypomnienie');
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(ROUTES.REMAINDER_LIST);
  } catch (err) {
    console.log(err, 'error')
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się dodać przypomnienia');
    dispatch(failure());
  }
};

export default addReminder;
