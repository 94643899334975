import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions, Selectors } from 'Modules/UserModule';
import Main from './Main';

type ConnectedP = {
  internalUsersLogData: any;
};

type DispatchedP = {
  actions: {
    getInternalUsersLogs: (page: any, string: any) => void;
    getInternalUserLogsXlsx: (params: any) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getInternalUsersLogs: (page, size) => Actions.getInternalUsersLogs(page, size),
      getInternalUserLogsXlsx: (params: any) => Actions.getInternalUserLogsXlsx(params)
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  internalUsersLogData: Selectors.getInternalUsersLogs(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
