import * as Types from '../../Models/UserModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { UserAction } from '../Actions/Models/UserActionModel';

export type State = Types.UserStore;

export const initialState: State = {
  internalUsersList: null,
  externalUsersList: null,
  specificInternalUser: null,
  specificExternalUser: null,
  sidebarCounts: null,
  internalUsersLogs: {
    data: [],
  },
  externalUsersLogs: {
    data: [],
  },
  internalUserOptions: {
    data: [],
  },
  usersStats: {
    data: [],
  },
};

export function userReducer(state: any = initialState, action: UserAction | any) {
  switch (action.type) {
    case ActionTypes.User.GET_INTERNAL_USERS: {
      return {
        ...state,
        internalUsersList: action.payload.internalUsersList,
      };
    }
    case ActionTypes.User.GET_EXTERNAL_USERS: {
      return {
        ...state,
        externalUsersList: action.payload.externalUsersList,
      };
    }
    case ActionTypes.User.GET_SPECIFIC_INTERNAL_USER: {
      return {
        ...state,
        specificInternalUser: action.payload.internalUser,
      };
    }
    case ActionTypes.User.GET_SPECIFIC_EXTERNAL_USER: {
      return {
        ...state,
        specificExternalUser: action.payload.externalUser,
      };
    }
    case ActionTypes.User.GET_INTERNAL_USERS_LOGS_SUCCESS: {
      return {
        ...state,
        internalUsersLogs: {
          data: action.payload.logs,
        },
      };
    }
    case ActionTypes.User.GET_EXTERNAL_USERS_LOGS_SUCCESS: {
      return {
        ...state,
        externalUsersLogs: {
          data: action.payload.logs,
        },
      };
    }
    case ActionTypes.User.GET_INTERNAL_USER_OPTIONS_SUCCESS: {
      return {
        ...state,
        internalUserOptions: {
          data: action.payload.data,
        },
      };
    }
    case ActionTypes.User.GET_ALL_USERS_STATS_SUCCESS: {
      return {
        ...state,
        usersStats: {
          data: action.payload.stats,
        },
      };
    }
    case ActionTypes.User.GET_SIDEBAR_COUNTS_SUCCESS: {
      return {
        ...state,
        sidebarCounts: action.payload.sidebarCounts,
      };
    }
    default: {
      return state;
    }
  }
}
