import { Client } from '../EnumTypes/ActionEnumTypes';
import { addContactToClient as addContactToClientConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import getClientContacts from './getClientContacts';

const init = () => ({
    type: Client.ADD_CONTACT_TO_CLIENT_INIT,
});

const success = () => ({
    type: Client.ADD_CONTACT_TO_CLIENT_SUCCESS,
});

const failure = () => ({
    type: Client.ADD_CONTACT_TO_CLIENT_FAILURE,
});

const addContactToClient = (contactData) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await addContactToClientConnector(contactData);
        dispatch(success());
        await dispatch(getClientContacts(contactData.id));
        toast.success('Pomyślnie dodano osobę kontaktową');
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default addContactToClient;
