import * as React from 'react';
import { generatePath } from 'react-router';
import { Breadcrumbs, Button, Table } from 'shared/components';
import Summary from './components/Summary';
import { getColumnsForCaseStatus, getColumnsForRemainders } from './columns';
import { MainType } from './Container';
import { ROUTES, CASE_STATUS } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';
import ReactToPrint from 'react-to-print';

type P = {
  match: any;
  history: any;
};

class Main extends React.Component<MainType & P, any> {
  componentRef = null;
  async componentDidMount() {
    await this.props.actions.getExternalCaseDetails(this.props.match.params.id);
  }
  redirectToRemaiderReply = (id) => this.props.history.push(generatePath(ROUTES.CASE_DETAILS_REPLY_TO_REMAINDER, { id }));

  redirectToEditCaseStatus = (statusData) => this.props.history.push(generatePath(ROUTES.CASE_DETAILS_EDIT_STATUS_DESCRIPTION, { id: this.props.match.params.id }), statusData);

  deleteCaseStatus = (id) => this.props.actions.deleteCaseStatusDescription(id, this.props.match.params.id);

  changeCaseStatus = async () => {
    if (this.props.caseData?.caseDetails?.caseStatus === CASE_STATUS.IN_PROGRESS) {
      return this.props.actions.finishCase(this.props.match.params.id);
    }
    return this.props.actions.resumeCase(this.props.match.params.id);
  };

  postponeReminder = (id) => {
    history.push(generatePath(ROUTES.POSTPONE_REMINDER, { id }));
  };

  handleManageFavourite = async (id, favourite) => {
    await this.props.actions.manageFavourite(id, !favourite);
    await this.props.actions.getExternalCaseDetails(this.props.match.params.id);
  };

  render() {
    const {
      caseData: { caseDetails = {}, statusDescriptions = [], reminders = [] } = {},
      history,
      authorizedUser: { external },
    } = this.props;
    const { path, params } = this.props.match;
    const caseDescriptionColumns = getColumnsForCaseStatus(this.redirectToEditCaseStatus, this.deleteCaseStatus, external);
    const columnRemainders = getColumnsForRemainders(this.redirectToRemaiderReply, this.postponeReminder, this.props.actions.showConfirmPopup);
    const { id } = caseDetails;
    return (
      <>
        <Button label={'Dokumenty sprawy'} className="caseDetails__files" onClick={() => history.push(generatePath(ROUTES.FILES, { id }))} />
        <ReactToPrint documentTitle="Karta sprawy" trigger={() => <button className="btn btn--main btn--default caseDetails__print">Drukuj</button>} content={() => this.componentRef} />
        <div className="caseDetails" ref={(el) => (this.componentRef = el)}>
          <Breadcrumbs pathname={path} />
          <div className="caseDetails__narrowSection">
            <div className="caseDetails__summary">
              <h2 className="main-subtitle">Dane podstawowe</h2>
              <div className="caseDetails__summary--actions">
                <button className={`btn-action btn-action--${caseDetails.favourite ? 'favourite-selected' : 'favourite'}`} data-tip={caseDetails.favourite ? 'Usuń z ulubionych' : `Dodaj do ulubionych`} onClick={() => this.handleManageFavourite(caseDetails.id, caseDetails.favourite)}></button>
                <button className="btn-action btn-action--edit" data-tip="Edytuj" onClick={() => this.props.history.push(generatePath(ROUTES.EDIT_CASE, { id }))}></button>
                <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => this.props.actions.showConfirmPopup('DeleteCase', { id })}></button>
              </div>
              <Summary caseDetails={caseDetails} changeCaseStatus={this.changeCaseStatus} external={external} />
            </div>
            <div className="caseDetails__status">
              <h2 className="main-subtitle flex-subtitle">
                Opis stanu sprawy
                {!external && <Button label={'+ Dodaj wpis'} onClick={() => history.push(generatePath(ROUTES.CASE_DETAILS_ADD_STATUS_DESCRIPTION, { id: params.id }))} />}
              </h2>
              <Table className="regular-table regular-table__case-descriptions" data={statusDescriptions} columns={caseDescriptionColumns} />
            </div>
          </div>
          {!external && (
            <div className="caseDetails__remainders">
              <h2 className="main-subtitle flex-subtitle">
                Lista przypomnień <Button label={'+ Dodaj przypomnienie'} onClick={() => history.push(generatePath(ROUTES.CASE_DETAILS_ADD_REMAINDER, { id: params.id }), { caseNo: caseDetails.caseNumber })} />
              </h2>
              <Table className="regular-table" data={reminders ?? []} columns={columnRemainders} />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Main;
