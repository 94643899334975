import { CASES } from '../EnumTypes/ActionEnumTypes';
import { manageFavourite as manageFavouriteConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import getFavouriteCases from './getFavouriteCases';
import  getSidebarCounts  from 'Store/Actions/user/getSidebarCounts';

const init = () => ({
    type: CASES.MANAGE_FAVOURITE_INIT,
});

const success = () => ({
    type: CASES.MANAGE_FAVOURITE_SUCCESS,
});

const failure = () => ({
    type: CASES.MANAGE_FAVOURITE_FAILURE,
});

const manageFavourite = (caseId, isFavourite) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await manageFavouriteConnector(caseId, isFavourite);
        dispatch(success());
        await dispatch(getFavouriteCases())
        await dispatch(getSidebarCounts());
        console.log('uruchom')
        toast.success(isFavourite ? 'Pomyślnie dodano do ulubionych' : 'Pomyślnie usunięto z ulubionych');
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.error('Wystąpił błą∂');
        dispatch(failure());
    }
};

export default manageFavourite;
