import API from './Config';
import { AxiosPromise } from 'axios';

export function getClientsSelectList(): AxiosPromise<any> {
  return API.get(`client`);
}

export function getClientsList(params): AxiosPromise<any> {
  return API.get(`client/table`, { params: {...params, size: 10, page: 0 } });
}

export function getClientDetails(id): AxiosPromise<any> {
  return API.get(`client/details/${id}`);
}

export function getClientNoteById(id): AxiosPromise<any> {
  return API.get(`client/note/${id}`);
}

export function getClientNotes(id): AxiosPromise<any> {
  return API.get(`client/${id}/notes`);
}

export function getClientContacts(id): AxiosPromise<any> {
  return API.get(`client/${id}/contacts`);
}

export function getClientSingleContact(id): AxiosPromise<any> {
  return API.get(`client/contact/${id}`);
}

export function addClient(data): AxiosPromise<any> {
  return API.post(`client/details`, { clientData: data });
}

export function editClient(data): AxiosPromise<any> {
  return API.put(`client/details`, { clientData: data });
}

export function editClientSingleContact(contact): AxiosPromise<any> {
  return API.put(`client/contact/${contact.id}`, { contact });
}

export function updateClientNote(data): AxiosPromise<any> {
  return API.put(`client/note/${data.id}`, { note: data.note });
}

export function deleteClient(id): AxiosPromise<any> {
  return API.delete(`client/details/${id}`);
}

export function deleteClientNote(id): AxiosPromise<any> {
  return API.delete(`client/note/${id}`);
}

export function deleteClientContact(id): AxiosPromise<any> {
  return API.delete(`client/contact/${id}`);
}

export function addContactToClient(data): AxiosPromise<any> {
  return API.post(`client/${data.id}/contact`, { contact: data });
}

export function addNoteToClient(data): AxiosPromise<any> {
  return API.post(`client/${data.id}/note`, { note: data.note });
}

export function manageFavourite(clientId, isFavourite): AxiosPromise<any> {
  return API.put(`client/${clientId}/favourite/${isFavourite}`);
}

export function getClientStats(params): AxiosPromise<any> {
  return API.get(`stats/clients`, { params: { size: 20, ...params } });
}

export function getClientStatsForUser(params): AxiosPromise<any> {
  console.log(params, 'params')
  return API.get(`stats/client/${params.clientId}/user/${params.userId || 'all'}`);
}

export function getClientsXlsx(params): AxiosPromise<any> {
  return API.get(`client/xlsx?${params}`, { responseType: 'blob' });
}