import { Client } from '../EnumTypes/ActionEnumTypes';
import { manageFavourite as manageFavouriteConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import  getSidebarCounts  from 'Store/Actions/user/getSidebarCounts';

const init = () => ({
    type: Client.MANAGE_FAVOURITE_INIT,
});

const success = () => ({
    type: Client.MANAGE_FAVOURITE_SUCCESS,
});

const failure = () => ({
    type: Client.MANAGE_FAVOURITE_FAILURE,
});

const manageFavourite = (clientId, isFavourite) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await manageFavouriteConnector(clientId, isFavourite);
        await dispatch(getSidebarCounts());
        dispatch(success());
        toast.success(isFavourite ? 'Pomyślnie dodano do ulubionych' : 'Pomyślnie usunięto z ulubionych');
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.error('Wystąpił błą∂');
        dispatch(failure());
    }
};

export default manageFavourite;
