import { CASES } from '../EnumTypes/ActionEnumTypes';
import { getExternalCaseDetails as getExternalCaseDetailsConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: CASES.GET_EXTERNAL_CASE_DETAILS_INIT,
});

const success = (caseDetails) => ({
  type: CASES.GET_EXTERNAL_CASE_DETAILS_SUCCESS,
  payload: {
    caseDetails,
  },
});

const failure = () => ({
  type: CASES.GET_EXTERNAL_CASE_DETAILS_FAILURE,
});

const getExternalCaseDetails = (id) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await getExternalCaseDetailsConnector(id);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getExternalCaseDetails;
