import { Limits } from '../EnumTypes/ActionEnumTypes';
import { getClientsReachingLimitsCount as getClientsReachingLimitsCountConnector } from 'Connectors/LimitsConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Limits.GET_CLIENTS_REACHING_LIMITS_COUNT_INIT,
});

const success = (clientsReachingLimitsCount) => ({
  type: Limits.GET_CLIENTS_REACHING_LIMITS_COUNT_SUCCESS,
  payload: {
    clientsReachingLimitsCount,
  },
});

const failure = () => ({
  type: Limits.GET_CLIENTS_REACHING_LIMITS_COUNT_FAILURE,
});

const getClientsReachingLimitsCount = () => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await getClientsReachingLimitsCountConnector();
    dispatch(success(data.clientLimitsCount));
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getClientsReachingLimitsCount;
