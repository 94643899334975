import * as React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { history } from 'Store/Reducers/RootReducer';
import RegularField from 'Components/Forms/Fields/RegularField';
import mapGroupToOptions from 'Helpers/mapGroupToOptions';
import { MainType } from './index';

type S = {};

const EditUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Pole wymagane'),
  lastName: Yup.string().required('Pole wymagane'),
  groupId: Yup.string().required('Pole wymagane'),
  userName: Yup.string().required('Pole wymagane'),
  email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
});

export default class EditInternalUser extends React.Component<MainType, S> {
  async componentDidMount() {
    const { id } = this.props.match.params;
    await this.props.getSpecificInternalUser(id);
    await this.props.getGroup();
  }

  onSubmit = async (values) => {
    const data = {
      userInternalUpdateDto: { ...values },
    };
    await this.props.editSpecificInternalUser(data);
    history.push('/dashboard/settings/internal-users');
  };
  public render() {
    const { prevPath, specificInternalUser, group } = this.props;
    return (
      <div className="add-customer">
        <div className="add-customer__title">
          <Link to={prevPath ? prevPath : '/dashboard'}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Edytuj Użytkownika</h2>
        </div>
        <div className="add-customer__form-container">
          <Formik initialValues={specificInternalUser ? { ...specificInternalUser.userInternalDto } : {}} enableReinitialize={true} validationSchema={EditUserSchema} onSubmit={this.onSubmit}>
            {({ handleSubmit, errors, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="add-customer__form">
                <Field name="firstName" type="text" wrapperClassName="default" placeholder="Wpisz imię" label="Imię" component={RegularField} errors={errors} />
                <Field name="lastName" type="text" wrapperClassName="default" placeholder="Wpisz nazwisko" label="Nazwisko" component={RegularField} errors={errors} />
                <Field name="userName" type="text" wrapperClassName="default" placeholder="Wpisz nazwę użytkownika" label="Nazwa użytkownika" component={RegularField} errors={errors} />
                <Field name="groupId" type="select" placeholder="Wybierz grupę" label="Grupa" options={mapGroupToOptions(group)} component={RegularField} setFieldValue={setFieldValue} errors={errors} />
                <Field name="email" type="text" wrapperClassName="default" placeholder="Wpisz adres email" label="Email" component={RegularField} errors={errors} isAutoComplete={false} />
                <button className="btn btn--default btn--login btn--center" type="submit">
                  Edytuj użytkownika
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
