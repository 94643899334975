import API from 'Connectors/Config';
import { AxiosPromise } from 'axios';
import * as AuthModule from '../Modules/AuthModule';

export function login(email: string, password: string): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.post('user/login', { email, password });
}
export function logout(): AxiosPromise<any> {
  return API.post('user/logout');
  // config(null, { Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` })
}
// export function setRefreshToken(): AxiosPromise<any> {
//   return API.post('user/refresh');
// }
export function getAuthorizedUser(): AxiosPromise<AuthModule.Types.AuthorizedUserData> {
  return API.get('user/me');
}
