import * as React from 'react';
import Logo from 'Assets/Img/logo.jpeg';

const Preloader = () => (
  <div className="preloader">
    <div className="preloader__logo">
      <img src={Logo} alt="" />
    </div>
    <div className="preloader__container">
      <div className="preloader__container-inner" />
      <div className="preloader__container-inner--lg" />
      <div className="preloader__container-inner--md" />
      <div className="preloader__container-inner--sm" />
    </div>
  </div>
);

export default Preloader;
