import * as React from 'react';

const CustomerDetails = ({
  customerDetails: {
    clientData: { id, company, type, nip, address, postalCode, status, city, phone, email,letter },
  },
}: any) => (
  <div className="summary">
    <CustomerDetailsBlock title={'Nr klienta'} value={id} />
    <CustomerDetailsBlock title={'Nazwa firmy'} value={company} />
    <CustomerDetailsBlock title={'Typ'} value={type} />
    <CustomerDetailsBlock title={'NIP'} value={nip} />
    <CustomerDetailsBlock title={'Adres'} value={address} />
    <CustomerDetailsBlock title={'Kod pocztowy'} value={postalCode} />
    <CustomerDetailsBlock title={'Miasto'} value={city} />
    <CustomerDetailsBlock title={'Telefon'} value={phone} />
    <CustomerDetailsBlock title={'Email'} value={email} />
    <CustomerDetailsBlock title={'Litera porządkowa'} value={letter} />
  </div>
);

const CustomerDetailsBlock = ({ title, value }: SummaryBlockP) => (
  <div className="summaryBlock">
    <div className="summaryBlock__title">
      <p>{title}</p>
    </div>
    <div className="summaryBlock__value"> {title === 'Email' ? <a href={`mailto:${value}`}>{value}</a> : value}</div>
    {/* {title === 'Email' ? <a href={`mailto:${value}`}>{value}</a> : {value}} */}

  </div>
);

type SummaryBlockP = { title: string; value: string | JSX.Element };

export default CustomerDetails;
