import { User } from './EnumTypes/ActionEnumTypes';
import * as UserActionModel from './Models/UserActionModel';
import * as UserModule from '../../Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { store } from '../Store';
import getInternalUsersLogs from './user/getInternalUsersLogs';
import getExternalUsersLogs from './user/getExternalUsersLogs';
import addExternalUser from './user/addExternalUser';
import updateClientsForExternalUser from './user/updateClientsForExternalUser';
import editExternalUser from './user/editExternalUser';
import getInternalUserOptions from './user/getInternalUserOptions';
import getAllUsersStats from './user/getAllUsersStats';
import checkCanActivate from './user/checkCanActivate';
import activateAccount from './user/activateAccount';
import getSidebarCounts from './user/getSidebarCounts';
import getExternalUserLogsXlsx from './user/getExternalUserLogsXlsx';
import getInternalUserLogsXlsx from './user/getInternalUserLogsXlsx';

function getInternalUsers(page: number, pageSize: any = 10) {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      const response = await UserModule.Connector.getInternalUsers(page, pageSize);
      dispatch(getInternalUsersSuccess(response.data));
      dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
    }
  };
}

function getInternalUsersSuccess(internalUsersList): UserActionModel.GetInternalUsers {
  return {
    type: User.GET_INTERNAL_USERS,
    payload: {
      internalUsersList,
    },
  };
}

function getExternalUsers(page: number, pageSize: any = 10) {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      const response = await UserModule.Connector.getExternalUsers(page, pageSize);
      dispatch(getExternalUsersSuccess(response.data));
      dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
    }
  };
}

function getExternalUsersSuccess(externalUsersList): UserActionModel.GetExternalUsers {
  return {
    type: User.GET_EXTERNAL_USERS,
    payload: {
      externalUsersList,
    },
  };
}

function addInternalUser(data) {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      await UserModule.Connector.addInternalUser(data);
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.success('Pomyślnie dodano użytkownika');
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.error('Wystąpił błąd podczas dodawania');
    }
  };
}

function getSpecificInternalUser(id: number, type: 'INTERNAL' | 'EXTERNAL') {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      if (type === 'INTERNAL') {
        const response = await UserModule.Connector.getSpecificInternalUser(id);
        dispatch(getSpecificInternalUserSuccess(response.data));
      }
      if (type === 'EXTERNAL') {
        const response = await UserModule.Connector.getSpecificExternallUser(id);
        dispatch(getSpecificExternallUserSuccess(response.data));
      }
      dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
    }
  };
}
function getSpecificExternallUserSuccess(externalUser): any {
  return {
    type: User.GET_SPECIFIC_EXTERNAL_USER,
    payload: {
      externalUser,
    },
  };
}
function getSpecificInternalUserSuccess(internalUser): UserActionModel.GetSpecificInternalUser {
  return {
    type: User.GET_SPECIFIC_INTERNAL_USER,
    payload: {
      internalUser,
    },
  };
}

function editSpecificInternalUser(data) {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      await UserModule.Connector.editSpecificInternalUser(data);
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.success('Pomyślnie edytowano użytkownika');
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.error('Wystąpił błąd podczas edytowania');
    }
  };
}

function deleteSpecificUser(id, type) {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      if (type === 'INTERNAL') {
        await UserModule.Connector.deleteSpecificInternalUser(id);
        const { internalUsersList } = store.getState().userStore;
        await dispatch(getInternalUsers(internalUsersList ? internalUsersList.pageable.pageNumber : 0));
      }

      if (type === 'EXTERNAL') {
        await UserModule.Connector.deleteExternalUser(id);
        const { externalUsersList } = store.getState().userStore;
        await dispatch(getExternalUsers(externalUsersList ? externalUsersList.pageable.pageNumber : 0));
      }
      dispatch(ViewManagementModule.Actions.hideConfirmPopup());
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.success('Pomyślnie usunięto użytkownika');
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.error('Wystąpił błąd podczas usuwania');
    }
  };
}
function changePasswordInternalUser(data) {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      await UserModule.Connector.changePasswordInternalUser(data);
      const { internalUsersList } = store.getState().userStore;
      await dispatch(getInternalUsers(internalUsersList ? internalUsersList.pageable.pageNumber : 0));
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.success('Pomyślnie zmieniono hasło');
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.error('Wystąpił błąd podczas zmiany');
    }
  };
}
export { editExternalUser, updateClientsForExternalUser, addExternalUser, getExternalUsersLogs, getInternalUsers, addInternalUser, getSpecificInternalUser, editSpecificInternalUser, deleteSpecificUser, changePasswordInternalUser, getExternalUsers, getInternalUsersLogs, getInternalUserOptions, getAllUsersStats, checkCanActivate, activateAccount, getSidebarCounts, getExternalUserLogsXlsx, getInternalUserLogsXlsx };
