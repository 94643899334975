import { Client } from '../EnumTypes/ActionEnumTypes';
import { getClientsXlsx as getClientsXlsxConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: Client.GET_CLIENTS_XLSX_INIT,
});

const success = () => ({
    type: Client.GET_CLIENTS_XLSX_SUCCESS,
});

const failure = () => ({
    type: Client.GET_CLIENTS_XLSX_FAILURE,
});

const getClientsXlsx = (params) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        const { data } = await getClientsXlsxConnector(params);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Klienci.xlsx');
        document.body.appendChild(link);
        link.click();
        dispatch(success());
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getClientsXlsx;

