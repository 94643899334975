/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Header from './Header';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { ROUTES } from 'shared/consts';
import { generatePath } from 'react-router';
import { history } from 'Store/Reducers/RootReducer';
import FileLoader from 'Components/Layout/FileLoader';
import moment from 'moment';

const FilesExplorer = ({ data, id, getFile, getDirectory, fileLoaderVisible, external, getFileExternal, getCaseFilesExternal }) => {
  const [isMainDirectory, setIsMainDirectory] = useState(true);
  const [files, setFiles] = useState(data?.items);
  const handleGetDirectory = () => {
    external ? getCaseFilesExternal(id) : getDirectory(id);
    setIsMainDirectory(true);
  };
  const handleGetSubDirectory = (path) => {
    external ? getCaseFilesExternal(id, path) : getDirectory(id, path);
    setIsMainDirectory(null);
  };
  const handleGoBack = () => (isMainDirectory ? (external ? history.push(generatePath(ROUTES.EXTERNAL_CASE_DETAILS, { id })) : history.push(generatePath(ROUTES.CASE_DETAILS, { id }))) : handleGetDirectory());

  const handleGetFile = (item) => {
    external ? getFileExternal(id, item) : getFile(id, item);
  };

  useEffect(() => {
    setFiles(data?.items);
  }, [data]);

  return (
    <>
      <div className="files__table--goBack" onClick={handleGoBack}>
        <div className="files__table--goBack--arrow">
          <img src={GrayArrow} alt="" />
          <div>{isMainDirectory ? 'Wróć do karty sprawy' : 'Wróć do głównego'}</div>
        </div>
        {fileLoaderVisible && <FileLoader />}
      </div>
      {/* <div className="files__select">
        <Select placeholder="Sortuj" onChange={handleSort} components={{ DropdownIndicator }} isSearchable={false} noOptionsMessage={() => 'Brak'} options={pageSizeOptions} isClearable={true} className="react-select" classNamePrefix="react-select" />
      </div> */}
      <Header files={files} setFiles={setFiles} />
      <main className="files__table--contentWrapper">
        <div className="files__table--content">
          {files &&
            files.map((item) => (
              <>
                {item.directory ? (
                  <div className="tile">
                    <button className="btn-action btn-action--folder actionTile" data-tip="Otwórz folder" onClick={() => handleGetSubDirectory({ path: item.path })}></button>
                  </div>
                ) : (
                  <div className="tile">
                    <button className="btn-action btn-action--open actionTile" data-tip="Pobierz plik" onClick={() => handleGetFile(item)}></button>
                  </div>
                )}
              </>
            ))}
        </div>
        <div className="files__table--content files__table--content--name">
          {files &&
            files.map((item) => (
              <>
                {item.directory ? (
                  <div className="files__table--content--file">
                    <div className="file" onClick={() => handleGetSubDirectory({ path: item.path })}>
                      {item.name}
                    </div>
                    <div className="data">{moment(item.modifiedAt).format('YYYY-MM-DD HH:MM')}</div>
                  </div>
                ) : (
                  <div className="files__table--content--file">
                    <div className="file" onClick={() => handleGetFile(item)}>
                      {item.name}
                    </div>
                    <div className="data">{moment(item.modifiedAt).format('YYYY-MM-DD HH:MM')}</div>
                  </div>
                )}
              </>
            ))}
        </div>
      </main>
    </>
  );
};

export default FilesExplorer;
