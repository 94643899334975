import API from './Config';
import { AxiosPromise } from 'axios';

export function getGroup(): AxiosPromise<any> {
  return API.get(`group`);
}

export function addGroup(groupData): AxiosPromise<any> {
  return API.post(`group`, groupData);
}

export function editGroup(groupData): AxiosPromise<any> {
  return API.put(`group`, groupData);
}

export function getCrmSettings(): AxiosPromise<any> {
  return API.get(`crm`);
}

export function updateCrmSettings(newCrmSettings): AxiosPromise<any> {
  return API.patch(`crm`, newCrmSettings);
}

export function getGroupById(id): AxiosPromise<any> {
  return API.get(`group/${id}`);
}

export function getPrivileges(): AxiosPromise<any> {
  return API.get(`privilege`);
}

export function deleteGroup(id): AxiosPromise<any> {
  return API.delete(`group/${id}`);
}

export function getGroupUsers(id): AxiosPromise<any> {
  return API.get(`group/${id}/users`);
}

export function updateGroupUsers(id, userIds): AxiosPromise<any> {
  return API.put(`group/${id}/users`, { userIds });
}
