import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getClientDetails, getClientNotes, getClientContacts } from 'Store/Actions/ClientActions';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as ClientSelector from 'Store/Selectors/ClientSelector';

type ConnectedP = {
  customerDetails: any;
  customerNotes: any;
  customerContacts: any;
};
type DispatchedP = {
  actions: {
    getClientDetails: (id: string) => void;
    getClientNotes: (id: string) => void;
    getClientContacts: (id: string) => void;
    showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => void;
  };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getClientDetails: (id) => getClientDetails(id),
      getClientNotes: (id) => getClientNotes(id),
      getClientContacts: (id) => getClientContacts(id),
      showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => ViewManagementModule.Actions.showConfirmPopup(typeConfirmPopup, dataToEdit),
    },
    dispatch,
  ),
});

function mapStateToProps(state): ConnectedP {
  return {
    customerDetails: ClientSelector.getClientDetailsData(state),
    customerNotes: ClientSelector.getClientNotesData(state),
    customerContacts: ClientSelector.getClientContactsData(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
