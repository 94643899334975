import * as React from 'react';
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
import RegularField from 'Components/Forms/Fields/RegularField';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { MainType } from './Container';
import { isEqual, isNotEmptyObject } from 'shared/utils';
import * as Yup from 'yup';

type S = {};
type P = {
  history: any;
};

const CRM_SETTINGS_SCHEMA = Yup.object().shape({
  mailActivationSubject: Yup.string().required('Pole wymagane'),
  mailActivationBody: Yup.string().required('Pole wymagane'),
  reportMail: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
});
export default class Main extends React.Component<MainType & P, S> {
  async componentDidMount() {
    await this.props.actions.getCrmSettings();
  }
  onSubmit = async (values) => {
    const { mailActivationSubject, mailActivationBody, reportMail } = this.props.crmSettings;
    const updated = isEqual({ mailActivationSubject, mailActivationBody, reportMail }, values);
    if (isNotEmptyObject(updated)) {
      await this.props.actions.patchCrmSettings(updated);
    }
  };

  public render() {
    const { mailActivationSubject, mailActivationBody, reportMail } = this.props.crmSettings;
    return (
      <div className="add-customer">
        <div className="add-customer__title">
          <Link to={'/dashboard'}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Ustawienia CRM</h2>
        </div>
        <div className="add-customer__form-container">
          <Formik validationSchema={CRM_SETTINGS_SCHEMA} enableReinitialize initialValues={{ mailActivationSubject, mailActivationBody, reportMail }} onSubmit={this.onSubmit}>
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} className="add-customer__form">
                <Field name="mailActivationSubject" type="text" wrapperClassName="default" label="Temat maila z danymi konta UZ" component={RegularField} errors={errors} />
                <Field name="mailActivationBody" type="textarea" wrapperClassName="default" label="Treść maila z danymi konta UZ" component={RegularField} errors={errors} />
                <Field name="reportMail" type="email" wrapperClassName="default" label="Comiesięczne raporty na podany adres email" component={RegularField} errors={errors} isAutoComplete={false} />
                <button className="btn btn--default btn--login" type="submit">
                  Zapisz
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
