import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { history } from '../Store/Reducers/RootReducer';
import Select, { components } from 'react-select';
import IndicatorsIcon from '../Assets/Img/Icons/indicators-icon.svg';

const Pagination = (props: any) => {
  const [pageSize, setPageSize] = React.useState(sessionStorage.getItem('pageSize') ? JSON.parse(sessionStorage.getItem('pageSize')) : { label: '10', value: '10' });
  const page = props.match.params?.page || props.pageNo + 1;
  let currentPage: number = !page ? 1 : page > 0 && page <= props.pages ? Number(page) : page < 0 ? 1 : props.pages;
  const pageLinks = [];
  const nextPage = async (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= props.pages) {
      currentPage = pageNumber - 1;
      if (props.path) {
        history.push(`${props.path}page/${currentPage + 1}`);
      }
      props.uuid ? await props.getFunction(currentPage, props.uuid) : await props.getFunction(currentPage, sessionStorage.getItem('pageSize') ? JSON.parse(sessionStorage.getItem('pageSize')).value : '10');
    }
  };
  // const showBegginingDots = currentPage > 3;
  // const showEndingDots = currentPage < props.pages - 3;

  // pageLinks.push(
  //   <li className={`pagination__list-element pagination__list-element--${currentPage === 1 && 'active'}`} onClick={() => nextPage(1)}>
  //     1
  //   </li>,
  // );
  // if (showBegginingDots) {
  //   pageLinks.push('.....');
  // } else {
  //   console.log('MATI');
  //   for (let j = 2; j < currentPage - 1; j++) {
  //     pageLinks.push(
  //       <li className={`pagination__list-element pagination__list-element--${currentPage === j && 'active'}`} key={j} onClick={() => nextPage(j)}>
  //         {j}
  //       </li>,
  //     );
  //   }
  // }
  // if (currentPage > 2) {
  //   pageLinks.push(
  //     <li className={`pagination__list-element pagination__list-element--${currentPage === 1 && 'active'}`} onClick={() => nextPage(currentPage - 1)}>
  //       {currentPage - 1}
  //     </li>,
  //   );
  // }
  // if (currentPage !== 1) {
  //   pageLinks.push(
  //     <li className={`pagination__list-element pagination__list-element--${currentPage === 1 && 'active'}`} onClick={() => nextPage(currentPage)}>
  //       {currentPage}
  //     </li>,
  //   );
  // }
  // if (currentPage > props.pages - 1) {
  //   pageLinks.push(
  //     <li className={`pagination__list-element pagination__list-element--${currentPage === 1 && 'active'}`} onClick={() => nextPage(currentPage + 1)}>
  //       {currentPage + 1}
  //     </li>,
  //   );
  // }
  // if (showEndingDots) {
  //   pageLinks.push('.....');
  // } else {
  //   for (let i = currentPage + 2; i < props.pages; i++) {
  //     pageLinks.push(
  //       <li className={`pagination__list-element pagination__list-element--${currentPage === i && 'active'}`} key={i} onClick={() => nextPage(i)}>
  //         {i}
  //       </li>,
  //     );
  //   }
  // }
  // pageLinks.push(
  //   <li className={`pagination__list-element pagination__list-element--${currentPage === props.pages && 'active'}`} onClick={() => nextPage(props.pages)}>
  //     {props.pages}
  //   </li>,
  // );
  if (props.pages > 6) {
    const showDots = currentPage >= 3 && currentPage < props.pages - 4;

    if (currentPage < 3) {
      for (let i = 1; i <= 3; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
      pageLinks.push('.....');
      for (let i = props.pages - 2; i <= props.pages; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
    }
    if (showDots) {
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
      pageLinks.push('.....');
      for (let i = props.pages - 2; i <= props.pages; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
    }
    if (currentPage >= props.pages - 4) {
      for (let i = currentPage - 2; i <= props.pages; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
    }
  } else {
    for (let i = 1; i <= props.pages; i++) {
      const active = currentPage === i ? 'active' : '';
      pageLinks.push(
        <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
          {i}
        </li>,
      );
    }
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={IndicatorsIcon} alt="" />
      </components.DropdownIndicator>
    );
  };

  const handleChange = async (value) => {
    if (value) {
      setPageSize(value);
      sessionStorage.setItem('pageSize', JSON.stringify(value));
      await props.getFunction((currentPage = 0), value.value);
    }
  };

  const pageSizeOptions = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
    { label: '200', value: 200 },
  ];
  return (
    <div className="pagination">
      <p>Na stronie</p>
      <Select components={{ DropdownIndicator }} value={pageSize} isSearchable={false} onChange={handleChange} noOptionsMessage={() => 'Brak'} options={pageSizeOptions} isClearable={true} className="react-select" classNamePrefix="react-select" />
      <ul className="pagination__list">
        {/*<li className={`pagination__list-element ${currentPage === 1 && 'pagination__list-element--disabled'}`} onClick={() => nextPage(currentPage === 1 ? 0 : 1)}>*/}
        {/*  <i className="fas fa-angle-double-left" />*/}
        {/*</li>*/}
        {/*<li className={`pagination__list-element ${currentPage === 1 && 'pagination__list-element--disabled'}`} onClick={() => nextPage(currentPage - 1)}>*/}
        {/*  <i className="fas fa-angle-left" />*/}
        {/*</li>*/}
        {pageLinks}
        {/*<li className={`pagination__list-element ${currentPage === props.pages && 'pagination__list-element--disabled'}`} onClick={() => nextPage(currentPage + 1)}>*/}
        {/*  <i className="fas fa-angle-right" />*/}
        {/*</li>*/}
        {/*<li className={`pagination__list-element ${currentPage === props.pages && 'pagination__list-element--disabled'}`} onClick={() => nextPage(currentPage === props.pages ? 0 : props.pages)}>*/}
        {/*  <i className="fas fa-angle-double-right" />*/}
        {/*</li>*/}
      </ul>
    </div>
  );
};

export default withRouter(Pagination);
