import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import * as AuthModule from '../Modules/AuthModule';
import * as ViewManagementModule from '../Modules/ViewManagementModule';
import { RootState } from '../Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import * as LimitsModule from 'Modules/LimitsModule';

import Navigation from 'Components/Layout/Navigation';
import RemindersList from 'Components/Pages/Profile/Reminder/RemindersList';
import CustomersList from 'Components/Pages/Customers/ContainerCustomersList';
import FavoriteCustomersList from 'Components/Pages/Customers/ContainerFavoriteCustomersList';
import AddReminder from 'Components/Pages/Profile/Reminder/AddReminder';
import PostponeReminder from 'Components/Pages/Profile/Reminder/PostponeReminder';
import RespondReminder from 'Components/Pages/Profile/Reminder/RespondReminder';
import AddCustomer from 'Components/Pages/Customers/AddCustomer';
import TimeTrialList from 'Components/Pages/Profile/TimeTrial/TimeTrialList';
import AddTimeTrial from './Pages/Profile/TimeTrial/AddTimeTrial';
import CasesList from './Pages/Cases/Container';
import CaseDetails from './Pages/CaseDetails/Container';
import ExternalCaseDetails from './Pages/ExternalCaseDetails/Container';
import AddCaseRemainder from './Pages/AddCaseRemainder/Container';
import AddCaseStatusDescription from './Pages/AddCaseStatusDescription/Container';
import ReplyToRemainder from './Pages/ReplyToRemainder/Container';
import Files from './Pages/Files/Container';
import SettingsList from './Pages/Settings/SettingsList';
import LimitsList from './Pages/Limits/Container';
import InternalUsersList from './Pages/Settings/InternalUsers/InternalUsersList';
import AddInternalUser from './Pages/Settings/InternalUsers/AddInternalUser/index';
import EditInternalUser from './Pages/Settings/InternalUsers/EditInternalUser/index';
import ChangeUserPassword from 'shared/components/ChangeUserPassword';
import ExternalUsersList from './Pages/Settings/ExternalUsers/ExternalsUsersList';
import AddExternalUser from './Pages/Settings/ExternalUsers/AddExternalUser';
import CRM from 'screens/crm-settings/Container';
import InternalUsersLog from 'screens/internal-users-log/Container';
import ExternalUsersLog from 'screens/external-users-log/Container';
import InternalUsersGroups from 'screens/internal-users-groups/Container';
import ManageInternalUsersGroup from 'screens/manage-internal-user/Container';
import AssignUsersToGroup from 'screens/assign-users-to-internal-group/Container';
import AddCase from './Pages/AddCase/Container';
import EditCase from './Pages/EditCase/Container';
import CustomerCard from './Pages/CustomerCard/Container';
import AddContact from './Pages/AddContact/Container';
import EditContact from './Pages/EditContact/Container';
import AddNote from './Pages/AddNote/Container';
import EditNote from './Pages/EditNote/Container';
import Statistics from './Pages/Statistics/Container';
import SearchEngine from './Pages/SearchEngine/Container';
import FavouriteCases from './Pages/FavouriteCases/Container';
import ExternalUserFavouriteCases from './Pages/ExternalUserFavouriteCases/Container';
import ExternalUserCases from './Pages/ExternalUserCases/Container';
import EditReminder from './Pages/EditReminder/Container';
import { ROUTES, PRIVILEGES } from 'shared/consts';
import ProtectedRoute from '../Components/Routes/ProtectedRoute';
import { checkPrivilegeAccess } from 'shared/utils';

type S = {};
type ConnectedP = {
  authorizedUser: AuthModule.Types.AuthorizedUserData;
  isMenuExpanded: boolean;
  location?: any;
};
type DispatchedP = {
  logout: () => void;
  getAuthorizedUser: () => void;
  expandMenu: () => void;
  getClientsReachingLimitsCount: () => void;
};
class Dashboard extends React.Component<DispatchedP & ConnectedP, S> {
  constructor(props: ConnectedP & DispatchedP) {
    super(props);
  }

  async componentDidMount() {
    await this.props.getAuthorizedUser();
  }

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  async componentDidUpdate(prevProps){
    const {
      location: { pathname },
    } = this.props;
    if (prevProps.location.pathname !== pathname) {
      await this.props.getClientsReachingLimitsCount();
    }
  }

  public render() {
    const { authorizedUser } = this.props;

    if (authorizedUser) {
      const { admin, external } = authorizedUser;
      const isAddClientAccess = checkPrivilegeAccess(PRIVILEGES.CLIENT_ADD);
      const isEditClientAccess = checkPrivilegeAccess(PRIVILEGES.CLIENT_EDIT);
      const isCardCaseAccess = checkPrivilegeAccess(PRIVILEGES.CASE_CARD);
      const isExternalCardCaseAccess = checkPrivilegeAccess(PRIVILEGES.CLIENT_ADD);
      const isStatisticsAccess = checkPrivilegeAccess(PRIVILEGES.STATS_VIEW);

      return (
        <div className="dashboard">
          <Navigation />
          <div className={`dashboard__content ${this.props.isMenuExpanded ? 'is-blurred' : ''}`}>
            <Switch>
              {/* Routes for all users */}
              <Route exact path={ROUTES.DASHBOARD} component={external ? ExternalUserCases : RemindersList} />
              <Route exact path={ROUTES.SEARCH_ENGINE} component={SearchEngine} />
              <Route exact path={ROUTES.FAVOURITE_CASES} component={external ? ExternalUserFavouriteCases : FavouriteCases} />
              <Route exact path={ROUTES.FILES} component={Files} />

              <ProtectedRoute exact path={ROUTES.CASE_DETAILS} component={CaseDetails} isPermissionGranted={isCardCaseAccess} />
              <ProtectedRoute exact path={ROUTES.EXTERNAL_CASE_DETAILS} component={ExternalCaseDetails} isPermissionGranted={isExternalCardCaseAccess} />

              {/* Only external users */}
              <ProtectedRoute exact path={ROUTES.CASES_LIST_CUSTOMER} component={CasesList} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.EXTERNAL_USER_CASES} component={ExternalUserCases} isPermissionGranted={external} />

              {/* Only internal users */}
              <ProtectedRoute exact path={ROUTES.TIME_TRIAL_LIST} component={TimeTrialList} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.CASES_LIST_CONTRACTOR} component={CasesList} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.CASES_LIST_SUBCONTRACTOR} component={CasesList} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.ADD_TIME_TRIAL} component={AddTimeTrial} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.EDIT_TIME_TRIAL} component={AddTimeTrial} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.ADD_CASE} component={AddCase} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.EDIT_CASE} component={EditCase} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.STATISTICS} component={Statistics} isPermissionGranted={!external && isStatisticsAccess} />

              {/* Customer */}
              <ProtectedRoute exact path={ROUTES.CUSTOMER_LIST} component={CustomersList} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.FAVOURITE_CUSTOMER_LIST} component={FavoriteCustomersList} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.ADD_CUSTOMER} component={AddCustomer} isPermissionGranted={!external && isAddClientAccess} />
              <ProtectedRoute exact path={ROUTES.EDIT_CUSTOMER} component={AddCustomer} isPermissionGranted={!external && isEditClientAccess} />
              <ProtectedRoute exact path={ROUTES.CUSTOMER_CARD} component={CustomerCard} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.ADD_CONTACT} component={AddContact} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.EDIT_CONTACT} component={EditContact} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.ADD_NOTE} component={AddNote} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.EDIT_NOTE} component={EditNote} isPermissionGranted={!external} />

              {/* Case details */}
              <ProtectedRoute exact path={ROUTES.CASE_DETAILS_ADD_REMAINDER} component={AddCaseRemainder} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.CASE_DETAILS_ADD_STATUS_DESCRIPTION} component={AddCaseStatusDescription} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.CASE_DETAILS_EDIT_STATUS_DESCRIPTION} component={AddCaseStatusDescription} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.CASE_DETAILS_REPLY_TO_REMAINDER} component={ReplyToRemainder} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.CASE_DETAILS_EDIT_REMAINDER} component={EditReminder} isPermissionGranted={!external} />

              {/* Reminder */}
              <ProtectedRoute exact path={ROUTES.REMAINDER_LIST} component={RemindersList} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.ADD_REMAINDER} component={AddReminder} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.POSTPONE_REMINDER} component={PostponeReminder} isPermissionGranted={!external} />
              <ProtectedRoute exact path={ROUTES.RESPOND_REMINDER} component={RespondReminder} isPermissionGranted={!external} />

              {/* Settings */}
              <ProtectedRoute exact path={ROUTES.LIMITS} component={LimitsList} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.SETTINGS} component={SettingsList} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.INTERNAL_USERS} component={InternalUsersList} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.INTERNAL_USER_PAGE} component={InternalUsersList} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.ADD_INTERNAL_USER} component={AddInternalUser} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.EDIT_INTERNAL_USER} component={EditInternalUser} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.CHANGE_INTERNAL_USER_PASSWORD} component={ChangeUserPassword} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.CHANGE_EXTERNAL_USER_PASSWORD} component={ChangeUserPassword} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.EXTERNAL_USERS} component={ExternalUsersList} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.EXTERNAL_USER_PAGE} component={ExternalUsersList} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.ADD_EXTERNAL_USER} component={AddExternalUser} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.EDIT_EXTERNAL_USER} component={AddExternalUser} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.CRM_SETTINGS} component={CRM} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.INTERNAL_USERS_LOG} component={InternalUsersLog} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.EXTERNAL_USERS_LOG} component={ExternalUsersLog} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.INTERNAL_USERS_GROUPS} component={InternalUsersGroups} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.INTERNAL_USERS_MANAGE_GROUP} component={ManageInternalUsersGroup} isPermissionGranted={admin} />
              <ProtectedRoute exact path={ROUTES.MANAGE_INTERNAL_GROUP_USERS} component={AssignUsersToGroup} isPermissionGranted={admin} />

              <Route exact component={external ? ExternalUserCases : RemindersList} />
            </Switch>
          </div>
        </div>
      );
    } else return null;
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  getAuthorizedUser: () => AuthModule.Actions.getAuthorizedUser(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  getClientsReachingLimitsCount: () => LimitsModule.Actions.getClientsReachingLimitsCount(),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    authorizedUser: AuthSelectors.authorizedUser(state),
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
