import * as Types from '../../Models/AuthModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { AuthAction } from '../Actions/Models/AuthActionModel';
import { parseJWT } from 'Helpers/Utils';

export type State = Types.AuthStore;

export const initialState: State = {
  token: null,
  authorizedUser: null,
  tokenExpires: null,
};

export function authReducer(state: State = initialState, action: AuthAction) {
  switch (action.type) {
    case ActionTypes.Auth.LOG_IN_SUCCESS: {
      let tokenExpires: any = new Date(parseJWT(action.payload.authData.token).exp * 1000);
      tokenExpires.setSeconds(tokenExpires.getSeconds() - 20);
      tokenExpires = tokenExpires.toLocaleTimeString();
      localStorage.setItem('token', action.payload.authData.token);
      localStorage.setItem('tokenExpires', tokenExpires);
      // console.log(parseJWT(action.payload.authData.token), 'parsed Auth');

      return {
        ...state,
        token: action.payload.authData.token,
        authorizedUser: parseJWT(action.payload.authData.token),
        // authorizedUser: { ...state.authorizedUser, ...parseJWT(action.payload.authData.token) }, // COMMENTED TO REMOVE LATENCY NAVIGATION CONDITIONAL
        tokenExpires: tokenExpires,
      };
    }
    case ActionTypes.Auth.LOG_OUT_SUCCESS: {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpires');
      return {
        ...state,
        token: null,
        tokenExpires: null,
        authorizedUser: null,
      };
    }
    case ActionTypes.Auth.LOG_OUT_FAILURE: {
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpires');
      return {
        ...state,
        token: null,
        tokenExpires: null,
        authorizedUser: null,
      };
    }
    case ActionTypes.Auth.GET_AUTHORIZED_USER: {
      return {
        ...state,
        // authorizedUser: { ...state.authorizedUser, ...action.payload.authorizedUserData.userShortDto }
        authorizedUser: action.payload.authorizedUserData.userShortDto
      };
    }
    case ActionTypes.Auth.SET_REFRESH_TOKEN: {
      let tokenExpires: any = new Date(parseJWT(action.payload.authData.token).exp * 1000);
      tokenExpires.setSeconds(tokenExpires.getSeconds() - 20);
      tokenExpires = tokenExpires.toLocaleTimeString();
      localStorage.setItem('token', action.payload.authData.token);
      localStorage.setItem('tokenExpires', tokenExpires);
      return {
        ...state,
        token: action.payload.authData.token,
        // authorizedUser: ,
        // authorizedUser: { ...state.authorizedUser, ...parseJWT(action.payload.authData.token) },  // COMMENTED TO REMOVE LATENCY NAVIGATION CONDITIONAL
        tokenExpires: tokenExpires,
      };
    }
    default: {
      return state;
    }
  }
}
