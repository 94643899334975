import { CASES } from '../EnumTypes/ActionEnumTypes';
import { getCaseCounts as getCaseCountsConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: CASES.GET_CASE_COUNTS_INIT,
});

const success = (casesCounts) => ({
  type: CASES.GET_CASE_COUNTS_SUCCESS,
  payload: {
    casesCounts,
  },
});

const failure = () => ({
  type: CASES.GET_CASE_COUNTS_FAILURE,
});

const getCaseCounts = (execType) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await getCaseCountsConnector(execType);
    dispatch(success(data));
    // dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getCaseCounts;
