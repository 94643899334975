import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.UPDATE_EXTERNAL_USER_CLIENTS_INIT,
});

const success = () => ({
  type: User.UPDATE_EXTERNAL_USER_CLIENTS_SUCCESS,
});

const failure = () => ({
  type: User.UPDATE_EXTERNAL_USER_CLIENTS_FAILURE,
});

const updateClientsForExternalUser = (clients, userId) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await Connector.updateClientsForExternalUser(clients, userId);
    dispatch(success());
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default updateClientsForExternalUser;
