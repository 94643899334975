import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';
// import RemainderReplies from './remainderReplies';

type P = {
    onSubmit: (values: any) => void;
    initialValues: object;
};
const EditRemainderForm = ({ onSubmit, initialValues }: P) => {
  return (
    <Formik
      initialValues={{ about: '', remindAt: '' }}
      validationSchema={Yup.object().shape({
        about: Yup.string().required('Pole wymagane'),
        remindAt: Yup.string().required('Pole wymagane'),
      })}
      onSubmit={onSubmit}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="add-customer__form">
          <div className="form--inner">
            {/* <RemainderReplies /> */}
            <Field name="about" type="textarea" wrapperClassName="default" label="Przypomnienie" component={RegularField} errors={errors} />
            <Field name="remindAt" type="date" wrapperClassName="default" placeholder="Podaj datę" label="Data" component={RegularField} errors={errors} />
          </div>
          <button className="btn btn--default btn--login btn--center" type="submit">
            edytuj
          </button>
        </form>
      )}
    </Formik>
  );
};

export default EditRemainderForm;
