import FavouriteCasesList from './FavouriteCasesList';
import { connect } from 'react-redux';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { getCaseCounts, getCasesList, getClientCasesList, getClientCaseCounts, getFavouriteCases, manageFavourite } from 'Store/Actions/cases';
import * as CaseSelector from 'Store/Selectors/CaseSelector';

type ConnectedP = {
  isMenuExpanded: boolean;
  isAddReminderView: boolean;
  prevPath: string;
  caseCounts: any;
  casesList: Array<any>;
  favouriteCases: any;
};
type DispatchedP = {
  expandMenu: () => void;
  getCaseCounts: (execType: string) => void;
  getCasesList: (execType: string, params: any) => void;
  getFavouriteCases: () => void;
  getClientCasesList: (id: number, params: any) => void;
  getClientCaseCounts: (id: number) => void;
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => void;
  manageFavourite: (caseId: number, isFavourite: boolean) => void;
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps: DispatchedP = {
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  getCaseCounts: (execType) => getCaseCounts(execType),
  getCasesList: (execType, params) => getCasesList(execType, params),
  getFavouriteCases: () => getFavouriteCases(),
  getClientCasesList: (id, params) => getClientCasesList(id, params),
  getClientCaseCounts: (id) => getClientCaseCounts(id),
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => ViewManagementModule.Actions.showConfirmPopup(typeConfirmPopup, dataToEdit),
  manageFavourite: (caseId: number, isFavourite: boolean) => manageFavourite(caseId, isFavourite),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    isAddReminderView: ViewManagementSelectors.isAddReminderView(state),
    prevPath: ViewManagementSelectors.prevPath(state),
    caseCounts: CaseSelector.getCasesCounts(state),
    casesList: CaseSelector.getCasesList(state),
    favouriteCases: CaseSelector.getFavouriteCases(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteCasesList);
