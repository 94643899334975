import * as React from 'react';
import { MainType } from './Container';
import { Breadcrumbs, Table, ExternalPagination, Button } from 'shared/components';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

type S = {
  page: number;
  size: number;
  date: Date[];
};
type P = {
  history: any;
  location: any;
};
export default class Main extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 10,
      date: [new Date(), new Date()],
    };
  }
  async componentDidMount() {
    await this.props.actions.getInternalUsersLogs(this.state.page, this.state.size);
  }
  getLogs = async () => await this.props.actions.getInternalUsersLogs(this.state.page, this.state.size);

  onSizeChange = (size) => {
    this.setState({ size, page: 0 }, () => this.getLogs());
  };
  onPageChange = (page) => this.setState({ page }, () => this.getLogs());

  prepareDate = (date) => {
    const dateFrom = date[0];
    const dateTo = date[1];
    return { dateFrom: dateFrom.toLocaleDateString('sv'), dateTo: dateTo.toLocaleDateString('sv') };
  };

  public render() {
    const { pathname } = this.props.location;
    const { size, totalPages, content } = this.props.internalUsersLogData;
    const getInternalUserLogsXlsx = this.props.actions.getInternalUserLogsXlsx;
    const { date } = this.state;
    return (
      <div className="internal-users-log">
        <div className="internal-users-log__header">
          <Breadcrumbs pathname={pathname} />
          <ExternalPagination size={size} pages={totalPages} page={0} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
        </div>
        <div className="internal-users-log__list">
          <div className="internal-users-log__button">
            <Button label="EKSPORTUJ LOGI" onClick={() => (date ? getInternalUserLogsXlsx(this.prepareDate(date)) : null)} />
            <label className="internal-users-log__date">
              Wybierz zakres dat do eksportu
              <DateRangePicker onChange={(value) => this.setState({ date: value })} value={date} />
            </label>
          </div>
          <Table
            className="regular-table"
            data={content}
            columns={[
              {
                id: 'userFirstAndLastName',
                accessor: 'userFirstAndLastName',
                Header: 'Użytkownik',
                filterable: true,
                Filter: () => <input className="table-filter-input" id="userFirstAndLastNameFilter" placeholder="Wyszukaj" />,
              },
              {
                id: 'lastLoginDate',
                accessor: 'lastLoginDate',
                Header: 'Kiedy',
              },
              {
                id: 'description',
                accessor: 'description',
                Header: 'Co',
              },
            ]}
          />
        </div>
      </div>
    );
  }
}
