import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { searchCase } from 'Store/Actions/cases';
import * as CaseSelector from 'Store/Selectors/CaseSelector';
import { authorizedUser } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
    searchedCasesList: any;
    authorizedUser: any;
};
type DispatchedP = {
    actions: {
        searchCase: (params) => void;
    };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
    actions: bindActionCreators(
        {
            searchCase: (params) => searchCase(params),
        },
        dispatch,
    ),
});

function mapStateToProps(state): ConnectedP {
    return {
        searchedCasesList: CaseSelector.getSearchedCasesList(state),
        authorizedUser: authorizedUser(state)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
