import * as React from 'react';

const filterKeys = ['date', 'company', 'ordering', 'userFullName', 'description', 'time'];

const keyTranslations = {
  date: 'Data wpisu',
  company: 'Klient',
  ordering: 'Zlecający',
  userFullName: 'Użytkownik',
  description: 'Opis',
  time: 'Czas (w h)',
};

const CustomFilters = ({ filters = {}, onFilterChange }: any) => (
  <>
    {filterKeys.map((key) => (
      <th key={key}>
        {keyTranslations[key]}
        <br />
        <input type="text" name={key} id={key} value={filters[key]} onChange={({ target: { value } }) => onFilterChange(key, value)} />
      </th>
    ))}
  </>
);
export default CustomFilters;
