import React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';

type P = {
  onSubmit: (values: any) => void;
  data: any;
};

const AddCaseRemainderForm = ({ onSubmit, data }: P) => {
  return (
    <>
      {data && (
        <Formik
          initialValues={{ note: data }}
          validationSchema={Yup.object().shape({
            note: Yup.string().required('Pole wymagane'),
          })}
          onSubmit={onSubmit}>
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className="add-customer__form">
              <Field name="note" type="textarea" wrapperClassName="default" placeholder="Wpisz treść notatki" label="Treść notatki" component={RegularField} errors={errors} />
              <button className="btn btn--default btn--login btn--center" type="submit">
                Edytuj
              </button>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddCaseRemainderForm;
