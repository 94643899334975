import { CASES } from './../Actions/EnumTypes/ActionEnumTypes';

export type State = any;

export const initialState: State = {
  casesList: undefined,
  favouriteCasesList: undefined,
  casesCounts: undefined,
  externalUserCasesCounts: undefined,
  externalUserCasesList: undefined,
  caseDetails: undefined,
  searchedCasesList: undefined,
  files: undefined,
};

export function casesReducer(state: any = initialState, action) {
  switch (action.type) {
    case CASES.GET_CASES_LIST_SUCCESS: {
      return {
        ...state,
        casesList: action.payload.casesList,
      };
    }
    case CASES.GET_CLIENT_CASES_LIST_SUCCESS: {
      return {
        ...state,
        casesList: action.payload.casesList,
      };
    }
    case CASES.GET_CASE_COUNTS_SUCCESS: {
      return {
        ...state,
        casesCounts: action.payload.casesCounts,
      };
    }
    case CASES.GET_CLIENT_CASE_COUNTS_SUCCESS: {
      return {
        ...state,
        casesCounts: action.payload.casesCounts,
      };
    }
    case CASES.GET_CASE_DETAILS_SUCCESS: {
      return {
        ...state,
        caseDetails: action.payload.caseDetails,
      };
    }
    case CASES.GET_EXTERNAL_CASE_DETAILS_SUCCESS: {
      return {
        ...state,
        caseDetails: action.payload.caseDetails,
      };
    }
    case CASES.SEARCH_CASE_SUCCESS: {
      return {
        ...state,
        searchedCasesList: action.payload.data,
      };
    }
    case CASES.GET_FAVOURITE_CASES_SUCCESS: {
      return {
        ...state,
        favouriteCasesList: action.payload.data,
      };
    }
    case CASES.GET_EXTERNAL_USER_CASES_COUNTS_SUCCESS: {
      return {
        ...state,
        externalUserCasesCounts: action.payload.data,
      };
    }
    case CASES.GET_EXTERNAL_USER_CASES_LIST_SUCCESS: {
      return {
        ...state,
        externalUserCasesList: action.payload.casesList,
      };
    }
    case CASES.GET_CASE_FILES_INIT: {
      return {
        ...state,
        files: [],
      };
    }
    case CASES.GET_CASE_FILES_SUCCESS: {
      return {
        ...state,
        files: action.payload.data,
      };
    }
    case CASES.SET_ELECTRONIC_ONLY_SUCCESS: {
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          caseDetails: {
            ...state.caseDetails.caseDetails,
            electronicOnly: action.payload.isElectronicOnly,
          },
        },
      };
    }
    case CASES.GET_CASE_STATUSES_SUCCESS: {
      // state.caseDetails.statusDescription = action.payload.data
      // console.log(state.caseDetails)
      console.log(action.payload.data)
      return {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          statusDescriptions:action.payload.data
        },
      };
    }
    default: {
      return state;
    }
  }
}
