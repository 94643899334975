import { TimeTrial } from '../EnumTypes/ActionEnumTypes';
import { editTimeTrial as editTimeTrialConnector } from 'Connectors/TimeTrialConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: TimeTrial.EDIT_TIME_TRIAL_INIT,
});

const success = () => ({
  type: TimeTrial.EDIT_TIME_TRIAL_SUCCESS,
});

const failure = () => ({
  type: TimeTrial.EDIT_TIME_TRIAL_FAILURE,
});

const editTimeTrial = (timeTrialData) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await editTimeTrialConnector({ timeEntryUpdateDto: { ...timeTrialData } });
    dispatch(success());
    toast.success('Pomyślnie edytowano czasówkę');
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(ROUTES.TIME_TRIAL_LIST);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się edytować czasówki');
    dispatch(failure());
  }
};

export default editTimeTrial;
