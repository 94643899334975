import * as ActionTypes from './EnumTypes/ActionEnumTypes';
import * as ViewManagementActionModel from './Models/ViewManagementActionModel';

function showLoader(): ViewManagementActionModel.ShowLoader {
  return {
    type: ActionTypes.ViewManagement.SHOW_LOADER,
  };
}

function hideLoader(): ViewManagementActionModel.HideLoader {
  return {
    type: ActionTypes.ViewManagement.HIDE_LOADER,
  };
}

function setPrevPath(prevPath: string): ViewManagementActionModel.SetPrevPath {
  return {
    type: ActionTypes.ViewManagement.SET_PREV_PATH,
    payload: {
      prevPath,
    },
  };
}
function expandMenu(): ViewManagementActionModel.ExpandMenu {
  return {
    type: ActionTypes.ViewManagement.EXPAND_MENU,
  };
}
function expandAddReminderView(): ViewManagementActionModel.ExpandAddReminderView {
  return {
    type: ActionTypes.ViewManagement.EXPAND_ADD_REMINDER_VIEW,
  };
}

function showConfirmPopup(typeConfirmPopup: string, dataToEdit): ViewManagementActionModel.ShowConfirmPopup {
  return {
    type: ActionTypes.ViewManagement.SHOW_CONFIRM_POPUP,
    payload: {
      typeConfirmPopup,
      dataToEdit,
    },
  };
}
function hideConfirmPopup(): ViewManagementActionModel.HideConfirmPopup {
  return {
    type: ActionTypes.ViewManagement.HIDE_CONFIRM_POPUP,
  };
}

function showFileLoader(): ViewManagementActionModel.ShowFileLoader {
  return {
    type: ActionTypes.ViewManagement.SHOW_FILE_LOADER,
  };
}

function hideFileLoader(): ViewManagementActionModel.HideFileLoader {
  return {
    type: ActionTypes.ViewManagement.HIDE_FILE_LOADER,
  };
}

export { showLoader, hideLoader, setPrevPath, expandMenu, expandAddReminderView, showConfirmPopup, hideConfirmPopup, showFileLoader, hideFileLoader };
