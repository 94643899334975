import * as React from 'react';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { MainType } from './Container';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { ROUTES } from 'shared/consts';
import AddCaseRemainderForm from './components/addCaseRemainderForm';

type P = {
  location: any;
  match: any;
};

class Main extends React.Component<MainType & P, any> {
  componentDidMount() {
    this.props.actions.getInternalUserOptions();
  }

  handleSubmit = async (values) => {
    await this.props.actions.addCaseRemainder(values, this.props.match.params.id);
  };

  render() {
    const { params } = this.props.match;
    const { caseNo } = this.props.location.state;
    const { internalUserOptions } = this.props;
    return (
      <div className="addCaseRemainder narrow-section">
        <div className="narrow-section__title">
          <Link to={generatePath(ROUTES.CASE_DETAILS, { id: params.id })}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Dodaj przypomnienie</h2>
        </div>
        <div className="add-customer__form-container">
          <AddCaseRemainderForm onSubmit={this.handleSubmit} caseNo={caseNo} internalUserOptions={internalUserOptions} />
        </div>
      </div>
    );
  }
}

export default Main;
