import { Client } from '../EnumTypes/ActionEnumTypes';
import { getClientCaseXlsx as getClientCaseXlsxConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Client.GET_CLIENTS_CASE_XLSX_INIT,
});

const success = () => ({
  type: Client.GET_CLIENTS_CASE_XLSX_SUCCESS,
});

const failure = () => ({
  type: Client.GET_CLIENTS_CASE_XLSX_FAILURE,
});

const getClientCaseXlsx = (clientId, params) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
        const { data } = await getClientCaseXlsxConnector(clientId, params);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Klienci.xlsx');
        document.body.appendChild(link);
        link.click();
        dispatch(success());
        dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(failure());
  }
};


export default getClientCaseXlsx;
