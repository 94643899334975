import { Client } from '../EnumTypes/ActionEnumTypes';
import { deleteClientNote as deleteClientNoteConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import getClientNotes from './getClientNotes';

const init = () => ({
  type: Client.DELETE_CLIENT_NOTE_INIT,
});

const success = () => ({
  type: Client.DELETE_CLIENT_NOTE_SUCCESS,
});

const failure = () => ({
  type: Client.DELETE_CLIENT_NOTE_FAILURE,
});

const deleteClientNote = (data) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await deleteClientNoteConnector(data.id);
    dispatch(success());
    await dispatch(ViewManagementModule.Actions.hideConfirmPopup());
    await dispatch(getClientNotes(data.customerId));
    toast.success('Pomyślnie usunięto notatkę');
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się usunąć notatki');
    dispatch(failure());
  }
};

export default deleteClientNote;
