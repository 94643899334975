import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getClientStore = (state: RootState): any => state.clientStore;
const clientsSelectList = createSelector(getClientStore, (state) => state.clientsSelectList);
const getClientsListData = createSelector(getClientStore, (state) => state.clientsList );
const getClientDetailsData = createSelector(getClientStore, (state) => state.clientDetails || { clientData: {} });
const getClientNotesData = createSelector(getClientStore, (state) => state.clientNotes || {});
const getClientContactsData = createSelector(getClientStore, (state) => state.clientContacts || {});
const getClientNoteById = createSelector(getClientStore, (state) => state.clientSingleNote || {});
const getClientSingleContact = createSelector(getClientStore, (state) => state.clientSingleContact || {});
const getClientStats = createSelector(getClientStore, (state) => state.clientStats || []);

export { clientsSelectList, getClientsListData, getClientDetailsData, getClientNotesData, getClientContactsData, getClientNoteById, getClientSingleContact, getClientStats };
