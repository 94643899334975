import API from './Config';
import { AxiosPromise } from 'axios';

export function getCaseCounts(execType): AxiosPromise<any> {
  return API.get(`case/counts/${execType}`);
}

export function getClientCaseCounts(id): AxiosPromise<any> {
  return API.get(`case/counts/client/${id}`);
}

export function getCasesList(execType, params): AxiosPromise<any> {
  return API.get(`case/${execType}`, { params });
}

export function getClientCasesList(id, params): AxiosPromise<any> {
  return API.get(`case/client/${id}`, { params });
}

export function getCaseDetails(id): AxiosPromise<any> {
  return API.get(`case/${id}`);
}

export function getExternalCaseDetails(id): AxiosPromise<any> {
  return API.get(`case/${id}/external`);
}

export const createCaseStatus = (statusCreationDto): AxiosPromise<any> => {
  return API.post(`case/status`, { statusCreationDto });
};

export const updateCaseStatus = (statusUpdateDto): AxiosPromise<any> => {
  return API.patch(`case/status`, { statusUpdateDto });
};

export const editCase = (caseData): AxiosPromise<any> => {
  return API.put(`case`, caseData);
};

export const deleteCaseStatus = (statusId): AxiosPromise<any> => {
  return API.delete(`case/status/${statusId}`);
};

export const deleteCase = (caseId): AxiosPromise<any> => {
  return API.delete(`case/${caseId}`);
};

export const resumeCase = (caseId): AxiosPromise<any> => {
  return API.put(`case/${caseId}/resume`);
};

export const finishCase = (caseId): AxiosPromise<any> => {
  return API.put(`case/${caseId}/finish`);
};

export const addCase = (caseData): AxiosPromise<any> => {
  return API.post(`case`, caseData);
};

export const addCaseRemainder = (reminderData): AxiosPromise<any> => {
  return API.post(`reminder`, reminderData);
};

export function searchCase(params, headers): AxiosPromise<any> {
  params.caseType === '' && delete params.caseType;
  if (params.caseType === 'COURT') {
    delete params.caseType;
    return API.get(`case/finder/${headers}`, { params });
  } else {
    return API.get(`case/finder/`, { params });
  }
}

export function searchCaseByExternalUser(params, headers): AxiosPromise<any> {
  if (params.caseType === 'COURT') {
    delete params.caseType;
    return API.get(`case/finder/external${headers}`, { params });
  } else {
    return API.get(`case/finder/external`, { params });
  }
}

export function getFavouriteCases(): AxiosPromise<any> {
  return API.get(`case/favourite`);
}

export function manageFavourite(caseId, isFavourite): AxiosPromise<any> {
  return API.put(`case/${caseId}/favourite/${isFavourite}`);
}

export function getExternalUserCasesCounts(): AxiosPromise<any> {
  return API.get(`case/counts/external`);
}

export function getExternalUserCasesList(params): AxiosPromise<any> {
  return API.get(`case/external`, { params });
}

export function getCaseFilesList(caseId, params): AxiosPromise<any> {
  return API.get(`case/${caseId}/directory`, { params });
}

export function getCaseFilesListExternal(caseId, params): AxiosPromise<any> {
  return API.get(`case/${caseId}/directory/external`, { params });
}

export function getClientCaseXlsx(clientId, params): AxiosPromise<any> {
  return API.get(`/case/client/xlsx/${clientId}${params}`, { responseType: 'blob' });
}

export function getCaseFile(caseId, path): AxiosPromise<any> {
  return API.get(`case/${caseId}/file`, { responseType: 'blob', params: { caseId, path } });
}

export function getCaseFileExternal(caseId, path): AxiosPromise<any> {
  return API.get(`case/${caseId}/file/external`, { responseType: 'blob', params: { caseId, path } });
}

export function getStatusesForCase(caseId): AxiosPromise<any> {
  return API.get(`case/status/`, { params: { caseId } });
}

export const setElectronicOnly = (caseId, isElectronicOnly): AxiosPromise<any> => {
  return API.put(`/case/${caseId}/electronic/${isElectronicOnly}`);
};
