import { Client } from '../EnumTypes/ActionEnumTypes';
import { deleteClientContact as deleteClientContactConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
// import { ROUTES } from 'shared/consts';
// import { history } from 'Store/Reducers/RootReducer';
// import { store } from 'Store/Store';
import getClientContacts from './getClientContacts';

const init = () => ({
    type: Client.DELETE_CLIENT_CONTACT_INIT,
});

const success = () => ({
    type: Client.DELETE_CLIENT_CONTACT_SUCCESS,
});

const failure = () => ({
    type: Client.DELETE_CLIENT_CONTACT_FAILURE,
});

const deleteClientContact = (id, clientId) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await deleteClientContactConnector(id);
        dispatch(success());
        await dispatch(ViewManagementModule.Actions.hideConfirmPopup());
        await dispatch(getClientContacts(clientId));
        toast.success('Pomyślnie usunięto osobę kontaktową');
        dispatch(ViewManagementModule.Actions.hideLoader());
        // history.push(ROUTES.CUSTOMER_LIST);
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.error('Nie udało się usunąć osoby kontaktowej');
        dispatch(failure());
    }
};

export default deleteClientContact;
