import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Formik, Field } from 'formik';
import RegularField from '../Forms/Fields/RegularField';
import * as AuthModule from '../../Modules/AuthModule';
import * as Yup from 'yup';
import { RootState } from '../../Store/Reducers/RootReducer';
// import { Link } from 'react-router-dom';
import Logo from 'Assets/Img/logo.jpeg';
import LogoEntire from 'Assets/Img/logo_entire.jpeg';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';

type S = {};
type ConnectedP = {
  authorizedUser: AuthModule.Types.AuthorizedUserData;
};
type DispatchedP = {
  loginUser: (email: string, password: string) => void;
};
class Login extends React.Component<DispatchedP & ConnectedP, S> {
  constructor(props: ConnectedP & DispatchedP) {
    super(props);
  }

  public render() {
    // const { authorizedUser } = this.props;
    if (!localStorage.getItem('token')) {
      const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
        password: Yup.string().required('Pole wymagane'),
      });
      return (
        <div className="login">
          <div className="login__container">
            <div className="login__header">
              <div className="login__logo">
                <img src={Logo} alt="" />
              </div>
              <div className="login__logo-entire">
                <img src={LogoEntire} alt="" />
              </div>
            </div>
            <div className="login__form-container">
              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={LoginSchema}
                onSubmit={(values) => {
                  this.props.loginUser(values.email, values.password);
                }}>
                {({ handleSubmit, errors }) => (
                  <form onSubmit={handleSubmit} className="login__form">
                    <Field name="email" type="email" placeholder="Wpisz nazwę" label="Nazwa użytkownika" component={RegularField} errors={errors} isAutoComplete={true} />
                    <Field name="password" type="password" placeholder="Wpisz hasło" label="Hasło" component={RegularField} errors={errors} isAutoComplete={true} />
                    {/* <p className="login__forget-password">Zapomniałeś hasła?</p> */}
                    <button className="btn btn--default btn--login btn--center" type="submit">
                      Zaloguj
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to={'/dashboard'} />;
    }
  }
}

const mapDispatchToProps: DispatchedP = {
  loginUser: (email: string, password: string) => AuthModule.Actions.login(email, password),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    authorizedUser: AuthSelectors.authorizedUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
