import { CASES } from '../EnumTypes/ActionEnumTypes';
import { searchCase as searchCaseConnector, searchCaseByExternalUser as searchCaseByExternalUserConnector } from 'Connectors/CaseConnector';
import { authorizedUser } from '../../Selectors/AuthSelector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: CASES.SEARCH_CASE_INIT,
});

const success = (data) => ({
    type: CASES.SEARCH_CASE_SUCCESS,
    payload: {
        data,
    },
});

const failure = () => ({
    type: CASES.SEARCH_CASE_FAILURE,
});

const searchCase = (params) => async (dispatch, getState) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        const { external } = await authorizedUser(getState())
        if (external) {
            let headers = undefined;
            if(params.caseType === 'COURT') {
                headers = `?caseType=COURT&caseType=VINDICATION`
            }
            const { data } = await searchCaseByExternalUserConnector(params, headers);
            dispatch(success(data));
        } else {
            let headers = undefined;
            if(params.caseType === 'COURT') {
                headers = `?caseType=COURT&caseType=VINDICATION`
            }
            const { data } = await searchCaseConnector(params, headers);
            dispatch(success(data));
        }

        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default searchCase;