import * as React from 'react';
import RTable from 'react-table';

type ReactTableP = {
  columns: Array<any>;
  data: Array<any>;
  expanded?: any;
  SubComponent?: any;
  className?: string;
  filterable?: boolean;
  areTrProps?: boolean;
  activeRatingId?: any;
};

const ReactTable = ({ className = '', columns, data, expanded = {}, SubComponent, filterable, areTrProps, activeRatingId }: ReactTableP) => {
  return <RTable filterable={filterable} defaultPageSize={300} className={className} showPagination={false} sortable={true} minRows={1} previousText="Poprzednia" nextText="Następna" pageText="Strona" noDataText="Brak danych" ofText="z" rowsText="rzędów" loadingText="Ładowanie..." columns={columns} data={data} expanded={expanded} SubComponent={SubComponent} />;
};

export default ReactTable;
