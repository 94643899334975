import * as React from 'react';
import { connect } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
// import { Formik, Field } from 'formik';
// import RegularField from '../Forms/Fields/RegularField';
import * as AuthModule from '../../../Modules/AuthModule';
import { ExternalPagination } from 'shared/components';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from '../../../Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import ReactTooltip from 'react-tooltip';
import { MainType } from './ContainerFavoriteCustomersList';

type S = {
  filters: any;
};

type ConnectedP = {
  isMenuExpanded: boolean;
  authorizedUser: AuthModule.Types.AuthorizedUserData;
};

type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
};
class FavoriteCustomersList extends React.Component<DispatchedP & ConnectedP & MainType, S> {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        size: JSON.parse(sessionStorage.getItem('pageSize'))?.value ?? 10,
        page: 0,
        favourite: true,
      },
    };
  }

  async componentDidMount() {
    await this.props.actions.getClientsList(this.state.filters);
  }
  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  onSizeChange = (size) => {
    this.setState({ filters: { ...this.state.filters, size } }, () => this.props.actions.getClientsList({ ...this.state.filters, size }));
  };
  onPageChange = (page) => this.setState({ filters: { ...this.state.filters, page } }, () => this.props.actions.getClientsList({ ...this.state.filters, page }));

  redirectToEditCustomer = (data) => history.push(ROUTES.EDIT_CUSTOMER, data);

  handleManageFavourite = async (client) => {
    const { manageFavourite, getClientsList } = this.props.actions;

    await manageFavourite(client.id, !client.favourite);
    await getClientsList(this.state.filters);
  };

  public render() {
    const { clientsListData } = this.props;
    return (
      <div className="favorite-customers-list">
        <div className="favorite-customers-list__header">
          <div className="favorite-customers-list__title">
            <Link to="/dashboard">
              <img src={GrayArrow} alt="" />
            </Link>
            <h2>Ulubieni klienci</h2>
          </div>
        </div>
        {clientsListData && <ExternalPagination size={clientsListData.size} pages={clientsListData.totalPages} page={0} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />}
        <div className="favorite-customers-list__table">
          <table className="table">
            <thead>
              <tr>
                <th className="actions">Akcje</th>
                <th>
                  Nr klienta <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Nazwisko/Nazwa firmy
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Typ
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  NIP
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Adres
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Kod pocztowy
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Miasto
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Telefon
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Email
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Litera porządkowa
                  <br />
                  <input type="text" name="" id="" />
                </th>
              </tr>
            </thead>
            <tbody>
              {clientsListData &&
                clientsListData.content.map((customer: any, idx: number) => {
                  const { id, company, type, nip, address, postalCode, city, phone, email, letter, favourite } = customer;
                  return (
                    <>
                      <ReactTooltip place="top" effect="solid" className="tooltip" />
                      <tr className="hoverable" key={idx}>
                        <td className="actions">
                          <button className="btn-action btn-action--search" data-tip="Karta klienta" onClick={() => history.push(generatePath(ROUTES.CUSTOMER_CARD, { id }))}></button>
                          <button className="btn-action btn-action--open" data-tip="Lista spraw klienta" onClick={() => history.push(generatePath(ROUTES.CASES_LIST_CUSTOMER, { id }))}></button>
                          <button className={`btn-action btn-action--${favourite ? 'favourite-selected' : 'favourite'}`} data-tip={favourite ? 'Usuń z ulubionych' : `Dodaj do ulubionych`} onClick={() => this.handleManageFavourite(customer)}></button>
                          <button className="btn-action btn-action--edit" data-tip="Edytuj" onClick={() => this.redirectToEditCustomer(customer)}></button>
                          {/* <button className="btn-action btn-action--search" data-tip="Karta klienta"></button>
                          <button className="btn-action btn-action--open" data-tip="Lista spraw klienta"></button>
                          <button className="btn-action btn-action--edit" data-tip="Edytuj"></button>
                          <button className="btn-action btn-action--favourite" data-tip="Usuń z ulubionych"></button> */}
                        </td>
                        <td> {id}</td>
                        <td className="case">{company}</td>
                        <td>{type}</td>
                        <td>{nip}</td>
                        <td>{address}</td>
                        <td>{postalCode}</td>
                        <td>{city}</td>
                        <td>{phone}</td>
                        <td><a className='email' href={`mailto:${email}`}>{email}</a></td>
                        <td>{letter}</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    authorizedUser: AuthSelectors.authorizedUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteCustomersList);
