import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addCase, editCase, getCaseDetails } from 'Store/Actions/cases';
import * as UserModule from 'Modules/UserModule';
import * as CaseSelector from 'Store/Selectors/CaseSelector';

type ConnectedP = {
    internalUserOptions: any;
    caseData: any;
};
type DispatchedP = {
    actions: {
        addCase: (caseData) => void;
        editCase: (caseData) => void;
        getInternalUserOptions: () => void;
        getCaseDetails: (id: string) => void;
    };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
    actions: bindActionCreators(
        {
            addCase: (caseData) => addCase(caseData),
            getInternalUserOptions: () => UserModule.Actions.getInternalUserOptions(),
            getCaseDetails: (id) => getCaseDetails(id),
            editCase: (caseData) => editCase(caseData),
        },
        dispatch,
    ),
});

function mapStateToProps(state): ConnectedP {
    return {
        internalUserOptions: UserModule.Selectors.getInternalUserOptions(state),
        caseData: CaseSelector.getCaseDetails(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
