import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getGroupStore = (state: RootState) => state.groupStore;
const getGroupData = createSelector(getGroupStore, ({ group: { data } }) => data);
const getCrmSettingsData = createSelector(getGroupStore, ({ crm: { data = {} } }) => data);
const getSingleGroupData = createSelector(getGroupStore, ({ singleGroup = {} }) => singleGroup);
const getPrivilegesData = createSelector(getGroupStore, ({ privileges = {} }) => privileges);
const getGroupUsers = createSelector(getGroupStore, ({ groupUsers = [] }) => groupUsers);

export { getGroupData, getCrmSettingsData, getSingleGroupData, getPrivilegesData, getGroupUsers };
