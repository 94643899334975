import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addCase } from 'Store/Actions/cases';
import * as UserModule from 'Modules/UserModule';

type ConnectedP = { internalUserOptions: any };
type DispatchedP = {
  actions: {
    addCase: (caseData) => void;
    getInternalUserOptions: () => void;
  };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      addCase: (caseData) => addCase(caseData),
      getInternalUserOptions: () => UserModule.Actions.getInternalUserOptions(),
    },
    dispatch,
  ),
});

function mapStateToProps(state): ConnectedP {
  return {
    internalUserOptions: UserModule.Selectors.getInternalUserOptions(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
