import * as React from 'react';
import { connect } from 'react-redux';
import { history, RootState } from 'Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors, Actions as ViewManagemenetActions } from 'Modules/ViewManagementModule';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import ReactTooltip from 'react-tooltip';
import * as UserModule from '../../../../Modules/UserModule';
import { Selectors as UserSelectors } from 'Modules/UserModule';
import Pagination from '../../../Pagination';

type S = {};
type P = {
  match: any;
};
type ConnectedP = {
  prevPath: string;
  externalUsersList: UserModule.Types.ExternalUsers;
};
type DispatchedP = {
  getExternalUsers: (page: any, pageSize?: string) => void;
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => void;
};
class ExternalUsersList extends React.Component<DispatchedP & ConnectedP & P, S> {
  async componentDidMount() {
    let { page } = this.props.match.params;
    const { externalUsersList } = this.props;
    page = page - 1;
    await this.props.getExternalUsers(page && (page >= 0 || page <= externalUsersList.totalPages) ? page : 0, sessionStorage.getItem('pageSize') ? JSON.parse(sessionStorage.getItem('pageSize')).value : '10');
    // await this.props.getInternalUsers();
  }

  public render() {
    const { externalUsersList } = this.props;
    return (
      <div className="external-users-list">
        <div className="external-users-list__header">
          <div className="external-users-list__title">
            <Link to={'/dashboard/settings'}>
              <img src={GrayArrow} alt="" />
            </Link>
            <h2>Lista użytkowników zewnętrznych</h2>
          </div>
          {externalUsersList && <Pagination pages={externalUsersList.totalPages} getFunction={this.props.getExternalUsers} path="/dashboard/settings/external-users/" />}
          <div className="external-users-list__add-button">
            <button className="btn btn--default" onClick={() => history.push('/dashboard/settings/add-external-user')}>
              <span>&#43;</span> Dodaj użytkownika
            </button>
          </div>
        </div>
        <div className="external-users-list__table">
          <table className="table">
            <thead className="filled-thead">
              <tr>
                <th>Id</th>
                <th>Imię</th>
                <th>Nazwisko</th>
                <th>Nazwa użytkownika</th>
                <th>Email</th>
                <th>Telefon</th>
                <th>Ostatnie logowanie</th>
                <th className="actions">Narzędzia</th>
              </tr>
            </thead>
            <tbody>
              {externalUsersList &&
                externalUsersList.content.map((externalUser: UserModule.Types.SingleExternalUser, idx: number) => {
                  const { id, firstName, lastName, email, userName, lastLoginDate, phone } = externalUser;
                  return (
                    <>
                      <ReactTooltip place="top" effect="solid" className="tooltip" />
                      <tr className="hoverable" key={idx}>
                        <td>{id}</td>
                        {/* <td className="case">{name}</td> */}
                        <td>{firstName}</td>
                        <td>{lastName}</td>
                        <td>{userName}</td>
                        <td>{email}</td>
                        <td>{phone}</td>
                        <td>{lastLoginDate}</td>
                        <td className="actions">
                          <button
                            className="btn-action btn-action--edit"
                            data-tip="Edytuj"
                            onClick={() => {
                              history.push(`/dashboard/settings/edit-external-user/${id}`);
                            }}
                          />
                          <button
                            className="btn-action btn-action--open"
                            data-tip="Zmień hasło"
                            onClick={() => {
                              history.push(`/dashboard/settings/change-external-user-password/${id}`);
                            }}
                          />
                          <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => this.props.showConfirmPopup('DeleteExternalUser', externalUser)} />
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  getExternalUsers: (page: any, pageSize?: string) => UserModule.Actions.getExternalUsers(page, pageSize),
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => ViewManagemenetActions.showConfirmPopup(typeConfirmPopup, dataToEdit),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    prevPath: ViewManagementSelectors.prevPath(state),
    externalUsersList: UserSelectors.externalUsersList(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalUsersList);
