import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getCaseStore = (state: RootState) => state.casesStore;
const getCasesCounts = createSelector(getCaseStore, ({ casesCounts }) => casesCounts);
const getCasesList = createSelector(getCaseStore, ({ casesList }) => casesList);
const getFavouriteCases = createSelector(getCaseStore, ({ favouriteCasesList }) => favouriteCasesList?.favouriteCases);
const getCaseDetails = createSelector(getCaseStore, ({ caseDetails }) => caseDetails);
const getSearchedCasesList = createSelector(getCaseStore, ({ searchedCasesList = {} }) => searchedCasesList);
const getExternalUserCasesCounts = createSelector(getCaseStore, ({ externalUserCasesCounts = [] }) => externalUserCasesCounts);
const getExternalUserCasesList = createSelector(getCaseStore, ({ externalUserCasesList }) => externalUserCasesList);
const getCaseFiles = createSelector(getCaseStore, ({ files }) => files);

export { getCasesCounts, getCasesList, getCaseDetails, getSearchedCasesList, getFavouriteCases, getExternalUserCasesCounts, getExternalUserCasesList, getCaseFiles };
