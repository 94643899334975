import { Reminder } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/ReminderModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
// import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
    type: Reminder.POSTPONE_REMINDER_INIT,
});

const success = () => ({
    type: Reminder.POSTPONE_REMINDER_SUCCESS,
});

const failure = () => ({
    type: Reminder.POSTPONE_REMINDER_FAILURE,
});

const postponeReminder = (reminderData) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await Connector.postponeReminder(reminderData);
        dispatch(success());
        toast.success('Pomyślnie edytowano przypomnienie');
        dispatch(ViewManagementModule.Actions.hideLoader());
        // history.push(ROUTES.REMAINDER_LIST);
        history.goBack();
    } catch (err) {
        console.log(err, 'error')
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.error('Nie udało się edytować przypomnienia');
        dispatch(failure());
    }
};

export default postponeReminder;
