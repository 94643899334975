import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addCaseStatusDescription, editCaseStatusDescription } from 'Store/Actions/cases';

type ConnectedP = {};
type DispatchedP = {
  actions: {
    addCaseDescription: (caseStatusDescriptionData, caseId) => void;
    editCaseDescription: (editCaseStatusDescriptionData, caseId) => void;
  };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      addCaseDescription: (caseStatusDescriptionData, caseId) => addCaseStatusDescription(caseStatusDescriptionData, caseId),
      editCaseDescription: (editCaseStatusDescriptionData, caseId) => editCaseStatusDescription(editCaseStatusDescriptionData, caseId),
    },
    dispatch,
  ),
});

function mapStateToProps(state): ConnectedP {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
