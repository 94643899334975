import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

type ProtectedRouteProps = {
  component: any;
  path: string;
  exact: boolean;
  isPermissionGranted: boolean;
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { component: Component, exact, isPermissionGranted, ...rest } = props;

  return (
    <Route
      {...rest}
      exact={exact}
      render={(routeProps) =>
        isPermissionGranted ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
