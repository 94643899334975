import { Client } from '../EnumTypes/ActionEnumTypes';
import { getClientContacts as getClientContactsConnector } from 'Connectors/ClientConnector';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: Client.GET_CLIENT_CONTACTS_INIT,
});

const success = (clientContacts) => ({
    type: Client.GET_CLIENT_CONTACTS_SUCCESS,
    payload: {
        clientContacts,
    },
});

const failure = () => ({
    type: Client.GET_CLIENT_CONTACTS_FAILURE,
});

const getClientContacts = (id) => async (dispatch) => {
    dispatch(init());
    try {
        // dispatch(ViewManagementModule.Actions.showLoader());
        const { data } = await getClientContactsConnector(id);
        dispatch(success(data));
        // dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        // dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getClientContacts;
