import * as React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import RegularField from 'Components/Forms/Fields/RegularField';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import mapGroupToOptions from 'Helpers/mapGroupToOptions';
import { MainType } from './';

type S = {};
type P = {
  history: any;
};
const AddCustomerSchema = Yup.object().shape({
  firstName: Yup.string().required('Pole wymagane'),
  lastName: Yup.string().required('Pole wymagane'),
  groupId: Yup.string().required('Pole wymagane'),
  password: Yup.string().required('Pole wymagane'),
  userName: Yup.string().required('Pole wymagane'),
  email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
});

export default class AddInternalUser extends React.Component<MainType & P, S> {
  async componentDidMount() {
    await this.props.getGroup();
  }

  onSubmit = async (values) => {
    await this.props.addInternalUser({
      userCreationDto: { ...values },
    });
    this.props.history.push('/dashboard/settings/internal-users');
  };

  public render() {
    const { prevPath, group } = this.props;
    return (
      <div className="add-customer">
        <div className="add-customer__title">
          <Link to={prevPath ?? '/dashboard'}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Dodaj Użytkownika</h2>
        </div>
        <div className="add-customer__form-container">
          <Formik initialValues={{ firstName: '', lastName: '', email: '', groupId: '', password: '', userName: '' }} validationSchema={AddCustomerSchema} onSubmit={this.onSubmit}>
            {({ handleSubmit, errors, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="add-customer__form">
                <Field name="firstName" type="text" wrapperClassName="default" placeholder="Wpisz imię" label="Imię" component={RegularField} errors={errors} />
                <Field name="lastName" type="text" wrapperClassName="default" placeholder="Wpisz nazwisko" label="Nazwisko" component={RegularField} errors={errors} />
                <Field name="userName" type="text" wrapperClassName="default" placeholder="Wpisz nazwę użytkownika" label="Nazwa użytkownika" component={RegularField} errors={errors} />
                <Field name="groupId" type="select" placeholder="Wybierz grupę" label="Grupa" options={mapGroupToOptions(group)} component={RegularField} setFieldValue={setFieldValue} errors={errors} />
                <Field name="email" type="text" wrapperClassName="default" placeholder="Wpisz adres email" label="Email" component={RegularField} errors={errors} isAutoComplete={false} />
                <Field name="password" type="password" wrapperClassName="default" placeholder="Wpisz hasło" label="Hasło" component={RegularField} errors={errors} isAutoComplete={false} />
                <button className="btn btn--default btn--login btn--center" type="submit">
                  Dodaj użytkownika
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
