import * as React from 'react';
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
import RegularField from 'Components/Forms/Fields/RegularField';
import Privileges from 'Components/Forms/Fields/Privileges';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { MainType } from './Container';
import * as Yup from 'yup';
import { ROUTES } from 'shared/consts';
import { mapPrivilegesToIds } from 'shared/utils';

type S = {};
type P = {
  history: any;
  match: any;
};

const MANAGE_GROUP_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Pole wymagane'),
  description: Yup.string().required('Pole wymagane'),
});
export default class Main extends React.Component<MainType & P, S> {
  async componentDidMount() {
    if (!this.isAddType()) {
      await this.props.actions.getGroupById(this.props.match.params?.id);
    } else {
      await this.props.actions.getPrivileges();
    }
  }

  isAddType = () => this.props.match.params?.id === 'add';

  onSubmit = async ({ description, name, privileges }) => {
    const isAdd = this.isAddType();
    const group = isAdd ? { description, name } : { description, name, id: this.props.groupData.groupDto.id };
    const groupData = { [isAdd ? 'groupCreationDto' : 'groupDto']: { ...group }, privilegeIds: mapPrivilegesToIds(privileges) };
    isAdd ? this.props.actions.addGroup(groupData, this.props.history) : this.props.actions.editGroup(groupData, this.props.history);
  };

  public render() {
    const { privileges = {} } = this.props;
    const { privileges: groupPrivileges = {}, groupDto = {} } = this.props.groupData;
    const { name: groupName, description } = groupDto;
    const options = this.props.match.params?.id !== 'add' ? groupPrivileges : privileges;
    const initial = this.isAddType() ? { privileges: options, name: '', description: '' } : { privileges: options, name: groupName, description };
    return (
      <div className="add-customer">
        <div className="add-customer__title">
          <Link to={ROUTES.INTERNAL_USERS_GROUPS}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>{this.props.match.params?.id === 'add' ? 'Dodaj' : 'Edytuj'} grupę</h2>
        </div>
        <div className="add-customer__form-container">
          <Formik validationSchema={MANAGE_GROUP_SCHEMA} enableReinitialize initialValues={initial} onSubmit={this.onSubmit}>
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} className="add-customer__form">
                <Field name="name" type="text" wrapperClassName="default" label="Skrócona nazwa grupy" component={RegularField} errors={errors} />
                <Field name="description" type="textarea" wrapperClassName="default" label="Opis" component={RegularField} errors={errors} />
                <Field name="privileges" wrapperClassName="default" label="Uprawnienia" component={Privileges} errors={errors} isAutoComplete={false} />
                <button className="btn btn--default btn--login" type="submit">
                  Zapisz
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
