import * as React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ closePopup, onSubmit, title = 'Jesteś pewien?', description = 'Czy chcesz usunąć te dane?' }: any) => {
  const handleChildClick = (e: any) => {
    e.stopPropagation();
  };
  return (
    <div className="confirmation-popup" onClick={closePopup}>
      <div className={`confirmation-popup__inner`} onClick={handleChildClick}>
        <div className="confirmation-popup__content">
          <h2>{title}</h2>
          <h6>{description}</h6>
          <div className="confirmation-popup__buttons">
            <button className={`btn btn--cancel`} onClick={closePopup}>
              Anuluj
            </button>
            <button className={`btn btn--confirm`} onClick={onSubmit}>
              Potwierdź
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default (props: any) => ReactDOM.createPortal(<Modal {...props} />, document.querySelector('#root'));
