import * as React from 'react';
import { connect } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';
import * as AuthModule from '../../../../Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as ReminderModule from 'Modules/ReminderModule';
import * as UserModule from 'Modules/UserModule';
import * as Yup from 'yup';
import { RootState } from '../../../../Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import { Link } from 'react-router-dom';
// import ReactTransitionGroup from 'react-addons-transition-group';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';

type S = {};
type ConnectedP = {
  isMenuExpanded: boolean;
  isAddReminderView: boolean;
  authorizedUser: AuthModule.Types.AuthorizedUserData;
  internalUserOptions: any;
};
type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
  addReminder: (reminderGroup) => void;
  getInternalUserOptions: () => void;
};
class AddReminder extends React.Component<DispatchedP & ConnectedP, S> {
  componentDidMount() {
    this.props.getInternalUserOptions();
  }

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };
  public render() {
    const { internalUserOptions } = this.props;

    const AddReminderSchema = Yup.object().shape({
      remindAt: Yup.string().required('Pole wymagane'),
      about: Yup.string().required('Pole wymagane'),
      toUserId: Yup.string().required('Pole wymagane'),
    });

    return (
      <div className="add-reminder">
        <div className="add-reminder__title">
          <Link to="/dashboard">
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Dodaj przypomnienie</h2>
        </div>
        <div className="add-reminder__form-container">
          <Formik
            initialValues={{ remindAt: '', about: '', toUserId: '' }}
            validationSchema={AddReminderSchema}
            onSubmit={async (values) => {
              console.log(values, 'values');
              await this.props.addReminder({ reminderCreationDto: { ...values } });
              //   this.props.loginUser(values.email, values.password);
            }}>
            {({ handleSubmit, errors, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="add-reminder__form">
                <Field name="remindAt" type="date" wrapperClassName="default" placeholder="Podaj datę" label="Data" component={RegularField} errors={errors} />
                <Field name="about" type="textarea" wrapperClassName="default" placeholder="Wpisz treść przypomnienia" label="Treść przypomnienia" component={RegularField} errors={errors} />
                <Field name="toUserId" type="select" placeholder="Wybierz osobę" label="Przypisany do" options={internalUserOptions} component={RegularField} setFieldValue={setFieldValue} errors={errors} />
                {/* <p className="login__forget-password">Zapomniałeś hasła?</p> */}
                <button className="btn btn--default btn--login btn--center" type="submit">
                  Dodaj przypomnienie
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  addReminder: (reminderData) => ReminderModule.Actions.addReminder(reminderData),
  getInternalUserOptions: () => UserModule.Actions.getInternalUserOptions(),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    isAddReminderView: ViewManagementSelectors.isAddReminderView(state),
    authorizedUser: AuthSelectors.authorizedUser(state),
    internalUserOptions: UserModule.Selectors.getInternalUserOptions(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddReminder);
