import * as React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
// import { generatePath } from 'react-router';
import { MainType } from './Container';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { ROUTES } from 'shared/consts';
import BasicData from './components/basicData';
import DetailedData from './components/detailedData';
import * as Yup from 'yup';
// import { STEP_TITLE, STEP_BUTTON_LABEL, VALIDATION_SCHEMA } from './consts';
import { STEP_BUTTON_LABEL } from './consts';
// import { STEP_BUTTON_LABEL, CASE_TYPES } from './consts';

type P = {
  location: any;
  match: any;
};

class Main extends React.Component<MainType & P, any> {
  constructor(props: MainType & P) {
    super(props);
    this.state = {
      numberOfClients: 0,
      clients: [],
      step: 0,
      caseType: '',
    };
  }

  componentDidMount() {
    this.props.actions.getInternalUserOptions();
  }

  handleSubmit = async (values) => {
    console.log(values, 'wartości dodawania sprawy');

    if (this.state.step === 0) {
      this.setState({ step: 1, caseType: values.caseType });
    } else {
      // this.setState({ step: 0 });
      this.props.actions.addCase(values);
    }
  };

  scrollInto = () => {
    setTimeout(()=> {
    const element = document.getElementById('name');
    element && element.scrollIntoView({behavior: "smooth", block: "center"});
    }, 100)

  }

  handleGoBack = () => this.setState({ step: 0 });

  addCaseSchema = () => {
    const { step } = this.state;
    // const { caseType, step } = this.state;

    return Yup.object().shape({
      clientId: Yup.string().required('Pole wymagane'),
      // ordering: Yup.string().required('Pole wymagane'),
      executive1Id: Yup.string().required('Pole wymagane'),
      executive2Id: Yup.string().required('Pole wymagane'),
      caseType: Yup.string().required('Pole wymagane'),
      ...(step === 1 && {
        name: Yup.string().required('Pole wymagane'),
        // tags: Yup.string().required('Pole wymagane'),
      }),
      // ...(caseType === CASE_TYPES.VARIOUS && {
      //   details: Yup.string().required('Pole wymagane'),
      // }),
      // ...((caseType === CASE_TYPES.COURT || caseType === CASE_TYPES.VINDICATION) && {
      //   plaintiff: Yup.string().required('Pole wymagane'),
      //   defendant: Yup.string().required('Pole wymagane'),
      //   wps: Yup.string().required('Pole wymagane'),
      //   signature: Yup.string().required('Pole wymagane'),
      //   kind: Yup.string().required('Pole wymagane'),
      // }),
      // ...(caseType === CASE_TYPES.CONTRACT && {
      //   contractor: Yup.string().required('Pole wymagane'),
      //   about: Yup.string().required('Pole wymagane'),
      //   kind: Yup.string().required('Pole wymagane'),
      // }),
      // ...(caseType === CASE_TYPES.OTHER && {
      //   details: Yup.string().required('Pole wymagane'),
      //   sign1: Yup.string().required('Pole wymagane'),
      //   sign2: Yup.string().required('Pole wymagane'),
      //   kind: Yup.string().required('Pole wymagane'),
      // }),
    });
  };

  render() {
    // const { params } = this.props.match;
    // const { caseNo } = this.props.location.state;
    const { internalUserOptions } = this.props;
    const initialValues = {
      clientId: '',
      ordering: '',
      contractor: '',
      executive1Id: '',
      executive2Id: '',
      caseType: '',
      name: '',
      tags: '',
      details: '',
      plaintiff: '',
      defendant: '',
      wps: '',
      kind: '',
      signature: '',
      sign1: '',
      sign2: '',
      about: '',
    };

    const { step } = this.state;
    return (
      // <div className="addCaseRemainder narrow-section">
      //   <div className="narrow-section__title">
      //     {/* <Link to={generatePath(ROUTES.CASE_DETAILS, { id: params.id })}> */}
      //     <Link to={generatePath(ROUTES.CASES_LIST_CONTRACTOR)}>
      //       <img src={GrayArrow} alt="" />
      //     </Link>
      //     <h2>Dodaj sprawę</h2>
      //   </div>
      //   <div className="add-customer__form-container">
      <Formik initialValues={initialValues} validationSchema={this.addCaseSchema} enableReinitialize={true} onSubmit={this.handleSubmit}>
        {({ handleSubmit, errors, setFieldValue, values: { caseType }, validateOnBlur }) => (
          <div className="add-customer--details__wrapper">
            <div className="add-customer add-customer--details">
              <div className="add-customer--details__inner">
                <div className="add-customer__title">
                  <Link to={ROUTES.CASES_LIST_CONTRACTOR}>
                    <img src={GrayArrow} alt="" />
                  </Link>
                  {/* <h2>{STEP_TITLE[step]}</h2> */}
                  <h2>Dodaj sprawę</h2>
                </div>
                <div className="add-customer__form-container">
                  <form onSubmit={handleSubmit} className="add-customer__form">
                    {step === 0 && <BasicData errors={errors} setFieldValue={setFieldValue} internalUserOptions={internalUserOptions} />}
                    {step === 1 && (
                      <>
                        <DetailedData errors={errors} setFieldValue={setFieldValue} caseType={caseType} />
                        {/* <button className="btn btn--default btn--login btn--center" onClick={this.handleGoBack}>
                          Cofnij
                        </button> */}
                      </>
                    )}
                    {/* {step === 1 && <AddClients values={values} validateOnBlur={validateOnBlur} errors={errors} setFieldValue={setFieldValue} isClientSelected={this.isClientSelected} numberOfClients={this.state.numberOfClients} />} */}

                    <button className="btn btn--default btn--login btn--center" type="submit" onClick={()=> this.scrollInto()}>
                      {STEP_BUTTON_LABEL[step]}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
      // {/* <AddCaseRemainderForm onSubmit={this.handleSubmit} caseNo={''} /> */}
      //   </div>
      // </div>
    );
  }
}

export default Main;
