import ExternalUserCasesList from './ExternalUserCasesList';
import { connect } from 'react-redux';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { getCaseCounts, getCasesList, getClientCasesList, getClientCaseCounts, manageFavourite, getExternalUserCasesCounts, getExternalUserCasesList } from 'Store/Actions/cases';
import * as CaseSelector from 'Store/Selectors/CaseSelector';

type ConnectedP = {
  isMenuExpanded: boolean;
  isAddReminderView: boolean;
  prevPath: string;
  caseCounts: any;
  externalUserCasesCounts: any;
  externalUserCasesList: any;
  casesList: Array<any>;
};
type DispatchedP = {
  expandMenu: () => void;
  getExternalUserCasesCounts: () => void;
  getCaseCounts: (execType: string) => void;
  getExternalUserCasesList: (params: any) => void;
  getCasesList: (execType: string, params: any) => void;
  getClientCasesList: (id: number, params: any) => void;
  getClientCaseCounts: (id: number) => void;
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => void;
  manageFavourite: (caseId: number, isFavourite: boolean) => void;
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps: DispatchedP = {
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  getExternalUserCasesCounts: () => getExternalUserCasesCounts(),
  getExternalUserCasesList: (params: any) => getExternalUserCasesList(params),
  getCaseCounts: (execType) => getCaseCounts(execType),
  getCasesList: (execType, params) => getCasesList(execType, params),
  getClientCasesList: (id, params) => getClientCasesList(id, params),
  getClientCaseCounts: (id) => getClientCaseCounts(id),
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => ViewManagementModule.Actions.showConfirmPopup(typeConfirmPopup, dataToEdit),
  manageFavourite: (caseId: number, isFavourite: boolean) => manageFavourite(caseId, isFavourite),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    isAddReminderView: ViewManagementSelectors.isAddReminderView(state),
    prevPath: ViewManagementSelectors.prevPath(state),
    caseCounts: CaseSelector.getCasesCounts(state),
    casesList: CaseSelector.getCasesList(state),
    externalUserCasesCounts: CaseSelector.getExternalUserCasesCounts(state),
    externalUserCasesList: CaseSelector.getExternalUserCasesList(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalUserCasesList);
