import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: User.EDIT_EXTERNAL_USER_INIT,
});

const success = () => ({
  type: User.EDIT_EXTERNAL_USER_SUCCESS,
});

const failure = () => ({
  type: User.EDIT_EXTERNAL_USER_FAILURE,
});

const editExternalUser = (userdata) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await Connector.editExternalUser(userdata);
    dispatch(success());
    toast.success('Pomyślnie zaktualizowano UZ');
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(ROUTES.EXTERNAL_USERS);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się zaktualizowano UZ');
    dispatch(failure());
  }
};

export default editExternalUser;
