import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Formik, Field } from 'formik';
import RegularField from '../Forms/Fields/RegularField';
import * as AuthModule from '../../Modules/AuthModule';
import * as UserModule from '../../Modules/UserModule';
import * as LimitsModule from '../../Modules/LimitsModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from '../../Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import Logo from 'Assets/Img/logo.jpeg';
import LogooutIcon from 'Assets/Img/Icons/logout-icon.svg';
import SearchIcon from 'Assets/Img/Icons/search-icon.svg';
import FacvoriteIcon from 'Assets/Img/Icons/favorite-icon.svg';
import { history } from 'Store/Reducers/RootReducer';
import { ROUTES, PRIVILEGES } from 'shared/consts';
import { checkPrivilegeAccess } from 'shared/utils';
import { searchCase } from 'Store/Actions/cases';

type S = {};
type ConnectedP = {
  isMenuExpanded: boolean;
  authorizedUser: AuthModule.Types.AuthorizedUserData;
  sidebarCounts: any;
  clientsReachingLimitsCount: any;
};
type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
  searchCase: (params) => void;
  getSidebarCounts: () => void;
  getClientsReachingLimitsCount: () => void;
};

class Navigation extends React.Component<DispatchedP & ConnectedP, S> {
  async componentDidMount() {
    await this.props.getSidebarCounts();
    await this.props.getClientsReachingLimitsCount();
  }

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  handleClientSearch = (value) => {
    history.push({
      pathname: ROUTES.CUSTOMER_LIST,
      search: `?companyNameOrId=${value}`,
    });
  };

  handleCaseSearch = async (value) => {
    console.log(value, 'valiue');
    history.push({
      pathname: ROUTES.SEARCH_ENGINE,
      state: { isSidebarSearch: true, caseNumberOrSignature: value },
    });
    await this.props.searchCase({ caseNumberOrSignature: value, pageSize: 50, pageNo: 0 });
  };

  public render() {
    const {
      isMenuExpanded,
      sidebarCounts,
      clientsReachingLimitsCount,
      authorizedUser: { firstName, lastName, external, admin },
    } = this.props;

    const isAddClientAccess = checkPrivilegeAccess(PRIVILEGES.CLIENT_ADD);
    const isStatsAccess = checkPrivilegeAccess(PRIVILEGES.STATS_VIEW);
    const isAddCaseAccess = checkPrivilegeAccess(PRIVILEGES.CASE_ADD);
    return (
      <div className={`navigation navigation__menu ${isMenuExpanded ? 'navigation__menu--expanded' : ''}`}>
        {isMenuExpanded && (
          <ul className="navigation__menu--mobile">
            <Formik
              initialValues={{ clientName: '', caseNumberOrSignature: '' }}
              onSubmit={(values, { resetForm }: any) => {
                resetForm({ values: '' });
              }}>
              {({ handleSubmit, errors, values }) => (
                <form onSubmit={handleSubmit} className="navigation__form">
                  {!external && (
                    <>
                      <Field name="clientName" type="text" wrapperClassName="default" icon={SearchIcon} placeholder="Szukaj klienta po nazwie lub nr klienta" component={RegularField} errors={errors} />
                      {values.clientName !== '' && (
                        <button className="btn btn--default btn--center" onClick={() => this.handleClientSearch(values.clientName)}>
                          Wyszukaj
                        </button>
                      )}
                    </>
                  )}
                  <Field name="caseNumberOrSignature" type="text" wrapperClassName="default" icon={SearchIcon} placeholder="Szukaj po sygnaturze lub nr sprawy" component={RegularField} errors={errors} />
                  {values.caseNumberOrSignature !== '' && (
                    <button className="btn btn--default btn--center" onClick={() => this.handleCaseSearch(values.caseNumberOrSignature)}>
                      Wyszukaj
                    </button>
                  )}
                </form>
              )}
            </Formik>
            {external ? (
              <>
                <div className="navigation__list-title">
                  <div className="list-title">
                    {/* <div className="list-title__counter"> 132</div> */}
                    Sprawy
                  </div>
                </div>
                <NavLink to={ROUTES.EXTERNAL_USER_CASES} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">
                    {/* <div className="list__counter"> 92</div> */}
                    Moje sprawy
                  </li>
                </NavLink>
                <NavLink to={ROUTES.FAVOURITE_CASES} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">
                    {/* <div className="list__counter"> 10</div> */}
                    <img className="list__icon" src={FacvoriteIcon} alt="" />
                    Ulubione sprawy
                  </li>
                </NavLink>
                <div className="navigation__list-title" style={{ marginTop: '40px' }}>
                  <div className="list-title">Profil</div>
                </div>
                <NavLink to={'/dashboard/search-engine'} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">Wyszukiwarka</li>
                </NavLink>
              </>
            ) : (
              <>
                <div className="navigation__list-title">
                  <div className="list-title">
                    <div className="list-title__counter">{''}</div>
                    Klienci
                  </div>
                  {isAddClientAccess && (
                    <button className="btn btn--navigation" onClick={() => history.push('/dashboard/add-customer')}>
                      <span>&#43;</span> Dodaj
                    </button>
                  )}
                </div>
                <NavLink to={'/dashboard/favorite-customers-list'} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">
                    <div className="list__counter">{sidebarCounts && sidebarCounts.clientsFavourite}</div>
                    <img className="list__icon" src={FacvoriteIcon} alt="" />
                    Klienci
                  </li>
                </NavLink>
                <NavLink to={'/dashboard/customers-list'} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">
                    <div className="list__counter">{sidebarCounts && sidebarCounts.clientsTotal}</div>
                    Klienci
                  </li>
                </NavLink>
                <div className="navigation__list-title" style={{ marginTop: '40px' }}>
                  <div className="list-title">
                    <div className="list-title__counter">{sidebarCounts && sidebarCounts.casesTotal}</div>
                    Sprawy
                  </div>
                  {isAddCaseAccess && (
                    <button className="btn btn--navigation" onClick={() => history.push(ROUTES.ADD_CASE)}>
                      <span>&#43;</span> Dodaj
                    </button>
                  )}
                </div>
                <NavLink to={'/dashboard/favourite-cases'} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">
                    <div className="list__counter">{sidebarCounts && sidebarCounts.casesFavourite}</div>
                    <img className="list__icon" src={FacvoriteIcon} alt="" />
                    Sprawy
                  </li>
                </NavLink>
                <NavLink to={`${ROUTES.CASES_LIST_CONTRACTOR}?pageNo=0&pageSize=25`} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">
                    <div className="list__counter">{sidebarCounts && sidebarCounts.casesExecutive1}</div>
                    Wykonawca
                  </li>
                </NavLink>
                <NavLink to={ROUTES.CASES_LIST_SUBCONTRACTOR} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">
                    <div className="list__counter">{sidebarCounts && sidebarCounts.casesExecutive2}</div>
                    Podwykonawca
                  </li>
                </NavLink>

                <div className="navigation__list-title" style={{ marginTop: '40px' }}>
                  <div className="list-title">Profil</div>
                </div>
                <NavLink to={'/dashboard/time-trial-list'} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">Czasówka</li>
                </NavLink>
                <NavLink to={'/dashboard/reminders-list'} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">Lista przypomnień</li>
                </NavLink>
                {isStatsAccess && (
                  <NavLink to={'/dashboard/statistics'} activeClassName="navigation__menu--selected-nav-item" exact>
                    <li className="list">Statystyki</li>
                  </NavLink>
                )}
                <NavLink to={'/dashboard/search-engine'} activeClassName="navigation__menu--selected-nav-item" exact>
                  <li className="list">Wyszukiwarka</li>
                </NavLink>
                <div className="limits">
                  <NavLink to={ROUTES.LIMITS} exact>
                    <li className="list"> Limity</li>
                  </NavLink>
                  {clientsReachingLimitsCount?.warningLimitCount > 0 && <div className="counter counter--orange">{clientsReachingLimitsCount?.warningLimitCount}</div>}
                  {clientsReachingLimitsCount?.maximumLimitCount > 0 && <div className="counter counter--red">{clientsReachingLimitsCount.maximumLimitCount}</div>}
                </div>
                {admin && (
                  <NavLink to={'/dashboard/settings'} activeClassName="navigation__menu--selected-nav-item" className="mg-top-60" exact>
                    <li className="list list--settings">Ustawienia</li>
                  </NavLink>
                )}
                <li
                  className="logout"
                  onClick={() => {
                    this.props.logout();
                  }}>
                  Wyloguj <img src={LogooutIcon} alt="" />
                </li>
              </>
            )}
          </ul>
        )}
        <div className="navigation__menu-logo">
          <img src={Logo} alt="" onClick={() => history.push('/dashboard')} />
          <span>
            {firstName} {lastName}
          </span>
        </div>
        <button className={`navigation__menu-hamburger ${isMenuExpanded ? 'navigation__menu-hamburger--active' : ''}`} onClick={() => this.props.expandMenu()}>
          <svg viewBox="0 0 64 48">
            <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
            <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
            <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
          </svg>
        </button>
        <button
          className="navigation__logout"
          onClick={() => {
            this.props.logout();
          }}>
          Wyloguj <img src={LogooutIcon} alt="" />
        </button>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  searchCase: (params) => searchCase(params),
  getSidebarCounts: () => UserModule.Actions.getSidebarCounts(),
  getClientsReachingLimitsCount: () => LimitsModule.Actions.getClientsReachingLimitsCount(),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    authorizedUser: AuthSelectors.authorizedUser(state),
    sidebarCounts: UserModule.Selectors.getSidebarCounts(state),
    clientsReachingLimitsCount: LimitsModule.Selectors.getClientsReachingLimitsCount(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
