import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';

type P = {
  onSubmit: (values: any) => void;
  caseNo: string;
  internalUserOptions: any;
};

const AddCaseRemainderForm = ({ onSubmit, caseNo = '--', internalUserOptions }: P) => {
  return (
    <Formik
      initialValues={{ remindAt: '', about: '', toUserId: '' }}
      validationSchema={Yup.object().shape({
        about: Yup.string().required('Pole wymagane'),
        toUserId: Yup.string().required('Pole wymagane'),
        remindAt: Yup.string().required('Pole wymagane'),
      })}
      onSubmit={onSubmit}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="add-customer__form">
          <div className="fake_field">
            <div className="fake_field__label">Karta sprawy</div>
            <div className="fake_field__value">{caseNo}</div>
          </div>
          <Field name="remindAt" type="date" wrapperClassName="default" placeholder="dd.mm.rrrr" label="Przypomnij w dniu" component={RegularField} errors={errors} />
          <Field name="about" type="textarea" wrapperClassName="default" label="Treść przypomnienia" component={RegularField} errors={errors} />
          <Field name="toUserId" type="select" wrapperClassName="default" label="Komu przypomnieć" options={internalUserOptions} component={RegularField} errors={errors} placeholder="Wybierz użytkownika" />
          <button className="btn btn--default btn--login btn--center" type="submit">
            Dodaj przypomnienie
          </button>
        </form>
      )}
    </Formik>
  );
};

export default AddCaseRemainderForm;
