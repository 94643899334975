import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import ReactTooltip from 'react-tooltip';
// import { Formik, Field } from 'formik';
// import RegularField from 'Components/Forms/Fields/RegularField';
import * as AuthModule from '../../../../Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from '../../../../Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors, Actions as ViewManagemenetActions } from 'Modules/ViewManagementModule';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import { Selectors as TimeTrialSelectors, Actions as TimeTrialActions } from 'Modules/TimeTrialModule';
import { history } from 'Store/Reducers/RootReducer';
// import SearchIcon from 'Assets/Img/Icons/search-icon.svg';
import Pagination from 'Components/Pagination';
import { ROUTES, PRIVILEGES } from 'shared/consts';
import CustomFilters from './components/customFilters';
import { checkPrivilegeAccess } from 'shared/utils';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

let timer;

type S = {
  filters: any;
  date: [Date, Date];
};

type ConnectedP = {
  isMenuExpanded: boolean;
  isAddReminderView: boolean;
  authorizedUser: AuthModule.Types.AuthorizedUserData;
  timeTrialList: any;
};

type P = {
  location: any;
};

type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
  getTimeTrialList: (params?) => void;
  getTimeTrialXlsx: (params) => void;
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => void;
};

const PERIOD_TYPES = {
  CURRENT_MONTH: 'CURRENT_MONTH',
  PREVIOUS_MONTH: 'PREVIOUS_MONTH',
  ALL: 'ALL',
};

class TimeTrialList extends React.Component<DispatchedP & ConnectedP & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        pageSize: JSON.parse(sessionStorage.getItem('pageSize'))?.value ?? 10,
        pageNo: 0,
      },
      date: [new Date(), new Date()],
    };
  }

  async componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const queryParams: any = new URLSearchParams(search);
    await this.props.getTimeTrialList({ ...this.state.filters, company: queryParams.get('company') });
    this.setState({ filters: { ...this.state.filters } });
  }

  async componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    const queryParams: any = new URLSearchParams(search);
    if (prevProps.location.search !== search) {
      await this.props.getTimeTrialList({ ...this.state.filters, company: queryParams.get('company') });
      this.setState({ filters: { ...this.state.filters } });
    }
  }

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  onFilterChange = (key, value) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [key]: value,
        },
      },
      () => {
        this.getTimeTrial();
      },
    );
  };

  getTimeTrial = () => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await this.props.getTimeTrialList(this.state.filters);
      const queryParams = new URLSearchParams(this.state.filters);
      // const queryParams = new URLSearchParams({ ...values, page: curentParams.get('page') });
      history.replace({
        search: queryParams.toString(),
      });
    }, 600);
  };

  handlePageOrSizeChange = (...args) => {
    const [pageNo, pageSize] = args;
    // console.log(page, size);
    this.setState(
      {
        filters: {
          ...this.state.filters,
          pageNo,
          pageSize,
        },
      },
      async () => await this.props.getTimeTrialList(this.state.filters),
    );
  };

  redirectToEditTimeTrial = (data) => history.push(ROUTES.EDIT_TIME_TRIAL, data);
  
  prepareDate = (date) => {
    const dateFrom = date[0];
    const dateTo = date[1];
    return { dateFrom: dateFrom.toLocaleDateString('sv'), dateTo: dateTo.toLocaleDateString('sv') };
  };
  
  public render() {
    const { timeTrialList, getTimeTrialXlsx } = this.props;
    const { date } = this.state;

    const isExportAccess = checkPrivilegeAccess(PRIVILEGES.EXPORT);
    return (
      <div className="time-trial-list">
        <div className="time-trial-list__header">
          <div className="time-trial-list__title">
            <Link to="/dashboard">
              <img src={GrayArrow} alt="" />
            </Link>
            <h2>Czasówka</h2>
          </div>
          {timeTrialList && <Pagination required pages={timeTrialList.timeEntries.totalPages} getFunction={this.handlePageOrSizeChange} pageNo={this.state.filters.page} />}
          <a href="/dashboard/add-time-trial" className="time-trial-list__right">
            <button
              className="btn btn--default"
              onClick={(e) => {
                e.preventDefault();
                history.push('/dashboard/add-time-trial');
              }}>
              <span>&#43;</span> Dodaj czasówkę
            </button>
          </a>
        </div>
        {timeTrialList && (
          <div className="time-trial-list__summary">
            <p>Podsumowanie czasu z bieżącego miesiąca: {timeTrialList.timeTotalMonth} h</p>
            <p>Podsumowanie czasu z dnia dzisiejszego: {timeTrialList.timeTotalDay} h</p>
            {timeTrialList.userTimeTotalDay ? <p>Podsumowanie czasu z dnia dzisiejszego zalogowanego użytkownika: {timeTrialList.userTimeTotalDay} h</p> : null}
          </div>
        )}
        {isExportAccess && (
          <div className="time-trial-list__export">
            <div className="time-trial-list__calendar">
              <label>Wybierz zakres dat do eksportu </label>
              <DateRangePicker onChange={(value) => this.setState({ date: value })} value={date} />
            </div>
            <div onClick={() =>date ? getTimeTrialXlsx(this.prepareDate(date)): null}>Eksportuj zakres dat do pliku </div>
            <div onClick={() => getTimeTrialXlsx({ period: PERIOD_TYPES.CURRENT_MONTH })}>Eksportuj bieżący miesiąc do pliku</div>
            <div onClick={() => getTimeTrialXlsx({ period: PERIOD_TYPES.PREVIOUS_MONTH })}>Eksportuj poprzedni miesiąc do pliku</div>
          </div>
        )}
        <div className="time-trial-list__table">
          <table className="table">
            <thead>
              <tr>
                <th>Akcje</th>
                <CustomFilters onFilterChange={this.onFilterChange} filters={this.state.filters} />
              </tr>
            </thead>
            <tbody>
              {timeTrialList &&
                timeTrialList.timeEntries.content.map((timeTrial: any) => {
                  const { date, company, ordering, userFullName, description, time, id } = timeTrial;
                  return (
                    <>
                      <ReactTooltip place="top" effect="solid" className="tooltip" />
                      <tr className="hoverable" key={id}>
                        <td className="actions">
                          <button
                            className="btn-action btn-action--edit"
                            data-tip="Edytuj"
                            onClick={() => {
                              this.redirectToEditTimeTrial(timeTrial);
                            }}
                          />
                          <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => this.props.showConfirmPopup('DeleteTimeTrial', timeTrial)} />
                        </td>
                        <td> {date}</td>
                        <td>{company}</td>
                        <td>{ordering}</td>
                        <td>{userFullName}</td>
                        <td className="remind">{description}</td>
                        <td>{time}</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  getTimeTrialList: (params) => TimeTrialActions.getTimeTrialList(params),
  getTimeTrialXlsx: (params) => TimeTrialActions.getTimeTrialXlsx(params),
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => ViewManagemenetActions.showConfirmPopup(typeConfirmPopup, dataToEdit),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    isAddReminderView: ViewManagementSelectors.isAddReminderView(state),
    authorizedUser: AuthSelectors.authorizedUser(state),
    timeTrialList: TimeTrialSelectors.getTimeTrialList(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTrialList);
