import { Limits } from '../EnumTypes/ActionEnumTypes';
import { getClientsReachingLimits as getClientsReachingLimitsConnector } from 'Connectors/LimitsConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Limits.GET_CLIENTS_REACHING_LIMITS_INIT,
});

const success = (clientsReachingLimits) => ({
  type: Limits.GET_CLIENTS_REACHING_LIMITS_SUCCESS,
  payload: {
    clientsReachingLimits,
  },
});

const failure = () => ({
  type: Limits.GET_CLIENTS_REACHING_LIMITS_FAILURE,
});

const getClientsReachingLimits = () => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await getClientsReachingLimitsConnector();
    dispatch(success(data.clientsReachingLimits));
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getClientsReachingLimits;
