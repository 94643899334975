import * as React from 'react';
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
import Users from 'Components/Forms/Fields/Users';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { MainType } from './Container';
import { ROUTES } from 'shared/consts';
import { mapActiveGroupUsersToIds } from 'shared/utils';

type S = {};
type P = {
  history: any;
  match: any;
};

export default class Main extends React.Component<MainType & P, S> {
  async componentDidMount() {
    await this.props.actions.getGroupUsers(this.props.match.params.id);
  }

  onSubmit = async ({ users }) => {
    const usersId = mapActiveGroupUsersToIds(users);
    await this.props.actions.updateGroupUsers(this.props.match.params.id, usersId, this.props.history);
  };

  public render() {
    const { groupUsers = [] } = this.props;
    return (
      <div className="add-customer">
        <div className="add-customer__title">
          <Link to={ROUTES.INTERNAL_USERS_GROUPS}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Przypisz użytkowników</h2>
        </div>
        <div className="add-customer__form-container">
          <Formik enableReinitialize initialValues={{ users: groupUsers }} onSubmit={this.onSubmit}>
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} className="add-customer__form">
                <Field name="users" wrapperClassName="default" label="Użytkownicy w grupie" component={Users} errors={errors} />
                <button className="btn btn--default btn--login" type="submit">
                  Zapisz
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
