import * as React from 'react';
import Select, { components } from 'react-select';
import { FieldProps } from 'formik';
import IndicatorsIcon from 'Assets/Img/Icons/indicators-icon.svg';
import AsyncSelect from 'react-select/async';
import API from 'Connectors/Config';
import { connect } from 'react-redux';
import * as ClientModule from 'Modules/ClientModule';
import { debounce } from 'lodash';
import { Editor, EditorState } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

type Props = {
  label?: string;
  options?: any;
  type: string;
  icon?: string;
  disabled: boolean;
  placeholder?: string;
  wrapperClassName?: string;
  rows?: number;
  field: any;
  errors: any;
  form: any;
  isMulti?: boolean;
  isPreacher?: boolean;
  isAutoComplete?: boolean;
  id?: any;
  setValueWithClientName?: boolean;
  initValue?: boolean;
  onChange?: any;
  external?: boolean;
};

type S = {
  selectValue: any;
  isSelected: boolean;
  showPlaceholderDate: boolean;
  clients: any;
  editorState: any;
};

type OptionType = {
  label: string;
  value: string;
};
let multiArray = [];

type DispatchedP = {
  getClientsSelectList: (clientsList) => void;
};

class RegularField extends React.PureComponent<Props & FieldProps & DispatchedP, S> {
  onChange: (editorState: any) => void;
  constructor(props: Props & FieldProps & DispatchedP) {
    super(props);
    const { field } = props;
    let editorState = EditorState.createEmpty();
    if (typeof field.value === 'string' && field.value.trim().length > 0) {
      const contentState = convertFromHTML(field.value);
      editorState = EditorState.createWithContent(contentState);
    }
    this.state = {
      selectValue: null,
      isSelected: false,
      showPlaceholderDate: true,
      clients: [],
      editorState,
    };
    this.onChange = (editorState) => {
      this.setState({ editorState });
      const contentState = editorState.getCurrentContent();
      const html = this.converter(contentState);
      const cleanedHtml = html.replace(/<\/?p>/g, '');
      this.props.form.setFieldValue(field.name, cleanedHtml);
    };
  }

  converter = convertToHTML({
    blockToHTML: (block) => {
      if (block.type === 'BOLD') {
        return <strong>{block.text}</strong>;
      }
    },
  });

  componentDidUpdate() {
    if (this.props.setValueWithClientName && this.props.type === 'asyncSelect' && this.props.field.value === '') {
      this.setState({ selectValue: null });
    }
  }

  public render() {
    const { field, type, placeholder, wrapperClassName, disabled, options, errors, form, label, icon, isAutoComplete, id } = this.props;
    let formClasses: string = 'form__field';
    formClasses += type ? ` form__field--${type}` : '';
    formClasses += wrapperClassName ? ` form__field--${wrapperClassName} ` : '';
    formClasses += disabled ? ' form__field--disabled' : '';
    formClasses += form.touched[field.name] && errors[field.name] ? ' form__field--error' : '';

    // if (field.value && this.props.options && this.props.options.find(option => option.value === field.value)) {
    //   console.log(field.value);
    //   this.setState({ isSelected: true, selectValue: this.props.options.find(option => option.value === field.value) });
    // }

    const onFocus = () => {
      this.setState({ isSelected: true });
    };
    const onBlur = () => {
      this.setState({ isSelected: false });
    };

    const handleChange = (value) => {
      if (value && this.props.isMulti) {
        multiArray = [];
        value.map((val: any) => multiArray.push(val.value));
        this.props.form.setFieldValue(field.name, multiArray);
        this.setState({ selectValue: multiArray.length ? multiArray : '' });
      } else if (value && this.props.isPreacher) {
        this.props.form.setFieldValue(field.name, [value.value]);
        this.setState({ selectValue: value });
      } else if (this.props.setValueWithClientName && this.props.type === 'asyncSelect') {
        this.props.form.setFieldValue(field.name, value ? { id: value.value, name: value.label } : '');
        this.setState({ selectValue: value });
      } else {
        this.props.form.setFieldValue(field.name, value ? value.value : '');
        this.setState({ selectValue: value });
        this.props.onChange && value && this.props.onChange(value);
      }
    };

    // const onChange = (e) => {
    //   console.log(e.target.files[0]);
    //   this.props.form.setFieldValue(field.name, e.currentTarget.files[0]);
    // };

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <img src={IndicatorsIcon} alt="" />
        </components.DropdownIndicator>
      );
    };

    // const getOption = async (value) => {
    //   // const response: any = this.props.external ? await API.get(`client?search=${value}`) : await API.get(`client?search=${value}`);
    //   const response: any = await API.get(`client${this.props.external ? '/external' : ''}?search=${value}`);
    //   this.props.getClientsSelectList(response.data);
    //   this.setState({clients: response.data})
    //   return response.data;
    // };

    // const getOptionDebaunce = debounce(async (inputValue) =>
    //   new Promise(async(resolve) => {
    //    resolve(getOption(inputValue));
    //   })
    //   , 1000);

    // const promiseOptions = async (inputValue) => {
    //   getOptionDebaunce(inputValue);
    // };

    const promiseOptions = debounce(async (inputValue, callBack) => {
      // console.log('powinno tu wejść i odpytać, czemu tak się nie dzieje?');
      const response: any = await API.get(`client${this.props.external ? '/external' : ''}?search=${inputValue}`);
      this.props.getClientsSelectList(response.data);
      this.setState({ clients: response.data });
      callBack(
        response.data.map((result) => {
          return result;
        }),
      );
    }, 1000);

    return (
      <div className={formClasses}>
        {type && type === 'select' ? (
          <>
            {/* <label className="select-label" htmlFor={field.name}> */}
            <label className="field-label" htmlFor={field.name}>
              {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
            </label>
            {this.props.isPreacher ? (
              <Select id={field.name} placeholder={placeholder} value={field.value.length > 0 ? this.props.options && this.props.options.find((option) => field.value.find((preacher) => option.value === (preacher && preacher.uuid))) : field.value.length === 0 && Array.isArray(field.value) ? this.props.options && this.props.options.find((option) => option.value === null) : ''} onChange={handleChange} onFocus={onFocus} isMulti={this.props.isMulti ? true : false} noOptionsMessage={() => 'Brak wyników'} onBlur={onBlur} options={options} isClearable={true} className="react-select" classNamePrefix={`react-select react-select${this.props.isMulti ? '--is-multi' : ''}`} />
            ) : (
              <Select id={field.name} components={{ DropdownIndicator }} value={field.value || field.value == null || field.value === 0 ? this.props.options && this.props.options.find((option) => option.value === field.value) : ''} onChange={handleChange} onFocus={onFocus} isMulti={this.props.isMulti ? true : false} noOptionsMessage={() => 'Brak wyników'} onBlur={onBlur} options={options} isClearable={true} className="react-select" classNamePrefix="react-select" placeholder={placeholder} />
            )}

            {/* {icon && <i className={`${icon} ${field.value === 1 ? 'verify-success' : field.value === 0 ? 'verify-pending' : ''}`} />} */}
            {/* {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>} */}
          </>
        ) : type && type === 'asyncSelect' ? (
          <>
            <label className="field-label" htmlFor={field.name}>
              {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
            </label>
            <AsyncSelect loadingMessage={() => 'Pobieranie danych'} loadOptions={promiseOptions} components={{ DropdownIndicator }} value={field.value ? { label: field.value.name, value: field.value.id } : ''} onChange={handleChange} onFocus={onFocus} noOptionsMessage={(e) => (e.inputValue ? 'Brak wyników' : 'Wpisz szukaną frazę')} onBlur={onBlur} isClearable={true} className="react-select" classNamePrefix="react-select" placeholder={placeholder} />
            {/* <AsyncSelect defaultOptions={true} loadingMessage={() => 'Pobieranie danych'} loadOptions={promiseOptions} components={{ DropdownIndicator }} value={field.value?.id || field.value?.id == null || field.value?.id === 0 ? this.props.options && this.props.options.find((option) => option.value === field.value?.id) : ''} onChange={handleChange} onFocus={onFocus} noOptionsMessage={(e) => (e.inputValue ? 'Brak wyników' : 'Wpisz szukaną frazę')} onBlur={onBlur} isClearable={true} className="react-select" classNamePrefix="react-select" placeholder={placeholder} /> */}
            {/* <i className={icon} /> */}
          </>
        ) : type && type === 'date' ? (
          <>
            <label className="field-label" htmlFor={field.name}>
              {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
            </label>
            <input id={id || field.name} {...field} type={(this.state.showPlaceholderDate || form.values.isUnknown) && !field.value ? 'text' : 'date'} disabled={form.values.isUnknown} onFocus={() => this.setState({ showPlaceholderDate: false })} onBlur={() => !form.values.isUnknown && this.setState({ showPlaceholderDate: true })} name={field.name} placeholder={placeholder} onChange={field.onChange} checked={field.value} />
            {/* {icon && <i className={icon} />} */}
            {/* {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>} */}
          </>
        ) : type && type === 'textarea' ? (
          <>
            <label className="field-label" htmlFor={field.name}>
              {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
            </label>
            <textarea {...field} type={type} disabled={form.values.isUnknown} onFocus={() => this.setState({ showPlaceholderDate: false })} onBlur={() => !form.values.isUnknown && this.setState({ showPlaceholderDate: true })} name={field.name} placeholder={placeholder} onChange={field.onChange} checked={field.value} />
            {/* {icon && <i className={icon} />} */}
            {/* {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>} */}
          </>
        ) : type && type === 'draft' ? (
          <>
            <label className="field-label" htmlFor={field.name}>
              {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
            </label>
            <Editor {...field} editorState={this.state.editorState} onChange={this.onChange} />
          </>
        ) : type && type === 'checkbox' ? (
          <div className="checkbox__details">
            <input id={id || field.name} disabled={disabled || false} {...field} checked={field.value} value={field.value} type={type} />
            <label className="custom-box" htmlFor={field.name} />

            <label htmlFor={field.name}>{label}</label>
          </div>
        ) : (
          <>
            {label && (
              <label className="field-label" htmlFor={field.name}>
                {/* {label} {errors && form.touched && <div >{form.touched[field.name] && <span className="form__field--label-error">*{errors[field.name]}</span>}</div>} */}
                {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
              </label>
            )}
            <input id={id || field.name} autoComplete={isAutoComplete ? 'on' : 'new-password'} type={type} name={field.name} onChange={field.onChange} value={field.value} placeholder={placeholder} />
            {icon && <img className="icon" src={icon} alt="" />}
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  getClientsSelectList: (clientsList) => ClientModule.Actions.getClientsSelectListSuccess(clientsList),
};

export default connect(null, mapDispatchToProps)(RegularField);
