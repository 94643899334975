import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as fromUserReducer from './UserReducer';
import * as fromAuthReducer from './AuthReducer';
import * as fromViewManagementReducer from './ViewManagementReducer';
import * as fromClientReducer from './ClientReducer';
import * as fromGroupReducer from './GroupReducer';
import * as fromCasesReducer from './CasesReducer';
import * as fromLimitsReducer from './LimitsReducer';
import * as timeTrialReducer from './TimeTrialReducer';
import * as reminderReducer from './ReminderReducer';
import { createBrowserHistory } from 'history';

export type RootState = {
  router: any;
  userStore: fromUserReducer.State;
  authStore: fromAuthReducer.State;
  viewManagementStore: fromViewManagementReducer.State;
  clientStore: fromClientReducer.State;
  groupStore: fromGroupReducer.State;
  casesStore: fromCasesReducer.State;
  limitsStore: fromCasesReducer.State;
  timeTrialStore: timeTrialReducer.State;
  reminderStore: any;
};

export const history = createBrowserHistory();

const rootReducer = combineReducers<RootState>({
  router: connectRouter(history),
  userStore: fromUserReducer.userReducer,
  authStore: fromAuthReducer.authReducer,
  viewManagementStore: fromViewManagementReducer.viewManagementReducer,
  clientStore: fromClientReducer.clientReducer,
  groupStore: fromGroupReducer.groupReducer,
  casesStore: fromCasesReducer.casesReducer,
  limitsStore: fromLimitsReducer.limitsReducer,
  timeTrialStore: timeTrialReducer.timeTrialReducer,
  reminderStore: reminderReducer.reminderReducer,
} as any);

const appReducer = (state: RootState, action) => {
  if (action.type === 'LOG_OUT_SUCCESS') {
    state.authStore = undefined;
  }
  return rootReducer(state, action);
};

export default appReducer;
