import * as Types from '../../Models/ClientModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.ClientStore;

export const initialState: State = {
  clientsSelectList: null,
  clientsList: undefined,
  clientDetails: undefined,
  clientNotes: undefined,
  clientContacts: undefined,
  clientSingleNote: undefined,
  clientSingleContact: undefined,
  clientStats: undefined
};

export function clientReducer(state: any = initialState, action) {
  switch (action.type) {
    case ActionTypes.Client.GET_CLIENTS_SELECT_LIST: {
      return {
        ...state,
        clientsSelectList: action.payload.clientsSelectList,
      };
    }
    case ActionTypes.Client.GET_CLIENTS_LIST_SUCCESS: {
      return {
        ...state,
        clientsList: action.payload.clientsList,
      };
    }
    case ActionTypes.Client.GET_CLIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        clientDetails: action.payload.clientDetails,
      };
    }
    case ActionTypes.Client.GET_CLIENT_NOTES_SUCCESS: {
      return {
        ...state,
        clientNotes: action.payload.clientNotes,
      };
    }
    case ActionTypes.Client.GET_CLIENT_CONTACTS_SUCCESS: {
      return {
        ...state,
        clientContacts: action.payload.clientContacts,
      };
    }
    case ActionTypes.Client.GET_CLIENT_NOTE_BY_ID_SUCCESS: {
      return {
        ...state,
        clientSingleNote: action.payload.clientSingleNote,
      };
    }
    case ActionTypes.Client.GET_CLIENT_SINGLE_CONTACT_SUCCESS: {
      return {
        ...state,
        clientSingleContact: action.payload.clientSingleContact,
      };
    }
    case ActionTypes.Client.GET_CLIENT_STATS_SUCCESS: {
      return {
        ...state,
        clientStats: action.payload.data,
      };
    }
    case ActionTypes.Client.GET_CLIENT_STATS_FOR_USER_SUCCESS: {
      const index = state.clientStats.content.findIndex(({ clientId }) => clientId === action.payload.data.clientId)
      state.clientStats.content[index] = { ...state.clientStats.content[index], ...action.payload.data }
      return {
        ...state,
        clientStats: [...state.clientStats]
      };
    }
    default: {
      return state;
    }
  }
}
