import * as React from 'react';
import RegularField from 'Components/Forms/Fields/RegularField';
import { Field } from 'formik';

type P = {
  errors: any;
  values: any;
  validateOnBlur: any;
  numberOfClients: any;
  isClientSelected: any;
  setFieldValue: any;
};

const AddClients = ({ values, validateOnBlur, setFieldValue, errors, numberOfClients, isClientSelected }: P) => {
  return (
    <>
      <Field name="id" type="asyncSelect" setValueWithClientName placeholder="Wpisz klienta" label="Klient" component={RegularField} setFieldValue={setFieldValue} errors={errors} />
      {values.id?.id && validateOnBlur && isClientSelected(values.id?.id) && (
        <div className="add-customer__form--padding">
          <h6 className="add-customer__form--type-list">Lista typów spraw:</h6>
          <Field type="checkbox" name={`clients[${numberOfClients}].various`} component={RegularField} label={'Różne'} />
          <Field type="checkbox" name={`clients[${numberOfClients}].court`} component={RegularField} label={'Sądowe'} />
          <Field type="checkbox" name={`clients[${numberOfClients}].vindication`} component={RegularField} label={'Windykacja'} />
          <Field type="checkbox" name={`clients[${numberOfClients}].contracts`} component={RegularField} label={'Umowy'} />
          <Field type="checkbox" name={`clients[${numberOfClients}].other`} component={RegularField} label={'Inne'} />
        </div>
      )}
    </>
  );
};

export default AddClients;
