const ROUTES = {
  DASHBOARD: '/dashboard',
  REMAINDER_LIST: '/dashboard/reminders-list',
  CUSTOMER_LIST: '/dashboard/customers-list',
  CUSTOMER_CARD: '/dashboard/customer-card/:id',
  ADD_CONTACT: '/dashboard/customer-card/:id/add-contact',
  EDIT_CONTACT: '/dashboard/customer-card/:id/edit-contact',
  ADD_NOTE: '/dashboard/customer-card/:id/add-note',
  EDIT_NOTE: '/dashboard/customer-card/:id/edit-note',
  FAVOURITE_CUSTOMER_LIST: '/dashboard/favorite-customers-list',
  TIME_TRIAL_LIST: '/dashboard/time-trial-list',
  CASES_LIST_CONTRACTOR: '/dashboard/contractor-cases-list',
  CASES_LIST_SUBCONTRACTOR: '/dashboard/subcontractor-cases-list',
  CASES_LIST_CUSTOMER: '/dashboard/customer-cases-list/:id',
  CASES_LIST_CUSTOMER_ID: '/dashboard/customer-cases-list',
  ADD_REMAINDER: '/dashboard/add-reminder',
  POSTPONE_REMINDER: '/dashboard/postpone-reminder/:id',
  RESPOND_REMINDER: '/dashboard/respond-reminder/:id',
  ADD_CUSTOMER: '/dashboard/add-customer',
  EDIT_CUSTOMER: '/dashboard/edit-customer',
  ADD_TIME_TRIAL: '/dashboard/add-time-trial',
  EDIT_TIME_TRIAL: '/dashboard/edit-time-trial',
  SETTINGS: '/dashboard/settings',
  INTERNAL_USERS: '/dashboard/settings/internal-users',
  ADD_INTERNAL_USER: '/dashboard/settings/add-internal-user',
  INTERNAL_USER_PAGE: '/dashboard/settings/internal-users/page/:page',
  EDIT_INTERNAL_USER: '/dashboard/settings/edit-internal-user/:id',
  EDIT_EXTERNAL_USER: '/dashboard/settings/edit-external-user/:id',
  CHANGE_INTERNAL_USER_PASSWORD: '/dashboard/settings/change-internal-user-password/:id',
  CHANGE_EXTERNAL_USER_PASSWORD: '/dashboard/settings/change-external-user-password/:id',
  EXTERNAL_USERS: '/dashboard/settings/external-users',
  EXTERNAL_USER_PAGE: '/dashboard/settings/external-users/page/:page',
  ADD_EXTERNAL_USER: '/dashboard/settings/add-external-user',
  CRM_SETTINGS: '/dashboard/crm-settings',
  INTERNAL_USERS_LOG: '/dashboard/internal-users-log',
  EXTERNAL_USERS_LOG: '/dashboard/external-users-log',
  INTERNAL_USERS_GROUPS: '/dashboard/internal-users-groups',
  INTERNAL_USERS_MANAGE_GROUP: '/dashboard/manage-internal-users-group/:id',
  INTERNAL_USERS_MANAGE_GROUP_NO_PARAM: '/dashboard/manage-internal-users-group',
  MANAGE_INTERNAL_GROUP_USERS: '/dashboard/assign-users-to-group/:id',
  MANAGE_INTERNAL_GROUP_USERS_NO_PARAM: '/dashboard/assign-users-to-group',
  ADD_CASE: '/dashboard/add-case',
  EDIT_CASE: '/dashboard/edit-case/:id',
  EXTERNAL_CASE_DETAILS: '/dashboard/external-case-details/:id',
  CASE_DETAILS: '/dashboard/case-details/:id',
  CASE_DETAILS_ADD_REMAINDER: '/dashboard/case-details/:id/add-remainder',
  CASE_DETAILS_ADD_STATUS_DESCRIPTION: '/dashboard/case-details/:id/add-status-description',
  CASE_DETAILS_EDIT_STATUS_DESCRIPTION: '/dashboard/case-details/:id/edit-status-description',
  CASE_DETAILS_REPLY_TO_REMAINDER: '/dashboard/case-details/:id/reply-to-remainder',
  CASE_DETAILS_EDIT_REMAINDER: '/dashboard/case-details/:id/edit-remainder',
  STATISTICS: '/dashboard/statistics',
  SEARCH_ENGINE: '/dashboard/search-engine',
  FAVOURITE_CASES: '/dashboard/favourite-cases',
  ACTIVATE_ACCOUNT: '/activate-account',
  EXTERNAL_USER_CASES: '/dashboard/external-user-cases',
  FILES: '/dashboard/files/:id',
  LIMITS: '/dashboard/limits',
};

export default ROUTES;
