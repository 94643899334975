import { GROUP } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = any;

export const initialState: State = {
  group: {
    data: [],
  },
  crm: {
    data: undefined,
  },
  singleGroup: undefined,
  privileges: undefined,
  groupUsers: undefined,
};

export function groupReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case GROUP.GET_GROUP_INIT: {
      return {
        ...state,
        group: {
          ...state.group,
          data: [],
        },
      };
    }
    case GROUP.GET_GROUP_SUCCESS: {
      return {
        ...state,
        group: {
          ...state.group,
          data: action.payload.group,
        },
      };
    }
    case GROUP.GET_CRM_SETTINGS_INIT: {
      return {
        ...state,
        crm: {
          ...state.crm,
          data: undefined,
        },
      };
    }
    case GROUP.GET_CRM_SETTINGS_SUCCESS: {
      return {
        ...state,
        crm: {
          ...state.crm,
          data: action.payload.crmSettings,
        },
      };
    }
    case GROUP.GET_GROUP_BY_ID_INIT: {
      return {
        ...state,
        singleGroup: undefined,
      };
    }
    case GROUP.GET_GROUP_BY_ID_SUCCESS: {
      return {
        ...state,
        singleGroup: action.payload.group,
      };
    }
    case GROUP.GET_PRIVILEGES_INIT: {
      return {
        ...state,
        privileges: undefined,
      };
    }
    case GROUP.GET_PRIVILEGES_SUCCESS: {
      return {
        ...state,
        privileges: action.payload.privileges,
      };
    }
    case GROUP.GET_GROUP_ID_USERS_INIT: {
      return {
        ...state,
        groupUsers: undefined,
      };
    }
    case GROUP.GET_GROUP_ID_USERS_SUCCESS: {
      return {
        ...state,
        groupUsers: action.payload.groupUsers,
      };
    }
    default: {
      return state;
    }
  }
}
