import { Reminder } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/ReminderModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: Reminder.GET_ACTIVE_REMINDER_LIST_INIT,
});

const success = (data) => ({
    type: Reminder.GET_ACTIVE_REMINDER_LIST_SUCCESS,
    payload: {
        data
    }
});

const failure = () => ({
    type: Reminder.GET_ACTIVE_REMINDER_LIST_FAILURE,
});

const getActiveRemidersList = () => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        const { data } = await Connector.getActiveRemidersList();
        dispatch(success(data));
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getActiveRemidersList;
