import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getExternalCaseDetails, deleteCaseStatusDescription, resumeCase, finishCase, manageFavourite } from 'Store/Actions/cases';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as CaseSelector from 'Store/Selectors/CaseSelector';
import { authorizedUser } from 'Store/Selectors/AuthSelector';

type ConnectedP = {
  caseData: any;
  authorizedUser: any;
};
type DispatchedP = {
  actions: {
    getExternalCaseDetails: (id: string) => void;
    deleteCaseStatusDescription: (statusId, caseId) => void;
    resumeCase: (caseId) => void;
    finishCase: (caseId) => void;
    showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => void;
    manageFavourite: (caseId: number, isFavourite: boolean) => void;
  };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getExternalCaseDetails: (id) => getExternalCaseDetails(id),
      deleteCaseStatusDescription: (statusId, caseId) => deleteCaseStatusDescription(statusId, caseId),
      resumeCase: (caseId) => resumeCase(caseId),
      finishCase: (caseId) => finishCase(caseId),
      showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => ViewManagementModule.Actions.showConfirmPopup(typeConfirmPopup, dataToEdit),
      manageFavourite: (caseId: number, isFavourite: boolean) => manageFavourite(caseId, isFavourite),
    },
    dispatch,
  ),
});

function mapStateToProps(state): ConnectedP {
  return {
    caseData: CaseSelector.getCaseDetails(state),
    authorizedUser: authorizedUser(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
