import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { replyToRemainder } from 'Store/Actions/cases';

type ConnectedP = {};
type DispatchedP = {
  actions: {
    replyToRemainder: (replyToRemainderData, caseId) => void;
  };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      replyToRemainder: (replyToRemainderData, caseId) => replyToRemainder(replyToRemainderData, caseId),
    },
    dispatch,
  ),
});

function mapStateToProps(state): ConnectedP {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
