import * as React from 'react';
import { CASES_TYPE_LABELS } from './../consts';

type P = {
  clients: any;
  onRemove: (id) => void;
};

const AssignedClients = ({ clients, onRemove }: P) => {
  return (
    <>
      <div className="add-customer__added-clients">
        {clients.map(({ details, req }, index) => (
          <div className="add-customer__newClient" key={details.id}>
            <div className="add-customer__newClient--name">{details.name}</div>
            <div className="add-customer__newClient--casesType">
              {Object.keys(req).map(
                (e, idx) => `${req[e] ? CASES_TYPE_LABELS[e] : ''}
            `,
              )}
            </div>
            {/* ${idx === Object.keys(req).length - 1 ? '' : ', '}`)}</div> */}
            <div className="add-customer__newClient--actions" onClick={() => onRemove(index)}>
              <button className="btn-action btn-action--delete" data-tip="Usuń"></button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AssignedClients;
