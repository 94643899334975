import API from './Config';
import { AxiosPromise } from 'axios';

export function getInternalUsers(page, pageSize): AxiosPromise<any> {
  return API.get(`user/internal?page=${page}&size=${pageSize}`);
}

export function getExternalUsers(page, pageSize): AxiosPromise<any> {
  return API.get(`user/external?page=${page}&size=${pageSize}`);
}

export function addInternalUser(data): AxiosPromise<any> {
  return API.post(`user/internal`, data);
}

export function getSpecificInternalUser(id: number): AxiosPromise<any> {
  return API.get(`user/internal/${id}`);
}

export function getSpecificExternallUser(id: number): AxiosPromise<any> {
  return API.get(`user/external/${id}`);
}

export function editSpecificInternalUser(data): AxiosPromise<any> {
  return API.put(`user/internal`, data);
}

export function deleteSpecificInternalUser(id): AxiosPromise<any> {
  return API.delete(`user/internal/${id}`);
}

export function deleteExternalUser(id): AxiosPromise<any> {
  return API.delete(`user/external/${id}`);
}

export function changePasswordInternalUser(data): AxiosPromise<any> {
  return API.post(`user/password`, data);
}

export function getInternalUsersLogs(page = 0, size = 10): AxiosPromise<any> {
  return API.get(`log/internal?page=${page}&size=${size}`);
}

export function getExternalUsersLogs(page = 0, size = 10): AxiosPromise<any> {
  return API.get(`log/external?page=${page}&size=${size}`);
}

export function addExternalUser(data): AxiosPromise<any> {
  return API.post(`user/external`, data);
}

export function updateClientsForExternalUser(clients, userId): AxiosPromise<any> {
  return API.post(`user/external/clients`, { clients, userId });
}

export function editExternalUser(data): AxiosPromise<any> {
  return API.put(`user/external`, data);
}

export function getInternalUserOptions(): AxiosPromise<any> {
  return API.get(`user/internal/labels`);
}

export function getAllUsersStats(): AxiosPromise<any> {
  return API.get(`stats/users`);
}

export function checkCanActivate(token): AxiosPromise<any> {
  return API.get(`user/external/activate`, { params: { activationToken: token } });
}

export function activateAccount(data): AxiosPromise<any> {
  return API.patch(`user/external/activate`, data);
}

export function getSidebarCounts(): AxiosPromise<any> {
  return API.get(`sidebar/internal`);
}

export function getExternalUserLogsXlsx(params): AxiosPromise<any> {
  return API.get(`log/external/xlsx`, { responseType: 'blob',  params:{...params} });
}

export function getInternalUserLogsXlsx(params): AxiosPromise<any> {
  return API.get(`log/internal/xlsx`, {responseType: 'blob',  params:{...params} });
}