import { Client } from '../EnumTypes/ActionEnumTypes';
import { updateClientNote as updateClientNoteConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
// import { ROUTES } from 'shared/consts';
// import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: Client.UPDATE_CLIENT_NOTE_INIT,
});

const success = () => ({
  type: Client.UPDATE_CLIENT_NOTE_SUCCESS,
});

const failure = () => ({
  type: Client.UPDATE_CLIENT_NOTE_FAILURE,
});

const updateClientNote = (data) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await updateClientNoteConnector(data);
    dispatch(success());
    toast.success('Pomyślnie edytowano notatkę');
    dispatch(ViewManagementModule.Actions.hideLoader());
    // history.push(ROUTES.CUSTOMER_LIST);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default updateClientNote;
