import { TimeTrial } from '../EnumTypes/ActionEnumTypes';
import { getTimeTrialList as getTimeTrialListConnector } from 'Connectors/TimeTrialConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: TimeTrial.GET_TIME_TRIAL_LIST_INIT,
});

const success = (timeTrialList) => ({
  type: TimeTrial.GET_TIME_TRIAL_LIST_SUCCESS,
  payload: {
    timeTrialList,
  },
});

const failure = () => ({
  type: TimeTrial.GET_TIME_TRIAL_LIST_FAILURE,
});

const getTimeTrialList = (params) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await getTimeTrialListConnector(params);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getTimeTrialList;
