import * as Yup from 'yup';

const STEP_TITLE = {
  0: 'Dodaj użytkownika zewnętrznego',
  1: 'Przypisz klientów',
};
const STEP_BUTTON_LABEL = {
  0: 'Przejdź dalej',
  1: 'Przypisz klienta',
};

const VALIDATION_SCHEMA = {
  0: Yup.object().shape({
    firstName: Yup.string().required('Pole wymagane'),
    lastName: Yup.string().required('Pole wymagane'),
    email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
    phone: Yup.string().required('Pole wymagane'),
  }),
  1: Yup.object().shape({ id: Yup.string().required('Pole wymagane') }),
};

const CASES_TYPE_LABELS = {
  other: 'inne',
  contracts: 'umowy',
  vindication: 'windykacja',
  court: 'sądowe',
  various: 'różne',
};

export { STEP_TITLE, STEP_BUTTON_LABEL, VALIDATION_SCHEMA, CASES_TYPE_LABELS };
