import { GROUP } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/GroupModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
const init = () => ({
  type: GROUP.UPDATE_GROUP_ID_USERS_INIT,
});

const success = () => ({
  type: GROUP.UPDATE_GROUP_ID_USERS_SUCCESS,
});

const failure = () => ({
  type: GROUP.UPDATE_GROUP_ID_USERS_FAILURE,
});

const updateGroupUsers = (groupId, usersId, history) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await Connector.updateGroupUsers(groupId, usersId);
    dispatch(success());
    toast.success('Pomyślnie zaktualizowano użytkowników grupy');
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(ROUTES.INTERNAL_USERS_GROUPS);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się zaktualizować użytkowników grupy');
    dispatch(failure());
  }
};

export default updateGroupUsers;
