import { CASES } from '../EnumTypes/ActionEnumTypes';
import * as CaseConnector from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import  getSidebarCounts  from 'Store/Actions/user/getSidebarCounts';
import { toast } from 'react-toastify';
// import { getCaseDetails } from './';

const init = () => ({
    type: CASES.DELETE_CASE_INIT,
});

const success = () => ({
    type: CASES.DELETE_CASE_SUCCESS,
});

const failure = () => ({
    type: CASES.DELETE_CASE_FAILURE,
});

const deleteCase = (caseId) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await CaseConnector.deleteCase(caseId);
        await window.location.reload();
        dispatch(success());
        await dispatch(ViewManagementModule.Actions.hideConfirmPopup());
        toast.success('Pomyślnie usunięto sprawę');
        await dispatch(getSidebarCounts());
        dispatch(ViewManagementModule.Actions.hideLoader());
        // dispatch(getCaseDetails(caseId));
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.error('Nie udało się usunąć sprawy');
        dispatch(failure());
    }
};

export default deleteCase;
