import LimitsList from './LimitsList';
import { connect } from 'react-redux';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from 'Store/Reducers/RootReducer';
import * as LimitsModule from 'Modules/LimitsModule';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';

type ConnectedP = {
  isMenuExpanded: boolean;
  clientsReachingLimits: any;
};
type DispatchedP = {
  expandMenu: () => void;
  getClientsReachingLimits: () => void;
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps: DispatchedP = {
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  getClientsReachingLimits: () => LimitsModule.Actions.getClientsReachingLimits(),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    clientsReachingLimits: LimitsModule.Selectors.getClientsReachingLimits(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LimitsList);
