import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.GET_EXTERNAL_USERS_LOGS_INIT,
});

const success = (logs) => ({
  type: User.GET_EXTERNAL_USERS_LOGS_SUCCESS,
  payload: {
    logs,
  },
});

const failure = () => ({
  type: User.GET_EXTERNAL_USERS_LOGS_FAILURE,
});

const getExternalUsersLogs = (page, size) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await Connector.getExternalUsersLogs(page, size);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getExternalUsersLogs;
