import * as React from 'react';

const CasesListTableHeader = ({ onFilterChange, filterValues }: any) => (
  <thead>
    <tr>
      <FilterCell id={'caseNumber'} title={'Nr klienta'} onChange={onFilterChange} filterValue={filterValues?.caseNumber} />
      <FilterCell id={'name'} title={'Nazwisko/Nazwa firmy'} onChange={onFilterChange} filterValue={filterValues?.name} />
      <FilterCell id={'executive1FullName'} title={'Czas z poprzedniego miesiaca'} onChange={onFilterChange} filterValue={filterValues?.executive1FullName} />
      <FilterCell id={'executive2FullName'} title={'Czas(live) z bieżącego miesiaca'} onChange={onFilterChange} filterValue={filterValues?.executive2FullName} />
      <FilterCell id={'createdAt'} title={'Limit ostrzegawczy'} onChange={onFilterChange} filterValue={filterValues?.createdAt} />
      <FilterCell id={'status'} title={'Limit końcowy'} onChange={onFilterChange} filterValue={filterValues?.status} />
    </tr>
  </thead>
);

const FilterCell = ({ id, title, filterValue = '', onChange, disabled = false }: any) => (
  <th key={id}>
    <span>{title}</span>
    <br />
  </th>
);

export default CasesListTableHeader;
