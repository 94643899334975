import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as AuthModule from 'Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from 'Store/Reducers/RootReducer';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { ROUTES } from 'shared/consts';

type S = {};
type ConnectedP = {
  // prevPath: string;
};
type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
};
class SettingsList extends React.Component<DispatchedP & ConnectedP, S> {
  public render() {
    // const { prevPath } = this.props;
    return (
      <div className="settings-list">
        <div className="settings-list__title">
          <Link to={'/dashboard'}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Ustawienia</h2>
        </div>
        <div className="settings-list__links-container">
          <ul>
            <NavLink to={ROUTES.INTERNAL_USERS} activeClassName="navigation__menu--selected-nav-item" exact>
              <li className="list">LISTA UŻYTKOWNIKÓW WEWNĘTRZNYCH UW</li>
            </NavLink>
            <NavLink to={ROUTES.EXTERNAL_USERS} activeClassName="navigation__menu--selected-nav-item" exact>
              <li className="list">LISTA UŻYTKOWNIKÓW ZEWNĘTRZNYCH UZ</li>
            </NavLink>
            <NavLink to={ROUTES.INTERNAL_USERS_GROUPS} activeClassName="navigation__menu--selected-nav-item" exact>
              <li className="list">LISTA GRUP UW</li>
            </NavLink>
          </ul>
          <ul>
            <NavLink to={ROUTES.INTERNAL_USERS_LOG} activeClassName="navigation__menu--selected-nav-item" exact>
              <li className="list">LOGI UW</li>
            </NavLink>
            <NavLink to={ROUTES.EXTERNAL_USERS_LOG} activeClassName="navigation__menu--selected-nav-item" exact>
              <li className="list">LOGI UZ</li>
            </NavLink>
          </ul>
          <ul>
            <NavLink to={ROUTES.CRM_SETTINGS} activeClassName="navigation__menu--selected-nav-item" exact>
              <li className="list">USTAWIENIA CRM</li>
            </NavLink>
          </ul>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    // prevPath: ViewManagementSelectors.prevPath(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsList);
