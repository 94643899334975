import { getCaseFile } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const getFile = (caseId, file) => async (dispatch) => {
  try {
    dispatch(ViewManagementModule.Actions.showFileLoader());
    const { data } = await getCaseFile(caseId, file.path);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    dispatch(ViewManagementModule.Actions.hideFileLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideFileLoader());
  }
};

export default getFile;
