import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions, Selectors } from 'Modules/GroupModule';
import Main from './Main';

type ConnectedP = {
  groupData: any;
  privileges: any;
};

type DispatchedP = {
  actions: {
    getGroupById: (id) => void;
    getPrivileges: () => void;
    editGroup: (groupData, history) => void;
    addGroup: (groupData, history) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getGroupById: (id) => Actions.getGroupById(id),
      getPrivileges: () => Actions.getPrivileges(),
      addGroup: (groupData, history) => Actions.addGroup(groupData, history),
      editGroup: (groupData, history) => Actions.editGroup(groupData, history),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  groupData: Selectors.getSingleGroupData(state),
  privileges: Selectors.getPrivilegesData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
