import { GROUP } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/GroupModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';

const init = () => ({
  type: GROUP.ADD_GROUP_INIT,
});

const success = () => ({
  type: GROUP.ADD_GROUP_SUCCESS,
});

const failure = () => ({
  type: GROUP.ADD_GROUP_FAILURE,
});

const addGroup = (groupData, history) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await Connector.addGroup(groupData);
    dispatch(success());
    toast.success('Pomyślnie dodano grupę');
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(ROUTES.INTERNAL_USERS_GROUPS);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error('Nie udało się dodać grupy');
    dispatch(failure());
  }
};

export default addGroup;
