import { Client } from '../EnumTypes/ActionEnumTypes';
import { getClientDetails as getClientDetailsConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: Client.GET_CLIENT_DETAILS_INIT,
});

const success = (clientDetails) => ({
    type: Client.GET_CLIENT_DETAILS_SUCCESS,
    payload: {
        clientDetails,
    },
});

const failure = () => ({
    type: Client.GET_CLIENT_DETAILS_FAILURE,
});

const getClientDetails = (id) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        const { data } = await getClientDetailsConnector(id);
        dispatch(success(data));
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getClientDetails;
