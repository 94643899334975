import * as Types from '../../Models/ViewManagementModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { ViewsManagemenetAction } from '../Actions/Models/ViewManagementActionModel';

export type State = Types.ViewManagementStore;

export const initialState: State = {
  loaderVisible: false,
  fileLoaderVisible: false,
  prevPath: '',
  isMenuExpanded: window.innerWidth > 1200,
  isAddReminderView: false,
  typeConfirmPopup: '',
  confirmationPopupVisible: false,
  dataToEdit: null,
};

export function viewManagementReducer(state: State = initialState, action: ViewsManagemenetAction) {
  switch (action.type) {
    case ActionTypes.ViewManagement.SHOW_LOADER: {
      return {
        ...state,
        loaderVisible: true,
      };
    }
    case ActionTypes.ViewManagement.HIDE_LOADER: {
      return {
        ...state,
        loaderVisible: false,
      };
    }
    case ActionTypes.ViewManagement.SET_PREV_PATH: {
      return {
        ...state,
        prevPath: action.payload.prevPath,
      };
    }
    case ActionTypes.ViewManagement.EXPAND_MENU: {
      return {
        ...state,
        isMenuExpanded: !state.isMenuExpanded,
      };
    }
    case ActionTypes.ViewManagement.EXPAND_ADD_REMINDER_VIEW: {
      return {
        ...state,
        isAddReminderView: !state.isAddReminderView,
      };
    }
    case ActionTypes.ViewManagement.SHOW_CONFIRM_POPUP: {
      return {
        ...state,
        typeConfirmPopup: action.payload.typeConfirmPopup,
        confirmationPopupVisible: true,
        dataToEdit: action.payload.dataToEdit,
      };
    }
    case ActionTypes.ViewManagement.HIDE_CONFIRM_POPUP: {
      return {
        ...state,
        confirmationPopupVisible: false,
      };
    }
    case ActionTypes.ViewManagement.SHOW_FILE_LOADER: {
      return {
        ...state,
        fileLoaderVisible: true,
      };
    }
    case ActionTypes.ViewManagement.HIDE_FILE_LOADER: {
      return {
        ...state,
        fileLoaderVisible: false,
      };
    }
    default: {
      return state;
    }
  }
}
