import { Client } from './EnumTypes/ActionEnumTypes';
import * as ClientActionModel from './Models/ClientActionMode';
import * as ClientModule from '../../Modules/ClientModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import getClientsList from './client/getClients';
import addClient from './client/addClient';
import editClient from './client/editClient';
import deleteClient from './client/deleteClient';
import getClientDetails from './client/getClientDetails';
import getClientNotes from './client/getClientNotes';
import getClientNoteById from './client/getClientNoteById';
import getClientContacts from './client/getClientContacts';
import addContactToClient from './client/addContactToClient';
import addNoteToClient from './client/addNoteToClient';
import deleteClientContact from './client/deleteClientContact';
import updateClientNote from './client/updateClientNote';
import deleteClientNote from './client/deleteClientNote';
import getClientSingleContact from './client/getClientSingleContact';
import editClientSingleContact from './client/editClientSingleContact';
import manageFavourite from './client/manageFavourite';
import getClientStats from './client/getClientStats';
import getClientStatsForUser from './client/getClientStatsForUser';
import getClientsXlsx from './client/getClientsXlsx';

function getCLientsSelectList() {
  return async (dispatch) => {
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      const response = await ClientModule.Connector.getClientsSelectList();
      dispatch(getClientsSelectListSuccess(response.data));
      dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
    }
  };
}

function getClientsSelectListSuccess(clientsSelectList): ClientActionModel.GetClientsSelectList {
  return {
    type: Client.GET_CLIENTS_SELECT_LIST,
    payload: {
      clientsSelectList,
    },
  };
}

export { getCLientsSelectList, getClientsSelectListSuccess, getClientsList, addClient, editClient, deleteClient, getClientDetails, getClientNotes, getClientContacts, addContactToClient, addNoteToClient, deleteClientContact, getClientNoteById, updateClientNote, deleteClientNote, getClientSingleContact, editClientSingleContact, manageFavourite, getClientStats, getClientStatsForUser, getClientsXlsx };
