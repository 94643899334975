import { Client } from '../EnumTypes/ActionEnumTypes';
import { addNoteToClient as addNoteToClientConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import getClientNotes from './getClientNotes';

const init = () => ({
  type: Client.ADD_NOTE_TO_CLIENT_INIT,
});

const success = () => ({
  type: Client.ADD_NOTE_TO_CLIENT_SUCCESS,
});

const failure = () => ({
  type: Client.ADD_NOTE_TO_CLIENT_FAILURE,
});

const getClientNoteById = (noteData) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await addNoteToClientConnector(noteData);
    dispatch(success());
    await dispatch(getClientNotes(noteData.id));
    toast.success('Pomyślnie dodano notatkę');
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getClientNoteById;
