import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.GET_INTERNAL_USER_LOGS_XLSX_INIT,
});

const success = () => ({
  type: User.GET_INTERNAL_USER_LOGS_XLSX_SUCCESS,
});

const failure = () => ({
  type: User.GET_INTERNAL_USER_LOGS_XLSX_FAILURE,
});

const getInternalUserLogsXlsx = (params) => async (dispatch) => {
  dispatch(init());

  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await Connector.getInternalUserLogsXlsx(params);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Logi_UW.xlsx');
    document.body.appendChild(link);
    link.click();
    dispatch(success());
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    console.log(err);
    dispatch(failure());
  }
};

export default getInternalUserLogsXlsx;
