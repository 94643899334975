import * as React from 'react';
import RegularField from 'Components/Forms/Fields/RegularField';
import { Field } from 'formik';

type P = {
  errors: any;
};

const ExternalUserDetails = ({ errors }: P) => {
  return (
    <>
      <Field name="firstName" type="text" wrapperClassName="default" placeholder="Wpisz imię" label="Imię" component={RegularField} errors={errors} />
      <Field name="lastName" type="text" wrapperClassName="default" placeholder="Wpisz nazwisko" label="Nazwisko" component={RegularField} errors={errors} />
      <Field name="phone" type="text" wrapperClassName="default" placeholder="Wpisz numer telefonu" label="Numer telefonu" component={RegularField} errors={errors} />
      <Field name="email" type="text" wrapperClassName="default" placeholder="Wpisz adres email" label="Email" component={RegularField} errors={errors} isAutoComplete={false} />
    </>
  );
};

export default ExternalUserDetails;
