import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';

export const initialState = {
  remindersList: undefined,
};

export function reminderReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ActionTypes.Reminder.ADD_REMINDER_SUCCESS: {
      return {
        ...state,
        // internalUsersList: action.payload.internalUsersList,
      };
    }
    case ActionTypes.Reminder.GET_ACTIVE_REMINDER_LIST_SUCCESS: {
      // console.log(action.payload.data);

      return {
        ...state,
        remindersList: action.payload.data,
      };
    }
    case ActionTypes.Reminder.GET_ALL_REMINDER_LIST_SUCCESS: {
      // console.log(action.payload.data);
      return {
        ...state,
        remindersList: action.payload.data,
      };
    }

    default: {
      return state;
    }
  }
}
