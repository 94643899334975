import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserModule from 'Modules/UserModule';
import * as ClientModule from 'Modules/ClientModule';

type ConnectedP = { internalUserOptions: any; clientSingleNote: any };
type DispatchedP = {
  actions: {
    updateClientNote: (data) => void;
    getClientNoteById: (id) => void;
  };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      updateClientNote: (data) => ClientModule.Actions.updateClientNote(data),
      getClientNoteById: (id) => ClientModule.Actions.getClientNoteById(id),
      updateNote: (data) => ClientModule.Actions.updateClientNote(data),
    },
    dispatch,
  ),
});

function mapStateToProps(state): ConnectedP {
  return {
    internalUserOptions: UserModule.Selectors.getInternalUserOptions(state),
    clientSingleNote: ClientModule.Selectors.getClientNoteById(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
