import * as React from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';
import * as UserModule from 'Modules/UserModule';
import * as Yup from 'yup';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { ROUTES } from 'shared/consts';

type S = {
  userType: 'INTERNAL' | 'EXTERNAL';
};
type ConnectedP = {
  prevPath: string;
  match?: any;
  history?: any;
};
type DispatchedP = {
  changePasswordInternalUser: (data: any) => void;
};
class ChangeUserPassword extends React.Component<DispatchedP & ConnectedP, S> {
  constructor(props) {
    super(props);
    this.state = {
      userType: props.match.url.includes('internal') ? 'INTERNAL' : 'EXTERNAL',
    };
  }

  redirectToUsersList = () => this.props.history.push(this.state.userType === 'INTERNAL' ? ROUTES.INTERNAL_USER_PAGE : ROUTES.EXTERNAL_USER_PAGE);
  public render() {
    const ChangePasswordSchema = Yup.object().shape({
      password: Yup.string().required('Pole wymagane').min(8, 'Hasło musi zawierać minimum 8 znaków'),
      confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Hasła są niezgodne')
        .required('Pole wymagane'),
      // confirmation: Yup.string()
      //   .required('Pole wymagane')
      //   .test('passwords-match', 'Hasła są niezgodne', function (value) {
      //     return this.parent.password === value;
      //   }),
    });

    const { prevPath } = this.props;
    return (
      <div className="add-customer">
        <div className="add-customer__title">
          <Link to={prevPath ? prevPath : '/dashboard'}>
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Zmień hasło</h2>
        </div>
        <div className="add-customer__form-container">
          <Formik
            initialValues={{ password: '', confirmation: '' }}
            enableReinitialize={true}
            validationSchema={ChangePasswordSchema}
            onSubmit={async (values) => {
              const data = {
                id: this.props.match.params.id,
                password: values.password,
              };
              await this.props.changePasswordInternalUser(data);
              this.redirectToUsersList();
            }}>
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit} className="add-customer__form">
                <Field name="password" type="password" wrapperClassName="default" label="Hasło" placeholder="Wpisz nowe hasło" component={RegularField} errors={errors} />
                <Field name="confirmation" type="password" wrapperClassName="default" label="Powtórzenie hasła" placeholder="Powtórz hasło" component={RegularField} errors={errors} />
                <button className="btn btn--default btn--login btn--center" type="submit">
                  Zmień hasło
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  changePasswordInternalUser: (data: any) => UserModule.Actions.changePasswordInternalUser(data),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    prevPath: ViewManagementSelectors.prevPath(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserPassword);
