import { CASES } from '../EnumTypes/ActionEnumTypes';
import { setElectronicOnly as setElectonicOnlyConnector } from 'Connectors/CaseConnector';
import { toast } from 'react-toastify';

const init = () => ({
  type: CASES.SET_ELECTRONIC_ONLY_INIT,
});

const success = (isElectronicOnly) => ({
  type: CASES.SET_ELECTRONIC_ONLY_SUCCESS,
  payload: { isElectronicOnly },
});

const failure = () => ({
  type: CASES.SET_ELECTRONIC_ONLY_FAILURE,
});

const setElectonicOnly = (caseId, isElectronicOnly) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading('Trwa zmiana statusu sprawy...');
  try {
    await setElectonicOnlyConnector(caseId, isElectronicOnly);
    toast.update(id, {
      render: 'Status sprawy został zmieniony',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      autoClose: 6000,
      closeButton: true,
    });

    dispatch(success(isElectronicOnly));
  } catch (err) {
    dispatch(failure());
    toast.update(id, {
      render: 'Wystąpił błąd podczas zmiany statusu sprawy',
      type: toast.TYPE.ERROR,
      isLoading: false,
      autoClose: 6000,
      closeButton: true,
    });
  }
};

export default setElectonicOnly;
