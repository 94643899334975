import {connect, FormikContextType} from "formik";
import {Component} from "react";

interface Props {
    formik: FormikContextType<any>;
}

class ErrorFocusInternal extends Component<Props> {
    public componentDidUpdate(prevProps: Props) {
        const {isSubmitting, isValidating, errors} = prevProps.formik;
        const keys = Object.keys(errors);
        
        if (keys.length > 0 && isSubmitting && !isValidating) {
            // const selector = `[name="${keys[0]}"]`;
            const selector2 = keys[0];
            const errorElement = document.getElementById(selector2);
            // const errorElement = document.querySelector(selector) as HTMLElement;
            console.log(selector2)
            if (errorElement) {
                errorElement.scrollIntoView();
            }
        }
    }

    public render = () => null;
}

export const ErrorFocus = connect<{}>(ErrorFocusInternal);