import { CASES } from '../EnumTypes/ActionEnumTypes';
import { getCasesList as getCasesListConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: CASES.GET_CASES_LIST_INIT,
});

const success = (casesList) => ({
  type: CASES.GET_CASES_LIST_SUCCESS,
  payload: {
    casesList,
  },
});

const failure = () => ({
  type: CASES.GET_CASES_LIST_FAILURE,
});

const getCasesList = (execType, params) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await getCasesListConnector(execType, params);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getCasesList;
