import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumbs } from 'shared/components';
import FilesExplorer from './components/FilesExplorer';

const Files = ({ match, getCaseFiles, getFile, files, fileLoaderVisible, getCaseFilesExternal, authorizedUser, getFileExternal }) => {
  const {
    path,
    params: { id },
  } = match;
  const { external } = authorizedUser;

  useEffect(() => {
    external ? getCaseFilesExternal(id) : getCaseFiles(id);
  }, [getCaseFiles, getCaseFilesExternal, external, id]);

  return (
    <div className="files">
      <div className="files__breadcrumbs">
        <Breadcrumbs pathname={path} caseId={id} />
      </div>
      <h2 className="main-subtitle files__title">Dokumenty sprawy</h2>
      <div className="files__table">
        <FilesExplorer getFile={getFile} data={files} id={id} getCaseFilesExternal={getCaseFilesExternal} getDirectory={getCaseFiles} fileLoaderVisible={fileLoaderVisible} external={external} getFileExternal={getFileExternal} />
      </div>
    </div>
  );
};

export default Files;

Files.propTypes = {
  files: PropTypes.array.isRequired,
  fileLoaderVisible: PropTypes.bool.isRequired,
  getCaseFiles: PropTypes.func.isRequired,
  getCaseFilesExternal: PropTypes.func.isRequired,
  getFile: PropTypes.func.isRequired,
  getFileExternal: PropTypes.func.isRequired,
  match: PropTypes.string.isRequired,
  authorizedUser: PropTypes.object.isRequired,
};
