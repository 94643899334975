import * as React from 'react';
// import { Link } from 'react-router-dom';
// import { generatePath } from 'react-router';
import { MainType } from './Container';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
// import { ROUTES } from 'shared/consts';
import EditNoteForm from './components/editNoteForm';
import { history } from 'Store/Reducers/RootReducer';

type P = {
  location: any;
  match: any;
};

class Main extends React.Component<MainType & P, any> {
  constructor(props: MainType & P) {
    super(props);
    this.state = {
      data: null,
    };
  }
  async componentDidMount() {
    await this.props.actions.getClientNoteById(this.props.match.params.id);
    this.setState({ data: this.props.clientSingleNote?.note?.value });
  }
  handleSubmit = async (values) => {
    await this.props.actions.updateClientNote({ ...values, id: this.props.match.params.id });
    history.goBack();
  };

  render() {
    return (
      <div className="addCaseRemainder narrow-section">
        <div className="narrow-section__title">
          <div onClick={() => history.goBack()}>
            <img src={GrayArrow} alt="" />
          </div>
          <h2>Edytuj notatkę</h2>
        </div>
        <div className="add-customer__form-container">
          <EditNoteForm data={this.state.data} onSubmit={this.handleSubmit} />
        </div>
      </div>
    );
  }
}

export default Main;
