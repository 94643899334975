import * as React from 'react';
import Login from './Layout/Login';
import ActivateAccount from './Layout/ActivateAccount';
// import './App.scss';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { store } from '../Store/Store';
import { Route, Switch } from 'react-router-dom';
import { history } from '../Store/Reducers/RootReducer';
import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import * as ViewManagementModule from '../Modules/ViewManagementModule';
// import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import * as AuthModule from '../Modules/AuthModule';
import * as UserModule from '../Modules/UserModule';
import Preloader from 'Components/Layout/Preloader';
import { ToastContainer, toast } from 'react-toastify';
import PrivateRoute from './Routes/PrivateRoute';
import Dashboard from './Dashboard';
import * as H from 'history';
import ConfirmationPopup from './Popups/ConfirmationPopup';
import { ROUTES } from 'shared/consts';

type P = RootState;

type ConnectedP = {
  loaderVisible: boolean;
  confirmationPopupVisible: boolean;
  history: any;
  location: H.Location;
};
type DispatchedP = {
  setPrevPath: (prevPath: string) => void;
  loginUserFromStorage: (token: string) => void;
  checkCanActivate: (token: string) => any;
  getAuthorizedUser: () => void;
};

class App extends React.Component<ConnectedP & DispatchedP, any> {
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    const {
      location: { search },
      checkCanActivate,
    } = this.props;

    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      this.props.loginUserFromStorage(token);
      // await this.props.getAuthorizedUser();
    }

    if (search.split('=')[0] === '?activationToken') {
      console.log(search.split('=')[1]);
      const isTokenActive = await checkCanActivate(search.split('=')[1]);
      if (isTokenActive) {
        localStorage.setItem('activationToken', search.split('=')[1]);
        history.push(ROUTES.ACTIVATE_ACCOUNT);
      } else {
        toast.error('Klucz aktywacyjny wygasł');
      }
      console.log(isTokenActive);
    }
  }

  shouldComponentUpdate({ location }) {
    if (location.pathname !== this.props.location.pathname) {
      this.props.setPrevPath(this.props.location.pathname);
    }
    return true;
  }

  public render() {
    const { loaderVisible, confirmationPopupVisible } = this.props;
    return (
      <div className="main-wrapper">
        <ToastContainer autoClose={6000} />
        {loaderVisible && <Preloader />}
        {confirmationPopupVisible && !loaderVisible && <ConfirmationPopup />}
        <div className="page__inner">
          <Switch>
            <Route exact path={'/'} component={Login} />
            <Route path={'/login'} component={Login} />
            <Route path={ROUTES.ACTIVATE_ACCOUNT} component={ActivateAccount} />
            <PrivateRoute path={'/dashboard'} component={Dashboard} />
            <Route component={Login} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  setPrevPath: (prevPath: string) => ViewManagementModule.Actions.setPrevPath(prevPath),
  loginUserFromStorage: (token: string) => AuthModule.Actions.loginSuccess({ token }),
  checkCanActivate: (token: string) => UserModule.Actions.checkCanActivate(token),
  getAuthorizedUser: () => AuthModule.Actions.getAuthorizedUser(),
};

function mapStateToProps(state: RootState) {
  return {
    loaderVisible: ViewManagementSelectors.loaderVisible(state),
    confirmationPopupVisible: ViewManagementSelectors.confirmationPopupVisible(state),
  };
}

const AppMain = connect(mapStateToProps, mapDispatchToProps)(App);

const RootApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route path="/" component={AppMain} />
    </ConnectedRouter>
  </Provider>
);
export default RootApp;
