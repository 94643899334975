import { Reminder } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/ReminderModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
    type: Reminder.RESPOND_REMINDER_INIT,
});

const success = () => ({
    type: Reminder.RESPOND_REMINDER_SUCCESS,
});

const failure = () => ({
    type: Reminder.RESPOND_REMINDER_FAILURE,
});

const respondReminder = (reminderData) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        await Connector.respondReminder(reminderData);
        dispatch(success());
        toast.success('Pomyślnie odpowiedzono na przypomnienie');
        dispatch(ViewManagementModule.Actions.hideLoader());
        history.push(ROUTES.REMAINDER_LIST);
    } catch (err) {
        console.log(err, 'error')
        dispatch(ViewManagementModule.Actions.hideLoader());
        toast.error('Nie udało się odpowiedzieć na przypomnienie');
        dispatch(failure());
    }
};

export default respondReminder;
