/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

const getColumnsForCaseStatus = (redirectToEditCaseStatus, deleteCaseStatus, external) => [
  {
    id: 'id',
    accessor: 'id',
    Header: 'Narzędzia',
    width: 90,
    show: !external,
    Cell: ({ original: { shared = false, id = '', body = '' } = {} }) => (
      <>
        <button className="btn-action btn-action--edit" data-tip="Edytuj" onClick={() => redirectToEditCaseStatus({ body, id, shared })}></button>
        <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => deleteCaseStatus(id)}></button>
      </>
    ),
  },
  {
    id: 'createdAt',
    accessor: 'createdAt',
    Header: 'Data wpisu',
    width: 100,
  },
  {
    id: 'body',
    accessor: 'body',
    Header: 'Wpis',
    Cell: ({ value }) => <span title={value}>{value}</span>,
  },
  {
    id: 'userFullName',
    accessor: 'userFullName',
    Header: 'Użytkownik',
    width: 180,
  },
  {
    id: 'shared',
    accessor: 'shared',
    Header: 'Udostępnij klientowi',
    width: 105,
    show: !external,
    Cell: ({ value }) => (value ? 'Tak' : 'Nie'),
  },
];

const getColumnsForRemainders = (redirectToRemaiderReply, redirectToPostponeReminder, showConfirmPopup) => [
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Akcje',
    Cell: ({ original }) => {
      const singleReminder = { remindAt: original.remindAt, about: original.about };
      const singleReminderAfterStringify = JSON.stringify(singleReminder);
      return (
        <>
          <ReactTooltip place="top" effect="solid" className="tooltip" />
          {/* <button className="btn-action btn-action--edit" data-tip="Edytuj" onClick={() => redirectToRemaiderEdit(id)}></button> */}
          <button className="btn-action btn-action--reply" data-tip="Odpowiedz" onClick={() => redirectToRemaiderReply(original.id)}></button>
          <button
            className="btn-action btn-action--edit"
            data-tip="Edytuj"
            onClick={() => {
              redirectToPostponeReminder(original.id);
              sessionStorage.setItem('reminder', singleReminderAfterStringify);
            }}></button>
          <button className="btn-action btn-action--cancel" data-tip="Odrzuć" onClick={() => showConfirmPopup('RejectReminder', { id: original.id })}></button>
        </>
      );
    },
  },
  {
    id: 'toUserFullName',
    accessor: 'toUserFullName',
    Header: 'Przypisany do',
    filterable: true,
    Filter: () => <input className="table-filter-input" id="toUserFullName" />,
  },
  {
    id: 'caseNumber',
    accessor: 'caseNumber',
    Header: 'Karta sprawy',
    filterable: true,
    Filter: () => <input className="table-filter-input" id="caseNumber" />,
  },
  {
    id: 'userFullName',
    accessor: 'userFullName',
    Header: 'Zlecający',
    filterable: true,
    Filter: () => <input className="table-filter-input" id="userFullName" />,
  },
  {
    id: 'about',
    accessor: 'about',
    Header: 'Przypomnij o',
    filterable: true,
    Filter: () => <input className="table-filter-input" id="about" />,
    Cell: ({ original: { about = '' } = {} }) => (
      <>
        <div className="remind" dangerouslySetInnerHTML={{ __html: about }} />
      </>
    ),
  },
  {
    id: 'remindAt',
    accessor: 'remindAt',
    Header: 'Przypomnij w dniu',
    filterable: true,
    Filter: () => <input className="table-filter-input" id="remindAt" />,
  },
];

export { getColumnsForCaseStatus, getColumnsForRemainders };
