import * as React from 'react';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';

const PATHNAME_BREADCRUMBS = {
  [ROUTES.INTERNAL_USERS_LOG]: [{ breadcrumb: 'Ustawienia', path: ROUTES.SETTINGS }, { breadcrumb: 'Logi UW' }],
  [ROUTES.EXTERNAL_USERS_LOG]: [{ breadcrumb: 'Ustawienia', path: ROUTES.SETTINGS }, { breadcrumb: 'Logi UZ' }],
  [ROUTES.INTERNAL_USERS_GROUPS]: [{ breadcrumb: 'Ustawienia', path: ROUTES.SETTINGS }, { breadcrumb: 'Lista grup UW' }],
  [ROUTES.CASE_DETAILS]: [{ breadcrumb: 'Lista spraw', path: ROUTES.CASES_LIST_CONTRACTOR }, { breadcrumb: 'Karta sprawy' }],
  [ROUTES.CUSTOMER_CARD]: [{ breadcrumb: 'Lista klientów', path: ROUTES.CUSTOMER_LIST }, { breadcrumb: 'Karta klienta' }],
  [ROUTES.FILES]: [{ breadcrumb: 'Lista spraw', path: ROUTES.CASES_LIST_CONTRACTOR }, { breadcrumb: 'Karta sprawy', path: ROUTES.CASE_DETAILS }, { breadcrumb: 'Pliki sprawy' }],
};
const parsePathnameToBreadcrumbs = ({ pathname }) => PATHNAME_BREADCRUMBS[pathname] ?? [];

const Breadcrumbs = ({ pathname, caseId }: any) => {
  const breadcrumbsPath = parsePathnameToBreadcrumbs({ pathname });

  const breadcrumbsAfterAddCaseID = () =>
    breadcrumbsPath.map((item) => {
      return { ...item, path: item?.path?.replaceAll(':id', caseId) };
    });

  return (
    <span className="breadcrumbs">
      <div className="breadcrumbs__inner">
        {breadcrumbsAfterAddCaseID().map(({ breadcrumb, path }, idx) => (
          <React.Fragment key={breadcrumb}>
            <a
              href={path}
              className="breadcrumbs__element"
              key={breadcrumb}
              onClick={(e) => {
                e.preventDefault();
                history.push(path);
              }}>
              {breadcrumb}
            </a>
            {idx !== breadcrumbsPath.length - 1 && (
              <span key="breadcrumb-arrow" className="breadcrumbs__arrow">
                <img src={GrayArrow} alt="" />
              </span>
            )}
          </React.Fragment>
        ))}
      </div>
    </span>
  );
};

export default Breadcrumbs;
