import * as React from 'react';
import RegularField from 'Components/Forms/Fields/RegularField';
import { Field } from 'formik';
import { ADD_CASE_OPTIONS } from '../consts';

type P = {
  errors: any;
  setFieldValue: any;
  internalUserOptions: any;
  isEdit?: boolean;
};

const BasicData = ({ errors, setFieldValue, internalUserOptions, isEdit = false }: P) => {
  return (
    <>
      {!isEdit && <Field name="clientId" type="asyncSelect" setValueWithClientName placeholder="Wpisz klienta" label="Klient" component={RegularField} setFieldValue={setFieldValue} errors={errors} />}
      <Field name="ordering" type="text" wrapperClassName="default" placeholder="Wpisz dane" label="Zlecający" component={RegularField} errors={errors} />
      <Field onChange={(e)=> setFieldValue('executive2Id', e.value)} name="executive1Id" type="select" wrapperClassName="default" placeholder="Wpisz osobę" label="Wykonawca" options={internalUserOptions} component={RegularField} errors={errors} />
      <Field name="executive2Id" type="select" wrapperClassName="default" placeholder="Wpisz osobę" label="Drugi wykonawca" options={internalUserOptions} component={RegularField} errors={errors} />
      {/* <Field name="contractor" type="text" wrapperClassName="default" placeholder="Wpisz osobę" label="Podwykonawca" component={RegularField} errors={errors} /> */}
      <Field name="caseType" type="select" wrapperClassName="default" placeholder="Wybierz typ" label="Typ sprawy" options={ADD_CASE_OPTIONS} component={RegularField} errors={errors} />
    </>
  );
};

export default BasicData;
