import { GROUP } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/GroupModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: GROUP.GET_PRIVILEGES_INIT,
});

const success = (privileges) => ({
  type: GROUP.GET_PRIVILEGES_SUCCESS,
  payload: {
    privileges,
  },
});

const failure = () => ({
  type: GROUP.GET_PRIVILEGES_FAILURE,
});

const getPrivileges = () => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await Connector.getPrivileges();
    dispatch(success(data.privilegeBooleanDtos));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getPrivileges;
