import * as React from 'react';
import { MainType } from './Container';
import { Breadcrumbs, Table, Button, Modal } from 'shared/components';
import { ROUTES } from 'shared/consts';

type S = {
  isModalActive: boolean;
  groupId: any;
};
type P = {
  history: any;
  location: any;
};

export default class Main extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      isModalActive: false,
      groupId: undefined,
    };
  }
  async componentDidMount() {
    await this.props.actions.getGroup();
  }
  renderActions = (value, history, openDeletePopup) => (
    <>
      <button className="btn-action btn-action--edit" data-tip="Edytuj" onClick={() => history.push(`${ROUTES.INTERNAL_USERS_MANAGE_GROUP_NO_PARAM}/${value}`)} />
      <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => openDeletePopup(value)} />
      <button className="btn-action btn-action--search" data-tip="Przypisz użytkowników" onClick={() => history.push(`${ROUTES.MANAGE_INTERNAL_GROUP_USERS_NO_PARAM}/${value}`)} />
    </>
  );

  openDeletePopup = (groupId) => this.setState({ isModalActive: true, groupId });

  onDelete = async () => {
    await this.props.actions.deleteGroup(this.state.groupId);
    this.setState({
      isModalActive: false,
      groupId: undefined,
    });
  };

  public render() {
    const {
      groups,
      location: { pathname },
      history,
    } = this.props;
    return (
      <div className="internal-users-group">
        <div className="internal-users-group__header">
          <Breadcrumbs pathname={pathname} />
          <Button label="Dodaj grupę" onClick={() => history.push(`${ROUTES.INTERNAL_USERS_MANAGE_GROUP_NO_PARAM}/add`)} />
        </div>
        <div className="internal-users-group__list">
          <div className="internal-users-group__button"></div>
          <Table
            className="regular-table"
            data={groups}
            columns={[
              {
                id: 'id',
                accessor: 'id',
                Header: 'Czynności',
                Cell: ({ value }) => this.renderActions(value, history, this.openDeletePopup),
                width: 130,
              },
              {
                id: 'id',
                accessor: 'id',
                Header: 'LP',
                width: 50,
              },
              {
                id: 'name',
                accessor: 'name',
                Header: 'Skrócona nazwa grupy',
              },
              {
                id: 'description',
                accessor: 'description',
                Header: 'Opis',
              },
            ]}
          />
        </div>
        {this.state.isModalActive && <Modal onSubmit={this.onDelete} closePopup={() => this.setState({ isModalActive: false, groupId: undefined })} />}
      </div>
    );
  }
}
