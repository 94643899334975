import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions, Selectors } from 'Modules/UserModule';
import Main from './Main';

type ConnectedP = {
  externalUsersLogData: any;
};

type DispatchedP = {
  actions: {
    getExternalUsersLogs: (page: any, string: any) => void;
    getExternalUserLogsXlsx: (params: any) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getExternalUsersLogs: (page, size) => Actions.getExternalUsersLogs(page, size),
      getExternalUserLogsXlsx: (params: any) => Actions.getExternalUserLogsXlsx(params)
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  externalUsersLogData: Selectors.getExternalUsersLogs(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
