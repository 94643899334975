import Main from './Main';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserModule from 'Modules/UserModule';
import * as ClientModule from 'Modules/ClientModule';

type ConnectedP = { internalUserOptions: any };
type DispatchedP = {
    actions: {
        addNoteToClient: (contactData) => void;
    };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
    actions: bindActionCreators(
        {
            addNoteToClient: (contactData) => ClientModule.Actions.addNoteToClient(contactData),
        },
        dispatch,
    ),
});

function mapStateToProps(state): ConnectedP {
    return {
        internalUserOptions: UserModule.Selectors.getInternalUserOptions(state),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
