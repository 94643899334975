import { Client } from '../EnumTypes/ActionEnumTypes';
import { editClient as editClientConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: Client.EDIT_CLIENT_INIT,
});

const success = () => ({
  type: Client.EDIT_CLIENT_SUCCESS,
});

const failure = () => ({
  type: Client.EDIT_CLIENT_FAILURE,
});

const editClient = (clientData) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await editClientConnector(clientData);
    dispatch(success());
    toast.success('Pomyślnie edytowano klienta');
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(ROUTES.CUSTOMER_LIST);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default editClient;
