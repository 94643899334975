const isEqual = (initial, updated) => {
  const newData = {};
  Object.keys(initial).map((key) => {
    if (initial[key] !== updated[key]) {
      return (newData[key] = updated[key]);
    }
    return false;
  });
  return newData;
};

export default isEqual;
