import * as React from 'react';
import { connect } from 'react-redux';
import * as UserModule from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Selectors as UserSelectors } from 'Modules/UserModule';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { history } from 'Store/Reducers/RootReducer';
import ReactTooltip from 'react-tooltip';
import Pagination from '../../../Pagination';

type S = {};
type ConnectedP = {
  prevPath: string;
  internalUsersList: UserModule.Types.InternalUsers;
};
type P = {
  match: any;
};
type DispatchedP = {
  getInternalUsers: (page: any, pageSize?: string) => void;
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => void;
};
class InternalUsersList extends React.Component<DispatchedP & ConnectedP & P, S> {
  async componentDidMount() {
    let { page } = this.props.match.params;
    console.log(page);
    const { internalUsersList } = this.props;
    page = page - 1;
    console.log(page);
    await this.props.getInternalUsers(page && (page >= 0 || page <= internalUsersList.totalPages) ? page : 0, sessionStorage.getItem('pageSize') ? JSON.parse(sessionStorage.getItem('pageSize')).value : '10');
    // await this.props.getInternalUsers();
  }

  public render() {
    const { internalUsersList } = this.props;
    return (
      <div className="internal-users-list">
        <div className="internal-users-list__header">
          <div className="internal-users-list__title">
            <Link to={'/dashboard/settings'}>
              <img src={GrayArrow} alt="" />
            </Link>
            <h2>Lista użytkowników wewnętrznych</h2>
          </div>
          {this.props.internalUsersList && <Pagination pages={this.props.internalUsersList.totalPages} getFunction={this.props.getInternalUsers} path="/dashboard/settings/internal-users/" />}
          <div className="internal-users-list__add-button">
            <button className="btn btn--default" onClick={() => history.push('/dashboard/settings/add-internal-user')}>
              <span>&#43;</span> Dodaj użytkownika
            </button>
          </div>
        </div>
        <div className="internal-users-list__table">
          <table className="table">
            <thead className="filled-thead">
              <tr>
                <th>Lp.</th>
                <th>Imię</th>
                <th>Nazwisko</th>
                <th>Nazwa użytkownika</th>
                <th>Email</th>
                <th>Ostatnie logowanie</th>
                <th className="actions">Narzędzia</th>
              </tr>
            </thead>
            <tbody>
              {internalUsersList &&
                internalUsersList.content.map((internalUser: UserModule.Types.SingleInternalUser, idx: number) => {
                  const { id, firstName, lastName, userName, email, lastLoginDate } = internalUser;
                  return (
                    <>
                      <ReactTooltip place="top" effect="solid" className="tooltip" />
                      <tr className="hoverable" key={idx}>
                        <td>{id}</td>
                        {/* <td className="case">{name}</td> */}
                        <td>{firstName}</td>
                        <td>{lastName}</td>
                        <td>{userName}</td>
                        <td>{email}</td>
                        <td>{lastLoginDate}</td>
                        <td className="actions">
                          <button
                            className="btn-action btn-action--edit"
                            data-tip="Edytuj"
                            onClick={() => {
                              history.push(`/dashboard/settings/edit-internal-user/${id}`);
                            }}
                          />
                          <button
                            className="btn-action btn-action--open"
                            data-tip="Zmień hasło"
                            onClick={() => {
                              history.push(`/dashboard/settings/change-internal-user-password/${id}`);
                            }}
                          />
                          <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => this.props.showConfirmPopup('DeleteInternalUser', internalUser)} />
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  getInternalUsers: (page: any, pageSize?: string) => UserModule.Actions.getInternalUsers(page, pageSize),
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => ViewManagementModule.Actions.showConfirmPopup(typeConfirmPopup, dataToEdit),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    internalUsersList: UserSelectors.internalUsersList(state),
    // internalUsersList: state.userStore.internalUsersList,
    prevPath: ViewManagementSelectors.prevPath(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalUsersList);
