import * as React from 'react';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { Table, ExternalPagination } from 'shared/components';
import { MainType } from './Container';
import Select from 'react-select';

type S = {
  areUsersSelected: boolean;
  page: number;
  size: number;
  searchValue: string;
  filters: any;
  selectedUsers: any;
};

let timer;

class TimeTrialList extends React.Component<MainType, S> {
  constructor(props) {
    super(props);
    this.state = {
      areUsersSelected: true,
      page: 0,
      size: 20,
      searchValue: '',
      selectedUsers: {},
      filters: {
        pageSize: JSON.parse(sessionStorage.getItem('pageSize'))?.value ?? 10,
        pageNo: 0,
      },
    };
  }

  async componentDidMount() {
    await this.props.actions.getAllUsersStats();
    this.props.actions.getInternalUserOptions();
  }

  handleUsersToggle = async () => {
    this.setState({ areUsersSelected: true });
    await this.props.actions.getAllUsersStats();
  };
  handleClientsToggle = async () => {
    this.setState({ areUsersSelected: false });
    const { size, page } = this.state;
    await this.props.actions.getClientStats({ size, page });
  };

  onSizeChange = (size) => {
    this.setState({ size, page: 0 }, () => this.props.actions.getClientStats({ size, page: 0 }));
  };
  onPageChange = (page) => this.setState({ page }, () => this.props.actions.getClientStats({ size: this.state.size, page }));

  handleOnSearch = (value) => {
    this.fetchClients(value);
  };

  fetchClients = (value) => {
    const { size, page } = this.state;
    clearTimeout(timer);
    timer = setTimeout(async () => {
      this.props.actions.getClientStats({ size, page, keyword: value });
    }, 900);
  };

  columnsSchema = () => [
    {
      id: 'company',
      accessor: 'company',
      Header: 'Klient',
      filterable: true,
      filterMethod: () => true,
      Filter: ({ filter, onChange }) => (
        <input
          className="table-filter-input"
          placeholder="Wyszukaj"
          value={filter ? filter.value : ''}
          onChange={({ target: { value } }) => {
            onChange(value);
            this.handleOnSearch(value);
          }}
        />
      ),
    },
    {
      id: 'user',
      Header: 'Użytkownik',
      Cell: ({ original: { clientId, company } }) => (
        <Select
          value={this.state.selectedUsers.hasOwnProperty(clientId) ? this.state.selectedUsers[clientId] : []}
          onChange={(option) => {
            this.props.actions.getClientStatsForUser({ clientId, userId: option?.value || '' });
            this.setState((prevState) => ({
              selectedUsers: {
                ...prevState.selectedUsers,
                [clientId]: option,
              },
            }));
          }}
          noOptionsMessage={() => 'Brak wyników'}
          options={this.props.internalUserOptions}
          menuPortalTarget={document.body}
          isClearable={true}
          className="react-select"
          classNamePrefix="react-select"
          placeholder={'Wszyscy'}
        />
      ),
    },
    {
      id: 'currentMonth',
      accessor: 'currentMonth',
      Header: 'Czas w bieżącym miesiącu',
    },
    {
      id: 'lastMonth',
      accessor: 'lastMonth',
      Header: 'Czas w ostatnim miesiącu',
    },
    {
      id: 'all',
      accessor: 'all',
      Header: 'Czas od początku',
    },
  ];

  public render() {
    const { areUsersSelected } = this.state;
    const { usersStats, clientStats } = this.props;
    const { size, totalPages } = this.props.clientStats;

    return (
      <div className="statistics">
        <div className="statistics__header">
          <div className="statistics__title">
            <Link to="/dashboard">
              <img src={GrayArrow} alt="" />
            </Link>
            <h2>Statystyki</h2>
          </div>
        </div>
        <div className="statistics__options">
          <div className="statistics__toggle">
            <div className={`statistics__block ${areUsersSelected ? 'statistics__block--active' : ''}`} onClick={this.handleUsersToggle}>
              Użytkownicy
            </div>
            <div className={`statistics__block ${!areUsersSelected ? 'statistics__block--active' : ''}`} onClick={this.handleClientsToggle}>
              Klienci
            </div>
          </div>
        </div>
        {!areUsersSelected && <ExternalPagination size={size} pages={totalPages} page={0} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />}
        <div className="statistics__table">
          {areUsersSelected ? (
            <Table
              className="regular-table"
              data={usersStats}
              columns={[
                {
                  id: 'userFullName',
                  accessor: 'userFullName',
                  Header: 'Użytkownik',
                },
                {
                  id: 'today',
                  accessor: 'today',
                  Header: 'Dzisiaj',
                },
                {
                  id: 'currentMonth',
                  accessor: 'currentMonth',
                  Header: 'Ten miesiąc',
                },
                {
                  id: 'lastMonth',
                  accessor: 'lastMonth',
                  Header: 'Poprzedni miesiąc',
                },
                {
                  id: 'all',
                  accessor: 'all',
                  Header: 'Czas od początku',
                },
                {
                  id: 'asExecutive1',
                  accessor: 'asExecutive1',
                  Header: 'Jako wykonawca',
                },
                {
                  id: 'asExecutive2',
                  accessor: 'asExecutive2',
                  Header: 'Jako podwykonawca',
                },
              ]}
            />
          ) : (
            <Table className="regular-table" data={clientStats?.content} columns={this.columnsSchema()} />
          )}
        </div>
      </div>
    );
  }
}

export default TimeTrialList;
