import { CASES } from '../EnumTypes/ActionEnumTypes';
import { getCaseFilesListExternal } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: CASES.GET_CASE_FILES_INIT,
});

const success = (data) => ({
  type: CASES.GET_CASE_FILES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CASES.GET_CASE_FILES_FAILURE,
});

const getCaseFilesExternal = (caseId, params) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showFileLoader());
    const { data } = await getCaseFilesListExternal(caseId, params);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideFileLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideFileLoader());
    dispatch(failure());
  }
};

export default getCaseFilesExternal;
