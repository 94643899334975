import * as React from 'react';
import { connect } from 'react-redux';
import * as ViewManagementModule from '../../Modules/ViewManagementModule';
import { RootState, history } from '../../Store/Reducers/RootReducer';
import * as UserModule from '../../Modules/UserModule';
import * as TimeTrialModule from '../../Modules/TimeTrialModule';
import * as ClientModule from '../../Modules/ClientModule';
import * as ReminderModule from '../../Modules/ReminderModule';
import { deleteCase } from 'Store/Actions/cases';
import { ROUTES } from 'shared/consts'

type DispatchedP = {
  hideConfirmPopup: () => void;
  deleteSpecificUser: (id: number, type: string) => void;
  deleteTimeTrial: (id: number) => void;
  deleteClient: (id: number) => void;
  deleteClientNote: (id: number) => void;
  deleteClientContact: (id: number, clientId: number) => void;
  rejectReminder: (id: number) => void;
  deleteCase: (id: number) => void;
};

type ConnectedP = {
  dataToEdit: any;
  typeConfirmPopup: string;
  router: any;
};

type P = {
  match?: any;
};
type S = {
  isLeaveAnimation: boolean;
};

class ConfirmationPopup extends React.Component<DispatchedP & ConnectedP & P, S> {
  constructor(props: DispatchedP & ConnectedP & P) {
    super(props);
    this.state = {
      isLeaveAnimation: false,
    };
  }

  public handleChildClick(e: any) {
    e.stopPropagation();
  }

  closePopup = () => {
    this.setState({ isLeaveAnimation: true }, () => {
      setTimeout(() => {
        this.props.hideConfirmPopup();
        this.setState({ isLeaveAnimation: false });
      }, 180);
    });
  };

  public generateContent() {
    switch (this.props.typeConfirmPopup) {
      case 'DeleteInternalUser': {
        return (
          <>
            <h2>Jesteś pewien?</h2>
            <h6>Czy chcesz usunąć te dane?</h6>
            <div className="confirmation-popup__buttons">
              <button className={`btn btn--cancel`} onClick={this.closePopup}>
                Anuluj
              </button>
              <button className={`btn btn--confirm`} onClick={() => this.props.deleteSpecificUser(this.props.dataToEdit.id, 'INTERNAL')}>
                Potwierdź
              </button>
            </div>
          </>
        );
      }
      case 'DeleteExternalUser': {
        return (
          <>
            <h2>Jesteś pewien?</h2>
            <h6>Czy chcesz usunąć te dane?</h6>
            <div className="confirmation-popup__buttons">
              <button className={`btn btn--cancel`} onClick={this.closePopup}>
                Anuluj
              </button>
              <button className={`btn btn--confirm`} onClick={() => this.props.deleteSpecificUser(this.props.dataToEdit.id, 'EXTERNAL')}>
                Potwierdź
              </button>
            </div>
          </>
        );
      }
      case 'DeleteTimeTrial': {
        return (
          <>
            <h2>Jesteś pewien?</h2>
            <h6>Czy chcesz usunąć te dane?</h6>
            <div className="confirmation-popup__buttons">
              <button className={`btn btn--cancel`} onClick={this.closePopup}>
                Anuluj
              </button>
              <button className={`btn btn--confirm`} onClick={() => this.props.deleteTimeTrial(this.props.dataToEdit.id)}>
                Potwierdź
              </button>
            </div>
          </>
        );
      }
      case 'DeleteClient': {
        return (
          <>
            <h2>Jesteś pewien?</h2>
            <h6>Czy chcesz usunąć te dane?</h6>
            <div className="confirmation-popup__buttons">
              <button className={`btn btn--cancel`} onClick={this.closePopup}>
                Anuluj
              </button>
              <button className={`btn btn--confirm`} onClick={() => this.props.deleteClient(this.props.dataToEdit.id)}>
                Potwierdź
              </button>
            </div>
          </>
        );
      }
      case 'DeleteNote': {
        return (
          <>
            <h2>Jesteś pewien?</h2>
            <h6>Czy chcesz usunąć notatkę?</h6>
            <div className="confirmation-popup__buttons">
              <button className={`btn btn--cancel`} onClick={this.closePopup}>
                Anuluj
              </button>
              <button className={`btn btn--confirm`} onClick={() => this.props.deleteClientNote(this.props.dataToEdit)}>
                Potwierdź
              </button>
            </div>
          </>
        );
      }
      case 'RejectReminder': {
        return (
          <>
            <h2>Jesteś pewien?</h2>
            <h6>Czy chcesz odrzucić przypomnienie?</h6>
            <div className="confirmation-popup__buttons">
              <button className={`btn btn--cancel`} onClick={this.closePopup}>
                Anuluj
              </button>
              <button className={`btn btn--confirm`} onClick={() => this.props.rejectReminder(this.props.dataToEdit.id)}>
                Odrzuć
              </button>
            </div>
          </>
        );
      }
      case 'DeleteClientContact': {
        return (
          <>
            <h2>Jesteś pewien?</h2>
            <h6>Czy chcesz usunąć te dane?</h6>
            <div className="confirmation-popup__buttons">
              <button className={`btn btn--cancel`} onClick={this.closePopup}>
                Anuluj
              </button>
              <button className={`btn btn--confirm`} onClick={() => this.props.deleteClientContact(this.props.dataToEdit.id, this.props.dataToEdit.clientId)}>
                Potwierdź
              </button>
            </div>
          </>
        );
      }
      case 'DeleteCase': {
        return (
          <>
            <h2>Jesteś pewien?</h2>
            <h6>Czy chcesz usunąć tą sprawe?</h6>
            <div className="confirmation-popup__buttons">
              <button className={`btn btn--cancel`} onClick={this.closePopup}>
                Anuluj
              </button>
              <button className={`btn btn--confirm`} onClick={() => {this.props.deleteCase(this.props.dataToEdit.id); history.push(ROUTES.DASHBOARD)}}>
                Potwierdź
              </button>
            </div>
          </>
        );
      }
      default: {
        return null;
      }
    }
  }
  public render() {
    return (
      <div className="confirmation-popup" onClick={this.closePopup}>
        <div className={`confirmation-popup__inner ${this.state.isLeaveAnimation ? 'confirmation-popup__inner--hidePopup' : ''}`} onClick={this.handleChildClick}>
          <div className="confirmation-popup__content">{this.generateContent()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState): ConnectedP {
  return {
    dataToEdit: state.viewManagementStore.dataToEdit,
    typeConfirmPopup: state.viewManagementStore.typeConfirmPopup,
    router: state.router,
  };
}
const mapDispachToProps: DispatchedP = {
  hideConfirmPopup: () => ViewManagementModule.Actions.hideConfirmPopup(),
  deleteSpecificUser: (id: number, type: string) => UserModule.Actions.deleteSpecificUser(id, type),
  deleteTimeTrial: (id: number) => TimeTrialModule.Actions.deleteTimeTrial(id),
  deleteClient: (id: number) => ClientModule.Actions.deleteClient(id),
  deleteClientNote: (id: number) => ClientModule.Actions.deleteClientNote(id),
  deleteClientContact: (id: number, clientId: number) => ClientModule.Actions.deleteClientContact(id, clientId),
  rejectReminder: (id: number) => ReminderModule.Actions.rejectReminder(id),
  deleteCase: (id: number) => deleteCase(id),
};
export default connect(mapStateToProps, mapDispachToProps)(ConfirmationPopup);
