import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: User.GET_SIDEBAR_COUNTS_INIT,
});

const success = (sidebarCounts) => ({
    type: User.GET_SIDEBAR_COUNTS_SUCCESS,
    payload: {
        sidebarCounts,
    },
});

const failure = () => ({
    type: User.GET_SIDEBAR_COUNTS_FAILURE,
});

const getSidebarCounts = () => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        const { data: { sidebarCounts } } = await Connector.getSidebarCounts();
        dispatch(success(sidebarCounts));
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getSidebarCounts;
