import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions, Selectors } from 'Modules/GroupModule';
import Main from './Main';

type ConnectedP = {
  groupUsers: any;
};

type DispatchedP = {
  actions: {
    getGroupUsers: (id) => void;
    updateGroupUsers: (groupId, usersId, history) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getGroupUsers: (id) => Actions.getGroupUsers(id),
      updateGroupUsers: (groupId, usersId, history) => Actions.updateGroupUsers(groupId, usersId, history),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  groupUsers: Selectors.getGroupUsers(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
