const mapFormValuesToRequestData = ({ firstName, lastName, phone, email }, clients) => ({
  clients: mapClientsToRequest(clients),
  user: {
    firstName,
    lastName,
    phone: +phone,
    email,
  },
});

const swapUndefinedForFalse = (val) => (val === undefined || val === false ? false : true);

const mapClientsToRequest = (clients) =>
  clients.map(({ id, req: { contracts, court, various, other, vindication } }) => ({
    id,
    contracts: swapUndefinedForFalse(contracts),
    court: swapUndefinedForFalse(court),
    various: swapUndefinedForFalse(various),
    other: swapUndefinedForFalse(other),
    vindication: swapUndefinedForFalse(vindication),
  }));

const initializeClients = (clients) =>
  clients.map((client) => ({
    details: {
      id: client.id,
      name: client.company,
    },
    id: client.id,
    req: {
      contracts: swapUndefinedForFalse(client.contracts),
      court: swapUndefinedForFalse(client.court),
      various: swapUndefinedForFalse(client.various),
      other: swapUndefinedForFalse(client.other),
      vindication: swapUndefinedForFalse(client.vindication),
    },
  }));

export { mapFormValuesToRequestData, initializeClients, mapClientsToRequest };
