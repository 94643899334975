import * as React from 'react';
import { generatePath } from 'react-router';
import { Breadcrumbs, Button, Table } from 'shared/components';
import { MainType } from './Container';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';
import CustomerDetails from './components/CustomerDetails';
import { getColumnsForContacts, getColumnsForNotes } from './columns';

type P = {
  match: any;
  history: any;
};

class Main extends React.Component<MainType & P, any> {
  async componentDidMount() {
    const { getClientDetails, getClientNotes, getClientContacts } = this.props.actions;
    getClientDetails(this.props.match.params.id);
    getClientNotes(this.props.match.params.id);
    getClientContacts(this.props.match.params.id);
  }

  handleContactDelete = (contactId) => {
    this.props.actions.showConfirmPopup('DeleteClientContact', { id: contactId, clientId: this.props.match.params.id });
  };

  handleNoteDelete = (noteId) => {
    this.props.actions.showConfirmPopup('DeleteNote', { id: noteId, customerId: this.props.match.params.id });
  };

  render() {
    // const { caseData: { caseDetails = {}, statusDescriptions = [], reminders = [] } = {}, history } = this.props;
    const { path, params } = this.props.match;
    const { customerDetails, customerContacts, customerNotes } = this.props;
    const customerContactsColumns = getColumnsForContacts(this.handleContactDelete);
    const customerNotesColumns = getColumnsForNotes(this.handleNoteDelete);
    // const columnRemainders = getColumnsForRemainders(this.redirectToRemaiderReply, this.postponeReminder);

    // console.log(customerNotes.content)
    customerNotes.content && customerNotes.content.sort(function(a, b) {
      return parseFloat(b.id) - parseFloat(a.id);
    });
    return (
      <div className="caseDetails">
        <Breadcrumbs pathname={path} />
        <div className="caseDetails__narrowSection">
          <div className="caseDetails__summary">
            <h2 className="main-subtitle">Dane klienta</h2>
            <CustomerDetails customerDetails={customerDetails} />
          </div>
          <div className="caseDetails__status">
            <h2 className="main-subtitle flex-subtitle">
              Osoby kontaktowe <Button label={'+ Dodaj'} onClick={() => history.push(generatePath(ROUTES.ADD_CONTACT, { id: params.id }))} />
            </h2>
            <Table className="regular-table" data={customerContacts.content} columns={customerContactsColumns} />
          </div>
          <div className="caseDetails__status">
            <h2 className="main-subtitle flex-subtitle">
              Notatki <Button label={'+ Dodaj'} onClick={() => history.push(generatePath(ROUTES.ADD_NOTE, { id: params.id }))} />
            </h2>
            <Table className="regular-table" data={customerNotes.content} columns={customerNotesColumns} />
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
