import CustomersList from './CustomersList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AuthModule from 'Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from '../../../Store/Reducers/RootReducer';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import { Actions as ClientActions, Selectors as ClientSelector } from 'Modules/ClientModule';
import { Selectors as ViewManagementSelectors, Actions as ViewManagemenetActions } from 'Modules/ViewManagementModule';

type ConnectedP = {
  isMenuExpanded: boolean;
  authorizedUser: AuthModule.Types.AuthorizedUserData;
  clientsListData: any;
};
type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
  actions: {
    getClientsList: (params) => void;
    showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => void;
    manageFavourite: (clientId: number, isFavourite: boolean) => void;
    getClientsXlsx: (params) => void;
  };
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  actions: bindActionCreators(
    {
      getClientsList: (params) => ClientActions.getClientsList(params),
      showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => ViewManagemenetActions.showConfirmPopup(typeConfirmPopup, dataToEdit),
      manageFavourite: (clientId: number, isFavourite: boolean) => ClientActions.manageFavourite(clientId, isFavourite),
      getClientsXlsx: (params) => ClientActions.getClientsXlsx(params),
    },
    dispatch,
  ),
});

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    authorizedUser: AuthSelectors.authorizedUser(state),
    clientsListData: ClientSelector.getClientsListData(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);
