import * as React from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import RegularField from 'Components/Forms/Fields/RegularField';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as ReminderModule from 'Modules/ReminderModule';
import * as Yup from 'yup';
import { RootState } from '../../../../Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Link } from 'react-router-dom';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import moment from 'moment';

type S = {};
type P = {
  match: any;
};
type ConnectedP = {
  isMenuExpanded: boolean;
  reminderList: any;
};
type DispatchedP = {
  expandMenu: () => void;
  respondReminder: (reminderData) => void;
};
class RespondReminder extends React.Component<DispatchedP & ConnectedP & P, S> {
  state = {
    activeReminder: null,
  };
  componentDidMount() {
    console.log(this.props.match.params.id);
    console.log(this.props.reminderList);
    const reminderList = this.props.reminderList.reminders;
    reminderList && this.setState({ activeReminder: reminderList.find((reminder) => reminder.id === Number(this.props.match.params.id)) });
  }

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };
  public render() {
    const RespondReminderSchema = Yup.object().shape({
      about: Yup.string().required('Pole wymagane'),
      remindAt: Yup.string().required('Pole wymagane'),
    });

    const { id } = this.props.match.params;
    const { activeReminder } = this.state;

    return (
      <div className="add-reminder">
        <div className="add-reminder__title">
          <Link to="/dashboard">
            <img src={GrayArrow} alt="" />
          </Link>
          <h2>Odpowiedz na przypomnienie</h2>
        </div>
        <div className="add-reminder__form-container">
          <Formik
            initialValues={{ about: '', remindAt: moment().format('YYYY-MM-DD') }}
            validationSchema={RespondReminderSchema}
            onSubmit={async (values) => {
              console.log(values, 'values');
              await this.props.respondReminder({ reminderResponseDto: { ...values, id } });
            }}>
            {({ handleSubmit, errors, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="add-reminder__form">
                <p className="respond-text" dangerouslySetInnerHTML={{ __html: activeReminder?.about || '' }} />
                <Field name="about" type="textarea" wrapperClassName="default" placeholder="Wpisz treść przypomnienia" label="Treść przypomnienia" component={RegularField} errors={errors} />
                <Field name="remindAt" type="date" wrapperClassName="default" placeholder="Podaj datę" label="Data" component={RegularField} errors={errors} />
                <button className="btn btn--default btn--login btn--center" type="submit">
                  Odpowiedz
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  respondReminder: (reminderData) => ReminderModule.Actions.respondReminder(reminderData),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    reminderList: ReminderModule.Selectors.getRemindersList(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RespondReminder);
