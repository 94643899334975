import API from './Config';
import { AxiosPromise } from 'axios';

export function getRemindersList(main): AxiosPromise<any> {
  return API.get(`reminder?main=${main}`);
}

export function getActiveRemidersList(): AxiosPromise<any> {
  return API.get(`reminder/main-view`);
}

export function getAllRemindersList(params): AxiosPromise<any> {
  return API.get(`reminder/full-view?sort=remindAt,desc`, { params: { size: params.size, page: params.page } });
}

export function addReminder(reminderData): AxiosPromise<any> {
  return API.post(`reminder`, reminderData);
}

export function postponeReminder(reminderData): AxiosPromise<any> {
  return API.patch(`reminder`, reminderData);
}

export function rejectReminder(id): AxiosPromise<any> {
  return API.patch(`reminder/reject/${id}`);
}

export function respondReminder(reminderData): AxiosPromise<any> {
  return API.post(`reminder/respond`, reminderData);
}
