import * as React from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { ROUTES } from 'shared/consts';
import * as AuthModule from '../../../../Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { RootState } from '../../../../Store/Reducers/RootReducer';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import * as ReminderModule from 'Modules/ReminderModule';
import { history } from 'Store/Reducers/RootReducer';
import ReactTooltip from 'react-tooltip';
import Pagination from 'Components/Pagination';
import { checkIfReminderIsExpired } from './utils';

type S = { main: boolean; filters: any };

type ConnectedP = {
  isMenuExpanded: boolean;
  isAddReminderView: boolean;
  authorizedUser: AuthModule.Types.AuthorizedUserData;
  remindersList: any;
};
type DispatchedP = {
  logout: () => void;
  expandMenu: () => void;
  getRemindersList: (main: boolean) => void;
  getActiveRemindersList: () => void;
  getAllRemindersList: (params) => void;
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => void;
};
class RemindersList extends React.Component<DispatchedP & ConnectedP, S> {
  constructor(props: DispatchedP & ConnectedP) {
    super(props);
    this.state = {
      main: true,
      filters: {
        size: JSON.parse(sessionStorage.getItem('pageSize'))?.value ?? 10,
        page: 0,
      },
    };
  }

  componentDidMount() {
    this.props.getActiveRemindersList();
  }

  closeMenu = () => {
    if (this.props.isMenuExpanded) {
      this.props.expandMenu();
    }
  };

  handleMainChange = () => {
    this.setState(
      (prevState) => ({ main: !prevState.main }),
      () => (this.state.main ? this.props.getActiveRemindersList() : this.props.getAllRemindersList(this.state.filters)),
    );
  };

  handlePageOrSizeChange = (...args) => {
    const [page, size] = args;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          page,
          size,
        },
      },
      async () => await this.props.getAllRemindersList(this.state.filters),
    );
  };

  public render() {
    const { remindersList } = this.props;
    const { main } = this.state;

    return (
      <div className="reminders-list">
        <div className="reminders-list__header">
          <div className="reminders-list__title">
            <h2>Lista przypomnień</h2>
          </div>
          {!this.state.main && remindersList.content && <Pagination pages={remindersList.totalPages} getFunction={this.handlePageOrSizeChange} pageNo={this.state.filters.page} />}
          <div className="reminders-list__buttons">
            <a href="/dashboard/add-time-trial" className="reminders-list__add-button">
              <button
                className="btn btn--default"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/dashboard/add-time-trial');
                }}>
                <span className="img" role="img" aria-label="Panda">
                  🕐
                </span>{' '}
                Dodaj Czasówkę
              </button>
            </a>
            <a href="/dashboard/add-reminder" className="reminders-list__add-button">
              <button
                className="btn btn--default"
                onClick={(e) => {
                  e.preventDefault();
                  history.push('/dashboard/add-reminder');
                }}>
                <span>&#43;</span> Dodaj przypomnienie
              </button>
            </a>
          </div>
        </div>
        <div className="time-trial-list__export">
          <div onClick={this.handleMainChange} className={main ? 'time-trial-list__export--active' : ''}>
            Aktywne przypomnienia
          </div>
          <div onClick={this.handleMainChange} className={!main ? 'time-trial-list__export--active' : ''}>
            Wszystkie przypomnienia
          </div>
        </div>
        <div className="reminders-list__table">
          <table className="table">
            <thead>
              <tr>
                <th className="actions">Akcje</th>
                <th>
                  Przypisany do <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Klient
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Karta sprawy
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Zlecający
                  <br />
                  <input type="text" name="" id="" />
                </th>
                <th className="remind">
                  Przypomnij o<br />
                  <input type="text" name="" id="" />
                </th>
                <th>
                  Przypomnij w dniu
                  <br />
                  <input type="text" name="" id="" />
                </th>
              </tr>
            </thead>

            <tbody>
              {this.state.main &&
                remindersList.reminders &&
                remindersList.reminders.map((reminder: any) => {
                  const { toUserFullName, userFullName, clientName, caseNumber, about, remindAt, id, clientId, caseId } = reminder;
                  const isColored = checkIfReminderIsExpired(remindAt) && main;
                  const singleReminder = { remindAt: remindAt, about: about };
                  const singleReminderAfterStringify = JSON.stringify(singleReminder);
                  return (
                    <>
                      <ReactTooltip place="top" effect="solid" className="tooltip" />
                      <tr className={`hoverable ${isColored ? 'colored' : ''}`} key={id}>
                        <td className="actions">
                          <button className="btn-action btn-action--reply" data-tip="Odpowiedz" onClick={() => history.push(generatePath(ROUTES.RESPOND_REMINDER, { id }))}></button>
                          <button
                            className="btn-action btn-action--edit"
                            data-tip="Edytuj"
                            onClick={() => {
                              history.push(generatePath(ROUTES.POSTPONE_REMINDER, { id }));
                              sessionStorage.setItem('reminder', singleReminderAfterStringify);
                            }}></button>
                          <button className="btn-action btn-action--cancel" data-tip="Odrzuć" onClick={() => this.props.showConfirmPopup('RejectReminder', { id })}></button>
                        </td>
                        <td> {toUserFullName}</td>
                        <td className="case">
                          <a
                            href={clientId && generatePath(ROUTES.CUSTOMER_CARD, { id: clientId })}
                            className="pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(generatePath(ROUTES.CUSTOMER_CARD, { id: clientId }));
                            }}>
                            {clientName}
                          </a>
                        </td>
                        <td className="case">
                          <a
                            href={caseId && generatePath(ROUTES.CASE_DETAILS, { id: caseId })}
                            className="pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              history.push(generatePath(ROUTES.CASE_DETAILS, { id: caseId }));
                            }}>
                            {caseNumber}
                          </a>
                        </td>
                        <td>{userFullName}</td>
                        {/* <td className="remind">{sanitizeHtml(about)}</td> */}
                        <td className="remind" dangerouslySetInnerHTML={{ __html: about }} />
                        <td>{remindAt}</td>
                      </tr>
                    </>
                  );
                })}
              {!this.state.main &&
                remindersList.content &&
                remindersList.content.map((reminder: any) => {
                  const { toUserFullName, userFullName, clientName, caseNumber, about, remindAt, id, clientId, caseId } = reminder;
                  const isColored = checkIfReminderIsExpired(remindAt) && main;
                  return (
                    <>
                      <ReactTooltip place="top" effect="solid" className="tooltip" />
                      <tr className={`hoverable ${isColored ? 'colored' : ''}`} key={id}>
                        <td className="actions">
                          <button className="btn-action btn-action--reply" data-tip="Odpowiedz" onClick={() => history.push(generatePath(ROUTES.RESPOND_REMINDER, { id }))}></button>
                          <button className="btn-action btn-action--date" data-tip="Odłóż" onClick={() => history.push(generatePath(ROUTES.POSTPONE_REMINDER, { id }))}></button>
                          <button className="btn-action btn-action--cancel" data-tip="Odrzuć" onClick={() => this.props.showConfirmPopup('RejectReminder', { id })}></button>
                        </td>
                        <td> {toUserFullName}</td>
                        <td className="case">
                          <div
                            className="pointer"
                            onClick={() => {
                              history.push(generatePath(ROUTES.CUSTOMER_CARD, { id: clientId }));
                            }}>
                            {clientName}
                          </div>
                        </td>
                        <td className="case">
                          <div
                            className="pointer"
                            onClick={() => {
                              history.push(generatePath(ROUTES.CASE_DETAILS, { id: caseId }));
                            }}>
                            {caseNumber}
                          </div>
                        </td>
                        <td>{userFullName}</td>
                        {/* <td className="remind">{sanitizeHtml(about)}</td> */}
                        <td className="remind" dangerouslySetInnerHTML={{ __html: about }} />
                        <td>{remindAt}</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps: DispatchedP = {
  logout: () => AuthModule.Actions.logout(),
  expandMenu: () => ViewManagementModule.Actions.expandMenu(),
  getRemindersList: (main) => ReminderModule.Actions.getRemindersList(main),
  getAllRemindersList: (params) => ReminderModule.Actions.getAllRemindersList(params),
  getActiveRemindersList: () => ReminderModule.Actions.getActiveRemindersList(),
  showConfirmPopup: (typeConfirmPopup: string, dataToEdit: any) => ViewManagementModule.Actions.showConfirmPopup(typeConfirmPopup, dataToEdit),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    isMenuExpanded: ViewManagementSelectors.isMenuExpanded(state),
    isAddReminderView: ViewManagementSelectors.isAddReminderView(state),
    authorizedUser: AuthSelectors.authorizedUser(state),
    remindersList: ReminderModule.Selectors.getRemindersList(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemindersList);
