/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import * as React from 'react';
// import ReactTooltip from 'react-tooltip';
import { history } from 'Store/Reducers/RootReducer';
import { generatePath } from 'react-router-dom';
import { ROUTES } from 'shared/consts';

const getColumnsForContacts = (handleContactDelete) => [
  {
    id: 'id',
    accessor: 'id',
    Header: 'Narzędzia',
    width: 90,
    Cell: ({ original: { id } }) => (
      <>
        <button className="btn-action btn-action--edit" data-tip="Edytuj" onClick={() => history.push(generatePath(ROUTES.EDIT_CONTACT, { id }))}></button>
        <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => handleContactDelete(id)}></button>
      </>
    ),
  },
  {
    id: 'surname',
    accessor: 'surname',
    Header: 'Nazwisko',
  },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Imię',
  },
  {
    id: 'email',
    accessor: 'email',
    Header: 'Mail',
    Cell: ({ original: { email } }) => (
      <>
      <a className='email' href={`mailto:${email}`}>{email}</a>
      </>
    ),
  },
  {
    id: 'phone',
    accessor: 'phone',
    Header: 'Telefon',
  },
  {
    id: 'status',
    accessor: 'status',
    Header: 'Status',
  },
];

const getColumnsForNotes = (handleNoteDelete) => [
  {
    id: 'id',
    accessor: 'id',
    Header: 'Narzędzia',
    width: 90,
    Cell: ({ original: { id } }) => (
      <>
        <button className="btn-action btn-action--edit" data-tip="Edytuj" onClick={() => history.push(generatePath(ROUTES.EDIT_NOTE, { id }))}></button>
        <button className="btn-action btn-action--delete" data-tip="Usuń" onClick={() => handleNoteDelete(id)}></button>
      </>
    ),
  },
  {
    Header: 'Nr notatki',
    width: 110,
    Cell: ({ original: { id, dailyId } }) => (
      <>
        {dailyId ? dailyId : id }
      </>
    ),
  },
  {
    id: 'valued',
    accessor: 'value',
    Header: 'Treść notatki',
  },
];

export { getColumnsForContacts, getColumnsForNotes };
