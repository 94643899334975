import { CASES } from '../EnumTypes/ActionEnumTypes';
import { getFavouriteCases as getFavouriteCasesConnector } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: CASES.GET_FAVOURITE_CASES_INIT,
});

const success = (data) => ({
    type: CASES.GET_FAVOURITE_CASES_SUCCESS,
    payload: {
        data,
    },
});

const failure = () => ({
    type: CASES.GET_FAVOURITE_CASES_FAILURE,
});

const getFavouriteCases = () => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        const { data } = await getFavouriteCasesConnector();
        dispatch(success(data));
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getFavouriteCases;
