import * as React from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
// import { generatePath } from 'react-router';
// import RegularField from 'Components/Forms/Fields/RegularField';
// import { Field } from 'formik';
import { MainType } from './Container';
import GrayArrow from 'Assets/Img/Icons/gray-arrow-icon.svg';
import { ROUTES } from 'shared/consts';
// import BasicData from './components/basicData';
// import DetailedData from './components/detailedData';
import * as Yup from 'yup';
// import { STEP_TITLE, STEP_BUTTON_LABEL, VALIDATION_SCHEMA } from './consts';
import BasicData from '../AddCase/components/basicData';
import DetailedData from '../AddCase/components/detailedData';
// import { CASE_TYPES } from './consts';
import { ErrorFocus } from 'shared/utils';

type P = {
  location: any;
  match: any;
};

class Main extends React.Component<MainType & P, any> {
  constructor(props: MainType & P) {
    super(props);
    this.state = {
      numberOfClients: 0,
      clients: [],
      step: 0,
      caseType: '',
    };
  }

  async componentDidMount() {
    await this.props.actions.getCaseDetails(this.props.match.params.id);
    this.props.actions.getInternalUserOptions();
    this.setState({ caseType: this.props.caseData?.caseDetails?.caseType });
  }

  handleSubmit = async (values) => {
    // if (this.state.step === 0) {
    //   this.setState({ step: 1, caseType: values.caseType });
    // } else {
    this.props.actions.editCase(values);
    // }
  };

  handleGoBack = () => this.setState({ step: 0 });

  addCaseSchema = () => {
    // const { caseType } = this.state;
    return Yup.object().shape({
        // clientId: Yup.string().required('Pole wymagane'),
      // ordering: Yup.string().required('Pole wymagane'),
      executive1Id: Yup.string().required('Pole wymagane'),
      executive2Id: Yup.string().required('Pole wymagane'),
      caseType: Yup.string().required('Pole wymagane'),
      //   ...(step === 1 && {
      name: Yup.string().required('Pole wymagane'),
      // tags: Yup.string().required('Pole wymagane'),
      //   }),
      // ...(caseType === CASE_TYPES.VARIOUS && {
      //   details: Yup.string().required('Pole wymagane'),
      // }),
      // ...((caseType === CASE_TYPES.COURT || caseType === CASE_TYPES.VINDICATION) && {
      //   plaintiff: Yup.string().required('Pole wymagane'),
      //   defendant: Yup.string().required('Pole wymagane'),
      //   wps: Yup.string().required('Pole wymagane'),
      //   signature: Yup.string().required('Pole wymagane'),
      //   kind: Yup.string().required('Pole wymagane'),
      // }),
      // ...(caseType === CASE_TYPES.CONTRACT && {
      //   contractor: Yup.string().required('Pole wymagane'),
      //   about: Yup.string().required('Pole wymagane'),
      //   kind: Yup.string().required('Pole wymagane'),
      // }),
      // ...(caseType === CASE_TYPES.OTHER && {
      //   details: Yup.string().required('Pole wymagane'),
      //   sign1: Yup.string().required('Pole wymagane'),
      //   sign2: Yup.string().required('Pole wymagane'),
      //   kind: Yup.string().required('Pole wymagane'),
      // }),
    });
  };

  render() {
    // const { params } = this.props.match;
    // const { caseNo } = this.props.location.state;
    const { internalUserOptions, caseData } = this.props;
    // const initialValues = {
    //   clientId: '',
    //   ordering: '',
    //   contractor: '',
    //   executive1Id: '',
    //   executive2Id: '',
    //   caseType: '',
    //   name: '',
    //   tags: '',
    //   details: '',
    //   plaintiff: '',
    //   defendant: '',
    //   wps: '',
    //   kind: '',
    //   signature: '',
    //   sign1: '',
    //   sign2: '',
    //   about: '',
    // };

    const initialValues = caseData?.caseDetails || {};
    // const { step } = this.state;
    return (
      <Formik initialValues={initialValues} validationSchema={this.addCaseSchema} enableReinitialize={true} onSubmit={this.handleSubmit}>
        {({ handleSubmit, errors, setFieldValue, values: { caseType }, validateOnBlur }) => (
          <div className="add-customer--details__wrapper">
            <div className="add-customer add-customer--details">
              <div className="add-customer--details__inner">
                <div className="add-customer__title">
                  <Link to={ROUTES.CASES_LIST_CONTRACTOR}>
                    <img src={GrayArrow} alt="" />
                  </Link>
                  <h2>Edytuj sprawę</h2>
                </div>
                <div className="add-customer__form-container">
                  <form onSubmit={handleSubmit} className="form add-customer__form">
                    <ErrorFocus />
                    <BasicData errors={errors} setFieldValue={setFieldValue} internalUserOptions={internalUserOptions} isEdit />
                    <DetailedData errors={errors} setFieldValue={setFieldValue} caseType={caseType} />
                    <button className="btn btn--default btn--login btn--center" type="submit">
                      {/* {STEP_BUTTON_LABEL[step]} */}
                      Edytuj
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    );
  }
}

export default Main;
