import { Client } from '../EnumTypes/ActionEnumTypes';
import { editClientSingleContact as editClientSingleContactConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
// import { ROUTES } from 'shared/consts';
// import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: Client.EDIT_SINGLE_CLIENT_INIT,
});

const success = () => ({
  type: Client.EDIT_SINGLE_CLIENT_SUCCESS,
});

const failure = () => ({
  type: Client.EDIT_SINGLE_CLIENT_FAILURE,
});

const editClientSingleContact = (data) => async (dispatch) => {
  dispatch(init());
  try {
    await editClientSingleContactConnector(data);
    dispatch(success());
    toast.success('Pomyślnie edytowano osobę kontaktową');
    dispatch(ViewManagementModule.Actions.hideLoader());
    // history.push(ROUTES.CUSTOMER_LIST);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default editClientSingleContact;
