import { CASES } from '../EnumTypes/ActionEnumTypes';
import { generatePath } from 'react-router';
import { createCaseStatus } from 'Connectors/CaseConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'shared/consts';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: CASES.ADD_CASE_STATUS_DESCRIPTION_INIT,
});

const success = () => ({
  type: CASES.ADD_CASE_STATUS_DESCRIPTION_SUCCESS,
});

const failure = () => ({
  type: CASES.ADD_CASE_STATUS_DESCRIPTION_FAILURE,
});

const addCaseStatusDescription =
  ({ body, shared }, caseId) =>
  async (dispatch) => {
    dispatch(init());
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      await createCaseStatus({ body, shared, caseId: Number(caseId) });
      dispatch(success());
      toast.success('Pomyślnie dodano wpis do sprawy');
      dispatch(ViewManagementModule.Actions.hideLoader());
      history.push(generatePath(ROUTES.CASE_DETAILS, { id: caseId }));
    } catch (err) {
      dispatch(ViewManagementModule.Actions.hideLoader());
      toast.error('Nie udało się dodać wpisu do sprawy');
      dispatch(failure());
    }
  };

export default addCaseStatusDescription;
