import { User } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/UserModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: User.GET_ALL_USERS_STATS_INIT,
});

const success = (stats) => ({
    type: User.GET_ALL_USERS_STATS_SUCCESS,
    payload: {
        stats,
    },
});

const failure = () => ({
    type: User.GET_ALL_USERS_STATS_FAILURE,
});

const getAllUsersStats = () => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        const { data: { userStats } } = await Connector.getAllUsersStats();
        dispatch(success(userStats));
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getAllUsersStats;
