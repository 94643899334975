import { Client } from '../EnumTypes/ActionEnumTypes';
import { getClientSingleContact as getClientSingleContactConnector } from 'Connectors/ClientConnector';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Client.GET_CLIENT_SINGLE_CONTACT_INIT,
});

const success = (clientSingleContact) => ({
  type: Client.GET_CLIENT_SINGLE_CONTACT_SUCCESS,
  payload: {
    clientSingleContact,
  },
});

const failure = () => ({
  type: Client.GET_CLIENT_SINGLE_CONTACT_FAILURE,
});

const getClientSingleContact = (id) => async (dispatch) => {
  dispatch(init());
  try {
    // dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await getClientSingleContactConnector(id);
    dispatch(success(data));
    // dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    // dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getClientSingleContact;
