import { Client } from '../EnumTypes/ActionEnumTypes';
import { getClientStatsForUser as getClientStatsForUserConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
    type: Client.GET_CLIENT_STATS_FOR_USER_INIT,
});

const success = (data) => ({
    type: Client.GET_CLIENT_STATS_FOR_USER_SUCCESS,
    payload: {
        data,
    },
});

const failure = () => ({
    type: Client.GET_CLIENT_STATS_FOR_USER_FAILURE,
});

const getClientStatsForUser = (params) => async (dispatch) => {
    dispatch(init());
    try {
        dispatch(ViewManagementModule.Actions.showLoader());
        const { data: { clientUserStatsDto } } = await getClientStatsForUserConnector(params);
        dispatch(success({ ...clientUserStatsDto, ...params }));
        dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
        dispatch(ViewManagementModule.Actions.hideLoader());
        dispatch(failure());
    }
};

export default getClientStatsForUser;
