import Files from './Files';
import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { getCaseFiles, getCaseFilesExternal, getFile, getFileExternal } from 'Store/Actions/cases';
import { getCaseFiles as getCaseFilesSelector } from 'Store/Selectors/CaseSelector';
import { fileLoaderVisible } from 'Store/Selectors/ViewManagementSelector';
import * as AuthModule from '../../../Modules/AuthModule';

type ConnectedP = {
  files: Array<any>;
  fileLoaderVisible: boolean;
  authorizedUser: AuthModule.Types.AuthorizedUserData;
};

type DispatchedP = {
  getCaseFiles: (caseId: number, params: string) => void;
  getFile: (caseId: number, file: object) => void;
  getCaseFilesExternal: (caseId: number, params: string) => void;
  getFileExternal: (caseId: number, file: object) => void;
};

export type MainType = DispatchedP & ConnectedP;

const mapDispatchToProps: DispatchedP = {
  getCaseFiles: (caseId, params) => getCaseFiles(caseId, params),
  getFile: (caseId, file) => getFile(caseId, file),
  getCaseFilesExternal: (caseId, params) => getCaseFilesExternal(caseId, params),
  getFileExternal: (caseId, file) => getFileExternal(caseId, file),
};

function mapStateToProps(state: RootState): ConnectedP {
  return {
    files: getCaseFilesSelector(state),
    fileLoaderVisible: fileLoaderVisible(state),
    authorizedUser: AuthModule.Selectors.authorizedUser(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Files);
