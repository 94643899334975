import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Actions, Selectors } from 'Modules/GroupModule';
import Main from './Main';

type ConnectedP = {
  crmSettings: any;
};

type DispatchedP = {
  actions: {
    getCrmSettings: () => void;
    patchCrmSettings: (crmOptions: any) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getCrmSettings: () => Actions.getCrmSettings(),
      patchCrmSettings: (crmOptions) => Actions.patchCrmSettings(crmOptions),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  crmSettings: Selectors.getCrmSettingsData(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
