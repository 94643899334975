import { GROUP } from '../EnumTypes/ActionEnumTypes';
import { Connector } from 'Modules/GroupModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: GROUP.DELETE_GROUP_INIT,
});

const success = () => ({
  type: GROUP.DELETE_GROUP_SUCCESS,
});

const failure = () => ({
  type: GROUP.DELETE_GROUP_FAILURE,
});

const deleteGroup = (id) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await Connector.deleteGroup(id);
    dispatch(success());
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.success('Usunięto grupę');
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.error(err.response?.data.message ?? 'Nie udało się usunąć grupy');
    dispatch(failure());
  }
};

export default deleteGroup;
