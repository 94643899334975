import { Client } from '../EnumTypes/ActionEnumTypes';
import { getClientNoteById as getClientNoteByIdConnector } from 'Connectors/ClientConnector';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Client.GET_CLIENT_NOTE_BY_ID_INIT,
});

const success = (clientSingleNote) => ({
  type: Client.GET_CLIENT_NOTE_BY_ID_SUCCESS,
  payload: {
    clientSingleNote,
  },
});

const failure = () => ({
  type: Client.GET_CLIENT_NOTE_BY_ID_FAILURE,
});

const getClientNoteById = (id) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await getClientNoteByIdConnector(id);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
  }
};

export default getClientNoteById;
