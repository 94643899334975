import { CASES } from '../EnumTypes/ActionEnumTypes';
import { getExternalUserCasesList as getExternalUserCasesListConnector } from 'Connectors/CaseConnector';

const init = () => ({
    type: CASES.GET_EXTERNAL_USER_CASES_LIST_INIT,
});

const success = (casesList) => ({
    type: CASES.GET_EXTERNAL_USER_CASES_LIST_SUCCESS,
    payload: {
        casesList,
    },
});

const failure = () => ({
    type: CASES.GET_EXTERNAL_USER_CASES_LIST_FAILURE,
});

const getExternalUserCasesList = (params) => async (dispatch) => {
    dispatch(init());
    try {
        const { data } = await getExternalUserCasesListConnector(params);
        dispatch(success(data));
    } catch (err) {
        dispatch(failure());
    }
};

export default getExternalUserCasesList;
